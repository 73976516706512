import React, { useState } from 'react';
import { useController } from './controller';
import { addCompanyModalProps } from '../../../utils/types';
import * as S from './styles';
import { plan } from './types';

const EditCompanyEmailIntegration = (props: any) => {
    const controller = useController(props);

    return (
        <S.main active={controller.modalActive} animationOpacity={props.modalActive} onClick={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal step={controller.step} onClick={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                <S.header>
                    <S.title>Editar empresa</S.title>
                    <S.close onClick={() => {
                        controller.setStep(0);
                        props.setModalActive((active: boolean) => !active)
                    }}>X</S.close>
                </S.header>
                <S.body>
                    <S.stepContainer>
                        {controller.step == 0 ?
                            <S.step active={controller.step == 0} mobileMod={true}>
                                <S.uploadLogo>
                                    <div style={{ position: 'relative', display: 'flex' }}>
                                        <S.avatar logoImg={controller.currentLogo != "" && controller.currentLogo != null ? controller.currentLogo : controller.companyInfo?.logo[0]?.pathCdn} maxInitials={2} textSizeRatio={5.25} style={{
                                            fontSize: '40px',
                                        }} size='150' name={controller.companyInfo?.name}></S.avatar>
                                        <S.uploadTooltip logoImg={controller.currentLogo}>
                                            <S.uploadIcon icon={controller.general.icons.faUpload}></S.uploadIcon>
                                            <S.uploadText>Clique para adicionar uma logo</S.uploadText>
                                        </S.uploadTooltip>
                                        {/* title has a ' '[white space] so it doesnt show anything on hover */}
                                        <S.upload onInput={controller.handleInputFile} type='file' accept='image/*' title=' '></S.upload>
                                    </div>
                                </S.uploadLogo>
                                <S.inputsArea>
                                    <S.inputContainer>
                                        <S.inputLabel>Nome:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='name' value={controller.companyInfo.name}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Nome fantasia:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='fantasy_name' value={controller.companyInfo.fantasy_name}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>CPF / CNPJ:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='document' value={controller.companyInfo.document}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Email:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='email' value={controller.companyInfo.email}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Telefone:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='phone' value={controller.companyInfo.phone}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Data de criação:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='birthdate' value={controller.companyInfo.birthdate}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}
                    </S.stepContainer>
                    <S.saveBtn onClick={() => controller.updateInfo()}>
                        <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={15}/>
                        Salvar
                    </S.saveBtn>
                </S.body>
            </S.modal>
        </S.main>
    );
}

export default EditCompanyEmailIntegration;