import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import ReactQuill from 'react-quill';

export const checkbox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    cursor: pointer;
    gap: 10px;
`;

export const checkboxInput = styled.input`
    width: 25px;
    height: 25px;
`;

export const label = styled.label`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
`;