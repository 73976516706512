import React, { useEffect, useState } from 'react';
import * as S from './styles';

export const Checkbox = (props: {
    name: string;
    checked?: boolean;
    required?: boolean;
    label: string;
    onChange: any;
}) => {
    return (
        <S.checkbox onClick={() => {
            props.onChange({ target: { checked: !props.checked } });
        }}>
            <S.label htmlFor={props.name}>{props.label}</S.label>
            <S.checkboxInput
                type='checkbox'
                name={props.name}
                id={props.name}
                checked={props.checked ?? false}
                required={props.required ?? false}
                onChange={props.onChange}
            />
        </S.checkbox>
    );
}

export default Checkbox;