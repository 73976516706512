import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { addCompanyModalProps } from '../../../utils/types';
import companyInfoType from './types'
import { plan } from './types'
import axios from 'axios';
import moment from 'moment-timezone';

export enum cupomStatusEnum {
    NULL = 0,
    LOADING = 1,
    VALID = 2,
    INVALID = 3
}

export const useController = (props: addCompanyModalProps) => {
    const [modalActive, setModalActive] = useState(false);
    const [step, setStep] = useState(0);
    const [plans, setPlans] = useState<plan[]>([]);
    const [planShowing, setPlanShowing] = useState(3);
    const [cupomStatus, setCupomStatus] = useState<number>(cupomStatusEnum.NULL);
    const [remoteIp, setRemoteIp] = useState<string>("");
    const [discounts, setDiscounts] = useState({
        monthly: 1,
        semestral: 0.95,
        anual: 0.9
    });
    const [currentLogo, setCurrentLogo] = useState<string>("");
    const [cupomDiscount, setCupomDiscount] = useState<number>(0);
    const [companyInfo, setCompanyInfo] = useState<companyInfoType>({
        logo: { name: "", type: "", size: "", result: "" },
        name: "",
        document: "",
        email: "",
        phone: "",
        planPeriod: 12,
        planSelected: "",
        cupom: "",
        address: {
            zip_code: "",
            street: "",
            number: "",
            complement: "",
            neighborhood: "",
            city: "",
            state: "",
            country: ""
        },
        cc: {
            number: "",
            name: "",
            expiration: "",
            cvc: "",
            installments: 1,
            focus: ""
        }
    });
    const [stepName, setStepName] = useState([
        {
            title: 'Informações básicas',
            nextBtn: 'Endereço'
        },
        {
            title: 'Endereço',
            nextBtn: 'Escolha um plano',
            backBtn: 'Voltar'
        },
        {
            title: 'Escolha um plano',
            nextBtn: 'Pagamento',
            backBtn: 'Voltar'
        },
        {
            title: 'Confirmar dados',
            nextBtn: 'Pagamento',
            backBtn: 'Voltar'
        },
        {
            title: 'Pagamento',
            nextBtn: 'Concluir',
            backBtn: 'Voltar'
        },
        {
            title: 'Parabéns!',
            nextBtn: 'Finalizar'
        }
    ]);

    const clearCompanyInfo = () => {
        setCompanyInfo({
            logo: { name: "", type: "", size: "", result: "" },
            name: "",
            document: "",
            email: "",
            phone: "",
            planPeriod: 12,
            planSelected: "",
            cupom: "",
            address: {
                zip_code: "",
                street: "",
                number: "",
                complement: "",
                neighborhood: "",
                city: "",
                state: "",
                country: ""
            },
            cc: {
                number: "",
                name: "",
                expiration: "",
                cvc: "",
                installments: 1,
                focus: ""
            }
        });
    }

    const resetPage = () => {
        setStep(0);
        clearCompanyInfo();
        setCurrentLogo("");
        setCupomStatus(cupomStatusEnum.NULL);
        setCupomDiscount(0);
        setPlanShowing(3);
    }

    const getPlans = () => {
        general.api.post('/plan/list', {
            page: 0,
            limit: 9999999999999
        }).then((response) => {
            if (response.status === 200) {
                setPlans(response.data.data.data.filter((plan: any) => plan.showOnWebsite === true).sort((a: any, b: any) => a.value - b.value));
                setCompanyInfo((prev) => ({ ...prev, planSelected: response.data.data.data[1]._id }));
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(() => {
        getPlans();
    }, []);

    const createCompany = async () => {
        try{
            general.api.post('/company/create', { ...companyInfo, planValue: plans.find((plan) => plan._id === companyInfo.planSelected)?.value, remoteIp: remoteIp }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            }).then((response) => {
                if (response.status === 200) {
                    props.getCompanies();
                }
            }).catch((err) => {
                console.log(err);
            });
        }
        catch(err) {
            props.setModalActive(false);
            general.notify('Erro ao criar empresa', 'error');
            console.log(err);
        }
    }

    const changeStep = (optionStep: string) => {
        if (optionStep === 'next') {
            if (!nextStepValidation()) return alert('Preencha todos os campos corretamente');
            if (step == stepName.length - 2) {
                setTimeout(() => {
                    createCompany();
                }, 300);
            };
            if (step == stepName.length - 1) {
                props.setModalActive(false);
                resetPage();
                return;
            };
            setStep(step + 1);
        }
        if (optionStep === 'back') {
            if (step == 0) return;
            setStep(step - 1);
        }
    }

    const fieldFormat = (field: string, value: any) => {
        switch (field) {
            case 'document':
                return general.customMask(value, (value.replace(/[^0-9]/g, '').length >= 12 ? '##.###.###/####-##' : '###.###.###-##'), "#", true);
            case 'phone':
                return general.phoneMask.customPhoneMask(value, '55');
            case 'cc.number':
                return general.customMask(value, '#### #### #### ####', "#", true);
            case 'cc.expiration':
                return general.customMask(value, '##/##', "#", true);
            case 'cc.cvc':
                return general.customMask(value, '###', "#", true);
            case 'cc.installments':
                return parseInt(value);
            case 'address.zip_code': {
                if (value.length >= 8) {
                    axios.get(`https://brasilapi.com.br/api/cep/v1/${value.replace('-', '').replace('.', '')}`).then((response) => {
                        if (response.status === 200) {
                            setCompanyInfo((prev) => ({ ...prev, address: { ...prev.address, street: response?.data?.street ?? '', neighborhood: response?.data?.neighborhood ?? '', city: response?.data?.city, state: response?.data?.state ?? '' } }));
                        }
                    }).catch((err) => { });
                }
                return general.customMask(value, '##.###-###', "#", true);
            }
            default:
                return value;
        }
    }

    const nextStepValidation = () => {
        switch (step) {
            case 0:
                if (companyInfo.name === "" || companyInfo.document === "" || companyInfo.email === "" || companyInfo.phone === "" || general.validateCustomMask(companyInfo.document, (companyInfo.document.replace(/[^0-9]/g, '').length >= 12 ? '##.###.###/####-##' : '###.###.###-##')) === false || general.validateCustomMask(companyInfo.phone, '(##) #####-####') === false || general.fieldValidations.validateEmail(companyInfo.email) === false) {
                    return false;
                }
                return true;
            case 1:
                if (companyInfo.address.zip_code === "" || companyInfo.address.street === "" || companyInfo.address.number === "" || companyInfo.address.neighborhood === "" || companyInfo.address.city === "" || companyInfo.address.state === "" || general.validateCustomMask(companyInfo.address.zip_code, '##.###-###') === false) {
                    return false;
                }
                return true;
            case 2:
                if (companyInfo.planSelected === "" || companyInfo.planPeriod === 0) return false;
                return true;

            case 3:
                return true;
            case 4:
                if (
                    companyInfo.cc.number === "" || 
                    companyInfo.cc.name === "" || 
                    companyInfo.cc.expiration === "" || 
                    companyInfo.cc.cvc === "" || 
                    general.validateCustomMask(companyInfo.cc.number, '#### #### #### ####') === false || 
                    general.validateCustomMask(companyInfo.cc.expiration, '##/##') === false || 
                    general.validateCustomMask(companyInfo.cc.cvc, '###') === false || 
                    parseInt(companyInfo.cc.expiration.split('/')[0]) > 12 || 
                    parseInt(companyInfo.cc.expiration.split('/')[1]) < parseInt(moment().year().toString().slice(2)) || 
                    (parseInt(companyInfo.cc.expiration.split('/')[0]) < parseInt(moment().month().toString()) && parseInt(companyInfo.cc.expiration.split('/')[1]) == parseInt(moment().year().toString().slice(2))) ||
                    companyInfo.cc.cvc.length < 3 || companyInfo.cc.installments === 0
                ){
                    return false;
                }
                return true;
            default:
                return true;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        let originalName = name;
        const child = name.includes('.') ? name.split('.')[1] : '';
        if (child !== '') {
            name = name.split('.')[0];
            setCompanyInfo((prev) => ({ ...prev, [name]: { ...prev.cc, [child]: fieldFormat(originalName, value) } }));
        }
        else {
            setCompanyInfo((prev) => ({ ...prev, [name]: fieldFormat(name, value) }));
        }
    }

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        let originalName = name;
        const child = name.includes('.') ? name.split('.')[1] : '';
        if (child !== '') {
            name = name.split('.')[0];
            setCompanyInfo((prev) => ({ ...prev, [name]: { ...prev.address, [child]: fieldFormat(originalName, value) } }));
        }
        else {
            setCompanyInfo((prev) => ({ ...prev, [name]: fieldFormat(name, value) }));
        }
    }

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let { name, value } = e.target;
        let originalName = name;
        const child = name.includes('.') ? name.split('.')[1] : '';
        if (child !== '') {
            name = name.split('.')[0];
            setCompanyInfo((prev) => ({ ...prev, [name]: { ...prev.cc, [child]: fieldFormat(originalName, value) } }));
        }
        else {
            setCompanyInfo((prev) => ({ ...prev, [name]: fieldFormat(name, value) }));
        }
    }

    const handleInputFocus = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setCompanyInfo((prev) => ({ ...prev, cc: { ...prev.cc, focus: e.target.name } }));
    }

    const onlyImages = (file: File) => {
        if (file.type.includes('image')) return true;
        return false;
    }

    const handleInputFile = async (e: React.FormEvent<HTMLInputElement>) => {
        console.log(e);
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            if (!onlyImages(file)) return alert('Arquivo inválido');
            setCompanyInfo((prev) => ({ ...prev, logo: { name: file.name, type: file.type, size: file.size.toString(), result: reader.result as string } }));
            setCurrentLogo(reader.result as string);
        }
        let fileUrl = await reader.readAsDataURL(file);
    }

    const calculateStepPercentage = () => {
        return (step * 100) / (stepName.length - 1);
    }

    useEffect(() => {
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
        }
    }, [props.modalActive]);

    const changeShowingPlan = (direction: 'next' | 'back') => {
        if (direction === 'next') {
            if (planShowing == 4) return;
            setPlanShowing(planShowing + 1);
        }
        if (direction === 'back') {
            if (planShowing == 2) return;
            setPlanShowing(planShowing - 1);
        }
    }

    const handleCupom = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCompanyInfo((prev) => ({ ...prev, cupom: e.target.value }));
        if (e.target.value === "") {
            setCupomStatus(cupomStatusEnum.NULL);
            setCupomDiscount(0);
            return;
        }
        setCupomStatus(cupomStatusEnum.LOADING);
        general.api.post('/promo-code/get', {
            name: e.target.value
        }).then((response) => {
            if (response.status === 200) {
                setCupomStatus(cupomStatusEnum.VALID);
                setCupomDiscount(response.data.data.discount_percentage);
            }
        }).catch((err) => {
            setCupomStatus(cupomStatusEnum.INVALID);
            setCupomDiscount(0);
        });
    }

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })

        axios.get('https://api.db-ip.com/v2/free/self').then((response) => {
            if (response.status === 200) {
                setRemoteIp(response.data.ipAddress);
            }
        }).catch((err) => { 
            console.log(err);
        });

    }, []);


    return {
        general,
        modalActive,
        step,
        setStep,
        stepName,
        changeStep,
        calculateStepPercentage,
        companyInfo,
        setCompanyInfo,
        discounts,
        planShowing,
        setPlanShowing,
        changeShowingPlan,
        handleInputChange,
        handleInputFocus,
        handleAddressChange,
        handleSelectChange,
        createCompany,
        handleInputFile,
        currentLogo,
        plans,
        handleCupom,
        cupomDiscount,
        cupomStatus
    };
}

export default useController;