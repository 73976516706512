import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';

const Stores = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Stores' />
            </S.generalArea>
        </S.main>
    );
}

export default Stores;