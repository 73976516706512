import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import ActionCardAlt from '../../../components/app/Cards/ActionCardAlt';
import DefaultLoadingFit from '../../../components/app/DefaultLoadingFit';

const CurrencyConvertion = (props: any) => {
    const [modalStep, setModalStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [currency, setCurrency] = useState<string>('brl');
    const controller = props.controller;

    const convertCurrency = async () => {
        setLoading(true);
        try {
            const response = await controller.general.axios.get(`https://latest.currency-api.pages.dev/v1/currencies/${currency}.json`);
            if (response.status === 200) {
                if (response.data.status === 403) {
                    controller.general.notify('Você não tem permissão para fazer isso', 'error');
                    setLoading(false);
                    return;
                }
                let currentResult = `Convertendo ${((value == '' ? 1 : (parseInt(value.replace(',', '')) / 100))).toString().replace('.', ',')} ${currency.toUpperCase()}<br>`;
                currentResult += `<br>Obs: Valores atualizados diariamente, porém podem haver pequenas variações ao longo do dia, consulte a cotação atualizada para valores exatos.`;
                currentResult += `<br><br>Resultado:<br><br>`;
                let realCurrency = controller.general.currencies.find((cur: any) => cur.code === 'brl');
                let usdCurrency = controller.general.currencies.find((cur: any) => cur.code === 'usd');
                let eurCurrency = controller.general.currencies.find((cur: any) => cur.code === 'eur');
                currentResult += `Em reais: [${realCurrency.symbol ?? 'BRL'} ${((response.data[currency]['brl'] * (value == '' ? 1 : (parseInt(value.replace(',', '')) / 100))).toFixed(2)).toString().replace('.', ',')}]<br>`
                currentResult += `Em dolares: [${usdCurrency.symbol ?? 'USD'} ${((response.data[currency]['usd'] * (value == '' ? 1 : (parseInt(value.replace(',', '')) / 100))).toFixed(2)).toString().replace('.', ',')}]<br>`
                currentResult += `Em euros: [${eurCurrency.symbol ?? 'EUR'} ${((response.data[currency]['eur'] * (value == '' ? 1 : (parseInt(value.replace(',', '')) / 100))).toFixed(2)).toString().replace('.', ',')}]<br><br>`
                currentResult += `Outras moedas:<br>`;
                Object.keys(response.data[currency]).map((c: any) => {
                    if (c === 'brl' || c === 'usd' || c === 'eur') return;
                    const currencyInfo = controller.general.currencies.find((cur: any) => cur.code === c);
                    currentResult += `${currencyInfo.name ?? c} - [${currencyInfo.symbol ?? c} ${((response.data[currency][c] * (value == '' ? 1 : (parseInt(value.replace(',', '')) / 100))).toFixed(2)).toString().replace('.', ',')}]<br>`
                });
                setResult(currentResult);
                setLoading(false);
                setModalStep(step => (step + 1));
            }
            else {
                controller.general.notify('Erro ao converter moeda', 'error');
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            controller.general.notify('Erro ao converter moeda', 'error');
            setLoading(false);
        }
    }

    return (
        props.modalOpened && (
            <S.modalWrapper>
                <S.modalMain onSubmit={(e) => {
                    e.preventDefault();
                    convertCurrency();
                }}>
                    <S.modalHeader>
                        <S.modalTitle>Conversão de moedas</S.modalTitle>
                        <S.modalClose onClick={() => {
                            props.setModalOpened(false);
                            setModalStep(0);
                            setValue('');
                            setCurrency('brl');
                        }}>X</S.modalClose>
                    </S.modalHeader>
                    <S.modalContent>
                        {loading ? (
                            <DefaultLoadingFit active={loading} />
                        ) : (
                            modalStep === 0 ? (
                                <>
                                    <S.modalInput type="text" placeholder='Valor' value={controller.general.formatMoney(value)} onChange={(e) => setValue(e.target.value)} />
                                    <S.modalSelect onChange={(e) => setCurrency(e.target.value)} value={currency}>
                                        {controller.general.currencies.map((currency: any, index: number) => {
                                            currency.name = currency.name == '' ? null : currency.name;
                                            currency.symbol = currency.symbol == '' ? null : currency.symbol;
                                            if (currency.name == 'Brazilian Real') {
                                                currency.name = 'Real Brasileiro';
                                            }
                                            return (
                                                <option key={index} value={currency.code}>{currency.name ?? currency.code} || {currency.symbol ?? currency.code} || {currency.code}</option>
                                            )
                                        })}
                                    </S.modalSelect>
                                    <S.modalButton onClick={() => convertCurrency()}>Converter</S.modalButton>
                                </>
                            ) : (
                                <S.result dangerouslySetInnerHTML={{ __html: result }}></S.result>
                            )
                        )}
                    </S.modalContent>
                </S.modalMain>
            </S.modalWrapper>
        )
    )
}

const CepSearch = (props: any) => {
    const [modalStep, setModalStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const controller = props.controller;

    const search = async () => {
        setLoading(true);
        let currentResult = `Resultado do CEP ${controller.general.customMask(value, '##.###-###')}:<br>`;
        try {
            const response = await controller.general.axios.get(`https://brasilapi.com.br/api/cep/v2/${value}`);
            if (response.status === 200) {
                if (response.data.status === 403) {
                    controller.general.notify('Você não tem permissão para fazer isso', 'error');
                    setLoading(false);
                    return;
                }

                currentResult += `Estado: ${response.data.state ?? 'Não encontrado'}<br>`;
                currentResult += `Cidade: ${response.data.city ?? 'Não encontrado'}<br>`;
                currentResult += `Bairro: ${response.data.neighborhood ?? 'Não encontrado'}<br>`;
                currentResult += `Rua: ${response.data.street ?? 'Não encontrado'}<br>`;
                currentResult += `Latitude: ${response.data.location.coordinates.latitude ?? 'Não encontrado'}<br>`;
                currentResult += `Longitude: ${response.data.location.coordinates.longitude ?? 'Não encontrado'}<br>`;
                currentResult += `Mapa: <a href="https://www.google.com/maps/search/?api=1&query=${response.data.cep}" target="_blank">Clique aqui</a>`;
                setResult(currentResult);
                setLoading(false);
                setModalStep(step => (step + 1));
            }
            else {
                controller.general.notify('Erro ao buscar CEP', 'error');
                currentResult += `Erro ao buscar CEP`;
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            controller.general.notify('Erro ao buscar CEP', 'error');
            currentResult += `Erro ao buscar CEP`;
            setLoading(false);
        }
    }

    return (
        props.modalOpened && (
            <S.modalWrapper>
                <S.modalMain onSubmit={(e) => {
                    e.preventDefault();
                    search();
                }}>
                    <S.modalHeader>
                        <S.modalTitle>Busca de CEP</S.modalTitle>
                        <S.modalClose onClick={() => {
                            props.setModalOpened(false);
                            setValue('');
                            setModalStep(0);
                        }}>X</S.modalClose>
                    </S.modalHeader>
                    <S.modalContent>
                        {loading ? (
                            <DefaultLoadingFit active={loading} />
                        ) : (
                            modalStep === 0 ? (
                                <>
                                    <S.modalInput type="text" placeholder='CEP' value={controller.general.customMask(value, '##.###-###')} onChange={(e) => setValue(e.target.value.replace(/\D/g, ''))} />
                                    <S.modalButton onClick={() => search()}>Buscar</S.modalButton>
                                </>
                            ) : (
                                <S.result dangerouslySetInnerHTML={{ __html: result }}></S.result>
                            )
                        )}
                    </S.modalContent>
                </S.modalMain>
            </S.modalWrapper>
        )
    )
}

const CnpjSearch = (props: any) => {
    const [modalStep, setModalStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const controller = props.controller;

    const search = async () => {
        setLoading(true);
        let currentResult = `Resultado do CNPJ ${controller.general.customMask(value, '##.###.###/####-##')}:<br><br>`;
        try {
            const response = await controller.general.axios.get(`https://brasilapi.com.br/api/cnpj/v1/${value}`);
            if (response.status === 200) {
                if (response.data.status === 403) {
                    controller.general.notify('Você não tem permissão para fazer isso', 'error');
                    setLoading(false);
                    return;
                }

                Object.keys(response.data).map((key: any) => {
                    if (key == 'qsa' || key == 'cnaes_secundarios') return;
                    currentResult += `${key.charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}: ${(response.data[key] != '' && response.data[key]) ? response.data[key]?.toString().replace('true', 'Sim').replace('false', 'Não') : 'Não encontrado'}<br><br>`;
                });
                currentResult += `Sócios: ${response.data.qsa.length > 0 ? response.data.qsa.map((qsa: any) => `[${qsa.nome_socio}, ${qsa.cnpj_cpf_do_socio}, ${qsa.qualificacao_socio}]`).join(', ') : 'Não encontrado'}<br><br>`;
                currentResult += `Atividades secundárias: ${response.data.cnaes_secundarios.length > 0 ? response.data.cnaes_secundarios.map((cnae: any) => `[${cnae.codigo} - ${cnae.descricao}]`).join(', ') : 'Não encontrado'}`;
                setResult(currentResult);
                setLoading(false);
                setModalStep(step => (step + 1));
            }
            else {
                controller.general.notify('Erro ao buscar CNPJ', 'error');
                currentResult += `Erro ao buscar CNPJ`;
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            controller.general.notify('Erro ao buscar CNPJ', 'error');
            currentResult += `Erro ao buscar CNPJ`;
            setLoading(false);
        }
    }

    return (
        props.modalOpened && (
            <S.modalWrapper>
                <S.modalMain onSubmit={(e) => {
                    e.preventDefault();
                    search();
                }}>
                    <S.modalHeader>
                        <S.modalTitle>Busca de CNPJ</S.modalTitle>
                        <S.modalClose onClick={() => {
                            props.setModalOpened(false);
                            setValue('');
                            setModalStep(0);
                        }}>X</S.modalClose>
                    </S.modalHeader>
                    <S.modalContent>
                        {loading ? (
                            <DefaultLoadingFit active={loading} />
                        ) : (
                            modalStep === 0 ? (
                                <>
                                    <S.modalInput type="text" placeholder='CNPJ' value={controller.general.customMask(value, '##.###.###/####-##')} onChange={(e) => setValue(e.target.value.replace(/\D/g, ''))} />
                                    <S.modalButton onClick={() => search()}>Buscar</S.modalButton>
                                </>
                            ) : (
                                <S.result dangerouslySetInnerHTML={{ __html: result }}></S.result>
                            )
                        )}
                    </S.modalContent>
                </S.modalMain>
            </S.modalWrapper>
        )
    )
}

const TaxesSearch = (props: any) => {
    const [modalStep, setModalStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const controller = props.controller;

    const search = async () => {
        setLoading(true);
        let currentResult = `Taxas atuais: <br><br>`;
        try {
            const response = await controller.general.axios.get(`https://brasilapi.com.br/api/taxas/v1`);
            if (response.status === 200) {
                if (response.data.status === 403) {
                    controller.general.notify('Você não tem permissão para fazer isso', 'error');
                    setLoading(false);
                    return;
                }

                Object.keys(response.data).map((key: any) => {
                    currentResult += `${response.data[key].nome}: ${response.data[key].valor}<br>`;
                });

                currentResult += `<br>Obs: Mais taxas serão adicionadas em breve.`;
                setResult(currentResult);
                setLoading(false);
                setModalStep(step => (step + 1));
            }
            else {
                controller.general.notify('Erro ao buscar Taxas', 'error');
                currentResult += `Erro ao buscar Taxas`;
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            controller.general.notify('Erro ao buscar Taxas', 'error');
            currentResult += `Erro ao buscar Taxas`;
            setLoading(false);
        }
    }

    return (
        props.modalOpened && (
            <S.modalWrapper>
                <S.modalMain onSubmit={(e) => {
                    e.preventDefault();
                    search();
                }}>
                    <S.modalHeader>
                        <S.modalTitle>Busca de Taxas</S.modalTitle>
                        <S.modalClose onClick={() => {
                            props.setModalOpened(false);
                            setValue('');
                            setModalStep(0);
                        }}>X</S.modalClose>
                    </S.modalHeader>
                    <S.modalContent>
                        {loading ? (
                            <DefaultLoadingFit active={loading} />
                        ) : (
                            modalStep === 0 ? (
                                <>
                                    <S.modalButton onClick={() => search()}>Buscar</S.modalButton>
                                </>
                            ) : (
                                <S.result dangerouslySetInnerHTML={{ __html: result }}></S.result>
                            )
                        )}
                    </S.modalContent>
                </S.modalMain>
            </S.modalWrapper>
        )
    )
}

const BarcodeGenerator = (props: any) => {
    const [modalStep, setModalStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const controller = props.controller;

    const generateBarcode = () => {
        setLoading(true);
        let currentResult = `Código de barras gerado para o valor ${value}:<br><br>`;
        try {
            currentResult += `<img src="https://barcodeapi.org/api/auto/${value}" alt="Barcode" />`;
            setResult(currentResult);
            setLoading(false);
            setModalStep(step => (step + 1));
        }
        catch (err) {
            console.log(err);
            controller.general.notify('Erro ao gerar código de barras', 'error');
            currentResult += `Erro ao gerar código de barras`;
            setLoading(false);
        }
    }

    return (
        props.modalOpened && (
            <S.modalWrapper>
                <S.modalMain onSubmit={(e) => {
                    e.preventDefault();
                    generateBarcode();
                }}>
                    <S.modalHeader>
                        <S.modalTitle>Gerador de código de barras</S.modalTitle>
                        <S.modalClose onClick={() => {
                            props.setModalOpened(false);
                            setValue('');
                            setModalStep(0);
                        }}>X</S.modalClose>
                    </S.modalHeader>
                    <S.modalContent>
                        {loading ? (
                            <DefaultLoadingFit active={loading} />
                        ) : (
                            modalStep === 0 ? (
                                <>
                                    <S.modalInput type="text" placeholder='Valor' value={value} onChange={(e) => setValue(e.target.value)} />
                                    <S.modalButton onClick={() => generateBarcode()}>Gerar</S.modalButton>
                                </>
                            ) : (
                                <>
                                    <S.result style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }} dangerouslySetInnerHTML={{ __html: result }}></S.result>
                                    <S.modalButton onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = `https://barcodeapi.org/api/auto/${value}`;
                                        link.download = 'barcode.png';
                                        link.target = '_blank';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }}>Baixar</S.modalButton>
                                </>
                            )
                        )}
                    </S.modalContent>
                </S.modalMain>
            </S.modalWrapper>
        )
    )
}

const QrCodeGenerator = (props: any) => {
    const [modalStep, setModalStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const controller = props.controller;

    const generateQRCode = () => {
        setLoading(true);
        let currentResult = `QR Code gerado para o valor ${value}:<br><br>`;
        try {
            currentResult += `<img src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${value}" alt="QR Code" />`;
            setResult(currentResult);
            setLoading(false);
            setModalStep(step => (step + 1));
        }
        catch (err) {
            console.log(err);
            controller.general.notify('Erro ao gerar QR Code', 'error');
            currentResult += `Erro ao gerar QR Code`;
            setLoading(false);
        }
    }

    return (
        props.modalOpened && (
            <S.modalWrapper>
                <S.modalMain onSubmit={(e) => {
                    e.preventDefault();
                    generateQRCode();
                }}>
                    <S.modalHeader>
                        <S.modalTitle>Gerador de QR Code</S.modalTitle>
                        <S.modalClose onClick={() => {
                            props.setModalOpened(false);
                            setValue('');
                            setModalStep(0);
                        }}>X</S.modalClose>
                    </S.modalHeader>
                    <S.modalContent>
                        {loading ? (
                            <DefaultLoadingFit active={loading} />
                        ) : (
                            modalStep === 0 ? (
                                <>
                                    <S.modalInput type="text" placeholder='Valor' value={value} onChange={(e) => setValue(e.target.value)} />
                                    <S.modalButton onClick={() => generateQRCode()}>Gerar</S.modalButton>
                                </>
                            ) : (
                                <>
                                    <S.result style={{
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column'
                                    }} dangerouslySetInnerHTML={{ __html: result }}></S.result>
                                    <S.modalButton onClick={() => {
                                        const link = document.createElement('a');
                                        link.href = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${value}`;
                                        link.download = 'qrcode.png';
                                        link.target = '_blank';
                                        document.body.appendChild(link);
                                        link.click();
                                        document.body.removeChild(link);
                                    }
                                    }>Baixar</S.modalButton>
                                </>
                            )
                        )}
                    </S.modalContent>
                </S.modalMain>
            </S.modalWrapper>
        )
    )
}

const DddSearch = (props: any) => {
    const [modalStep, setModalStep] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [result, setResult] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const controller = props.controller;

    const search = async () => {
        setLoading(true);
        let currentResult = `Resultado do DDD ${value}:<br><br>`;
        try {
            const response = await controller.general.axios.get(`https://brasilapi.com.br/api/ddd/v1/${value}`);
            if (response.status === 200) {
                if (response.data.status === 403) {
                    controller.general.notify('Você não tem permissão para fazer isso', 'error');
                    setLoading(false);
                    return;
                }

                currentResult += `Estado: ${response.data.state ?? 'Não encontrado'}<br>`;
                currentResult += `Cidades: <br>`;
                response.data.cities.map((city: any) => {
                    currentResult += `${city ?? 'Não encontrado'}<br>`;
                });
                setResult(currentResult);
                setLoading(false);
                setModalStep(step => (step + 1));
            }
            else {
                controller.general.notify('Erro ao buscar DDD', 'error');
                currentResult += `Erro ao buscar DDD`;
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            controller.general.notify('Erro ao buscar DDD', 'error');
            currentResult += `Erro ao buscar DDD`;
            setLoading(false);
        }
    }

    return (
        props.modalOpened && (
            <S.modalWrapper>
                <S.modalMain onSubmit={(e) => {
                    e.preventDefault();
                    search();
                }}>
                    <S.modalHeader>
                        <S.modalTitle>Busca de DDD</S.modalTitle>
                        <S.modalClose onClick={() => {
                            props.setModalOpened(false);
                            setValue('');
                            setModalStep(0);
                        }}>X</S.modalClose>
                    </S.modalHeader>
                    <S.modalContent>
                        {loading ? (
                            <DefaultLoadingFit active={loading} />
                        ) : (
                            modalStep === 0 ? (
                                <>
                                    <S.modalInput type="text" placeholder='DDD' value={value.replace(/\D/g, '')} onChange={(e) => setValue(e.target.value)} />
                                    <S.modalButton onClick={() => search()}>Buscar</S.modalButton>
                                </>
                            ) : (
                                <S.result dangerouslySetInnerHTML={{ __html: result }}></S.result>
                            )
                        )}
                    </S.modalContent>
                </S.modalMain>
            </S.modalWrapper>
        )
    )
}

const Utilities = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            <CurrencyConvertion modalOpened={controller.modalCurrencyConvertion} setModalOpened={controller.setModalCurrencyConvertion} controller={controller} />
            <CepSearch modalOpened={controller.modalCEP} setModalOpened={controller.setModalCEP} controller={controller} />
            <CnpjSearch modalOpened={controller.modalCNPJ} setModalOpened={controller.setModalCNPJ} controller={controller} />
            {/*<FreightSearch modalOpened={controller.modalFreight} setModalOpened={controller.setModalFreight} controller={controller} /> */}
            <TaxesSearch modalOpened={controller.modalTaxes} setModalOpened={controller.setModalTaxes} controller={controller} />
            <BarcodeGenerator modalOpened={controller.modalBarcode} setModalOpened={controller.setModalBarcode} controller={controller} />
            <QrCodeGenerator modalOpened={controller.modalQRCode} setModalOpened={controller.setModalQRCode} controller={controller} />
            {/* <ISBNSearch modalOpened={controller.modalISBN} setModalOpened={controller.setModalISBN} controller={controller} /> */}
            <DddSearch modalOpened={controller.modalDDD} setModalOpened={controller.setModalDDD} controller={controller} />
            {/* <NcmSearch modalOpened={controller.modalNCM} setModalOpened={controller.setModalNCM} controller={controller} /> */}

            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName={'Utilidades'} />
                <S.operationsArea>
                    <div className='content-cards-wrapper' onClick={() => controller.setModalCurrencyConvertion(true)}>
                        <ActionCardAlt mainIcon='faCoins'>
                            Conversão entre moedas
                        </ActionCardAlt>
                    </div>
                    <div className='content-cards-wrapper' onClick={() => controller.setModalCEP(true)}>
                        <ActionCardAlt mainIcon='faMapLocationDot'>
                            Busca de CEP
                        </ActionCardAlt>
                    </div>
                    <div className='content-cards-wrapper' onClick={() => controller.setModalCNPJ(true)}>
                        <ActionCardAlt mainIcon='faBuilding'>
                            Consulta CNPJ
                        </ActionCardAlt>
                    </div>
                    {/* <div className='content-cards-wrapper' onClick={() => controller.setModalFreight(true)}>
                        <ActionCardAlt mainIcon='faTruck'>
                            Consulta de frete
                        </ActionCardAlt>
                    </div> */}
                    <div className='content-cards-wrapper' onClick={() => controller.setModalTaxes(true)}>
                        <ActionCardAlt mainIcon='faFileInvoiceDollar'>
                            Taxas
                        </ActionCardAlt>
                    </div>
                    <div className='content-cards-wrapper' onClick={() => controller.setModalBarcode(true)}>
                        <ActionCardAlt mainIcon='faBarcode'>
                            Gerador de código de barras
                        </ActionCardAlt>
                    </div>
                    <div className='content-cards-wrapper' onClick={() => controller.setModalQRCode(true)}>
                        <ActionCardAlt mainIcon='faQrcode'>
                            Gerador de QR Code
                        </ActionCardAlt>
                    </div>
                    {/* <div className='content-cards-wrapper'>
                        <ActionCardAlt mainIcon='faBook'>
                            Busca de ISBN
                        </ActionCardAlt>
                    </div> */}
                    <div className='content-cards-wrapper' onClick={() => controller.setModalDDD(true)}>
                        <ActionCardAlt mainIcon='faPhone'>
                            Busca de DDD
                        </ActionCardAlt>
                    </div>
                    {/* <div className='content-cards-wrapper'>
                        <ActionCardAlt mainIcon='faBox'>
                            Busca de NCM
                        </ActionCardAlt>
                    </div> */}
                    {/* <div className='content-cards-wrapper' onClick={() => controller.setMeasurementsModal(true)}>
                        <ActionCardAlt mainIcon='faRuler'>
                            Tabela de medidas
                        </ActionCardAlt>
                    </div> */}
                </S.operationsArea>

            </S.generalArea>
        </S.main>
    );
}

export default Utilities;