import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import ReactQuill from 'react-quill';

export const text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px 0;
`;

export const textArea = styled.div`
    padding: 5px;
    border: none;
    border-bottom: 1px solid ${colors.grey};
    outline: none;
    background-color: transparent;
`;

export const label = styled.label`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
`;