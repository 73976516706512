import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    width: 330px;
    height: 320px;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
        transform: scale(1.05);
    }
        
    @media (max-width: 1366px) {
        width: 230px;
        height: 280px;
        padding: 0;
    }

    @media (max-width: 900px) {
        height: 280px;
        padding: 0;
        width: 180px;
    }

    @media (max-width: 550px) {
        width: 100%;
    }
`;
export const cardData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`;
export const cardTitles = styled.div`
    text-align: center;
    width: 100%;
    border-bottom: 1px solid ${colors.grey};
    font-family: ${fonts.main};
`;
export const cardTitle = styled.h3`
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 800;
    padding: 0 5px;

    @media (max-width: 1366px) {
        margin: 10px 0 0 0;
        font-size: 12px;
    }
    @media (max-width: 700px) {
        font-size: 13px;
        text-align: center;
    }
`;
export const cardSubTitle = styled.p`
    font-size: 12px;
    font-weight: 600;
    padding: 5px 0;
    margin: 0 10px;

    @media (max-width: 1366px) {
        font-size: 11px;
        padding: 5px 0;
    }
    @media (max-width: 700px) {
        font-size: 9px;
        text-align: center;
        padding: 5px 0;
    }
`;
export const cardGoal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
`;
export const goalArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    margin: 10px 0;
`;
export const modifyGoalButton = styled(FontAwesomeIcon)`
    font-size: 35px;
    color: ${colors.grey};
    cursor: pointer;
    transition-duration: 0.1s;
    margin: 0 15px;

    &:hover {
        color: ${colors.black};
    }

    @media (max-width: 1366px) {
        font-size: 25px;
    }

    @media (max-width: 900px) {
        font-size: 27px;
        margin: 0 10px;
    }

    @media (max-width: 550px) {
        font-size: 35px;
        margin: 0 8px;
    }
`;
export const goalShowArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 175px;
    height: 175px;
`;
export const goalBar = styled.div<{ oldPercentage?: number, percentage: number }>`
    @property --percentage {
        syntax: '<percentage>';
        inherits: false;
        initial-value: 0%;
    }

    animation: ${props => keyframes`
        0% {
            --percentage: ${props.oldPercentage}%;
        }
        100% {
            --percentage: ${props.percentage}%;
        }
    `} 0.3s linear forwards;

    width: 175px;
    height: 175px;
    border-radius: 50%;
    position: absolute;
    transition-duration: 0.3s;
    background: conic-gradient(${colors.green} 0% var(--percentage), ${colors.grey} var(--percentage) 100%);

    @media (max-width: 1366px) {
        width: 120px;
        height: 120px;
    }

    @media (max-width: 900px) {
        width: 90px;
        height: 90px;
    }

    @media (max-width: 550px) {
        width: 150px;
        height: 150px;
    }
`;
export const goalInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.white};
    position: absolute;
    width: 135px;
    height: 135px;
    border-radius: 50%;

    @media (max-width: 1366px) {
        width: 100px;
        height: 100px;
    }

    @media (max-width: 900px) {
        width: 70px;
        height: 70px;
    }

    @media (max-width: 550px) {
        width: 125px;
        height: 125px;
    }
`;
export const goal = styled.p`
    font-size: 16px;
    font-weight: 600;
    margin: 0 5px;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 12px;
    }

    @media (max-width: 550px) {
        font-size: 16px;
    }
`;
export const modifyGoalBtn = styled.button`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    width: 80%;
    height: 40px;
    background-color: ${colors.white};
    color: ${colors.black};
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.5s;
    text-align: center;

    &:hover {
        background-color: ${colors.black};
        color: ${colors.white};
    }

    @media (max-width: 1200px) {
        margin-bottom: 15px;
    }
`;