import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [modalActive, setModalActive] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);

    const getCompanies = async () => {
        setLoading(true);
        general.api.post('/account-company/list', {
            page: page,
            limit: 9999999999
        }).then((response) => {
            if (response.status === 200) {
                setTotalPages(response.data.data.total_pages);
                setCompanies(response.data.data.data);
            }
            setLoading(false);
        }).catch((err) => {
            console.log(err);
            setLoading(false);
            general.notify('Erro ao buscar empresas', 'error');
        });
    }

    useEffect(() => {
        getCompanies();
        localStorage.removeItem('company');
        localStorage.removeItem('companyObject');
    }, []);

    const chooseCompany = (company: any) => {
        localStorage.setItem('company', company._id);
        localStorage.setItem('companyObject', JSON.stringify(company));
        general.GoToRoute('/app/dashboard');
    }

    useEffect(() => {
        getCompanies();
    }, [page]);

    return {
        general,
        companies,
        modalActive,
        setModalActive,
        setCompanies,
        getCompanies,
        chooseCompany,
        page,
        setPage,
        totalPages,
        loading
    }
}

export default useController;