import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import DefaultEditModal from '../../../components/app/DefaultEditModal';

const Agenda = (props: defaultProps) => {
    const controller = useController();
    const localizer = momentLocalizer(moment);

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Agenda' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`event/create`} canAddCustomFields={false} title='Evento' type={controller.general.enums.itemTypes.PRODUCT} item={controller.eventCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal currentItem={controller.setCurrentItem} url={`event/update/${controller.currentItem?._id}`} canAddCustomFields={false} type={controller.general.enums.itemTypes.PRODUCT} item={controller.currentItem} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} /> : null}
            <S.showMoreEventsModal active={controller.showMoreEventsModal} onClick={() => controller.setShowMoreEventsModal(false)}>
                <S.showMoreEventsModalContent onClick={(e) => e.stopPropagation()}>
                    <S.showMoreEventsModalHeader>
                        <S.showMoreEventsModalTitle>Mais eventos</S.showMoreEventsModalTitle>
                        <S.showMoreEventsModalClose onClick={() => controller.setShowMoreEventsModal(false)} icon={controller.general.icons.faTimes} />
                    </S.showMoreEventsModalHeader>
                    <S.showMoreEventsModalBody>
                        {controller.eventsToShowMore.map((event: any, index: number) => {
                            return (
                                <S.showMoreEvent key={index} onMouseDown={() => {
                                    controller.setCurrentItem(event);
                                    controller.setEditModalActive(true);
                                    controller.setShowMoreEventsModal(false);
                                }}>
                                    <S.showMoreEventTitle>{event.title}</S.showMoreEventTitle>
                                    <S.showMoreEventTime>Horário: {`${moment(event.start).format('HH:mm')} - ${moment(event.end).format('HH:mm')}`}</S.showMoreEventTime>
                                    <S.showMoreEventDescription>Descrição: {event.description}</S.showMoreEventDescription>
                                </S.showMoreEvent>
                            );
                        })}
                    </S.showMoreEventsModalBody>
                </S.showMoreEventsModalContent>
            </S.showMoreEventsModal>
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Agenda' />
                {!controller.general.getCompany().plan.planModules.includes("module_schedule") ? <controller.general.NoPermissionComponent permissionDeniedMessage={"O Seu plano não possui acesso a essa funcionalidade, por favor solicite um upgrade"} /> :
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <>
                            <S.createTaskButton onClick={() => controller.setCreateModalActive(true)}>{'criar evento'.toUpperCase()}</S.createTaskButton>
                            <S.operationsArea>
                                <S.calendarWrapper>
                                    <controller.general.DefaultLoadingFit active={controller.loading} />
                                    <Calendar
                                        localizer={localizer}
                                        events={[...controller.events, ...controller.holidays]}
                                        startAccessor="start"
                                        endAccessor="end"
                                        culture='pt-BR'
                                        /* @ts-ignore */
                                        views={['month', 'week', 'day']}
                                        onSelectEvent={(e) => {
                                            controller.setCurrentItem(e);
                                            controller.setEditModalActive(true);
                                        }}
                                        onShowMore={(e) => {
                                            controller.setEventsToShowMore(e)
                                            controller.setShowMoreEventsModal(true)
                                        }}
                                        eventPropGetter={(event, start, end, isSelected) => {
                                            let newStyle = {
                                                backgroundColor: 'blue',
                                                color: 'white',
                                            };
                                            return {
                                                className: "",
                                                style: newStyle
                                            };
                                        }}
                                        messages={{
                                            allDay: 'Dia inteiro',
                                            previous: 'Anterior',
                                            next: 'Próximo',
                                            today: 'Hoje',
                                            month: 'Mês',
                                            week: 'Semana',
                                            day: 'Dia',
                                            showMore: total => `+${total}`
                                        }}
                                        onNavigate={(e: any) => {
                                            controller.setCurrentMonth(e.getMonth() + 1);
                                            controller.setCurrentYear(e.getFullYear());
                                        }}
                                        timeslots={1}
                                        allDayMaxRows={3}
                                        defaultView='month'
                                        style={{ height: '100%', width: '100%' }}
                                    />
                                </S.calendarWrapper>
                            </S.operationsArea>
                        </>
                    )}
            </S.generalArea>
        </S.main>
    );
}

export default Agenda;