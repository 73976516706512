import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemUser from '../../../components/app/Cards/ActionCard';
import UserCard from '../../../components/app/Cards/UserCard';
import DefaultEditModal from '../../../components/app/DefaultEditModal';
import DefaultCreateModal from '../../../components/app/DefaultCreateModal';
import Pagination from '../../../components/general/Pagination';

const Accounts = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <controller.general.DefaultLoading active={controller.loading} />
            {controller.createModalActive ? <DefaultCreateModal currentItem={controller.setCurrentItem} url={`account/create/`} canAddCustomFields title='Conta' type={controller.general.enums.itemTypes.ACCOUNT} item={controller.accountCreateTemplate} modalActive={controller.createModalActive} setModalActive={controller.setCreateModalActive} /> : null}
            {controller.editModalActive ? <DefaultEditModal currentItem={controller.setCurrentItem} url={`account/update/${controller.currentItem._id}`} canAddCustomFields={false} type={controller.general.enums.itemTypes.ACCOUNT} item={controller.currentItem} modalActive={controller.editModalActive} setModalActive={controller.setEditModalActive} hasTimeline={true} /> : null}
            <MenuComponent menuPreset={2} />
            <S.generalArea>
                <HeaderBarComponent pageName='Contas' />
                <S.operationsArea>
                    <div className='content-cards-wrapper' onClick={() => controller.setCreateModalActive(active => !active)}>
                        <NewItemUser mainIcon='faUser' pageItem='Conta'>
                            Adicionar <br /> conta
                        </NewItemUser>
                    </div>
                    {(controller.accounts ?? []).map((item: any, index) => {
                        return (
                            <div className='content-cards-wrapper' onClick={() => {
                                controller.setCurrentItem({...item, infos: [
                                    { key: 'name', type: "text", name: 'Nome', value: item.name, disabled: true },
                                    { key: 'email', type: "text", name: 'E-mail', value: item.email, disabled: true },
                                    { key: 'phone', type: "text", name: 'Telefone', mask: "(##) # ####-####", value: controller.general.customMask(item.phone, "(##) # ####-####"), disabled: true },
                                    { key: 'birthdate', type: "date", name: 'Data de Nascimento', value: (item.birthdate?.split('T')[0] ?? ""), disabled: true },
                                    { key: 'accountType', type: "select", name: 'Tipo de Conta', value: item.accountType, disabled: true, selectOptions: [{ value: 1, name: 'Administrador' }, { value: 2, name: 'Cliente' }] },
                                    { key: 'lastLoginAt', type: "date", name: 'Último login', value: (item.lastLoginAt?.split('T')[0] ?? ""), disabled: true },
                                    { key: 'createdAt', type: "date", name: 'Criação de conta', value: (item.createdAt?.split('T')[0] ?? ""), disabled: true },
                                    { key: 'updatedAt', type: "date", name: 'Última atualização', value: (item.updatedAt?.split('T')[0] ?? ""), disabled: true },
                                    { key: 'oldpass', type: "password", name: 'Senha antiga', value: '', disabled: true },
                                    { key: 'newpass', type: "password", name: 'Nova senha', value: '', disabled: true }
                                ], timeline: item?.timeline?.length > 0 ? item.timeline : []})
                                controller.setEditModalActive(active => !active)
                            }} key={`accounts_${index}`}>
                                <UserCard title={controller.general.formatName(item.name) ?? 'Não informado'} subtitle={item.email} infos={[
                                    { key: 'name', type: "text", name: 'Nome', value: controller.general.formatName(item.name), disabled: true },
                                    { key: 'email', type: "text", name: 'E-mail', value: item.email, disabled: true },
                                    { key: 'phone', type: "number", name: 'Telefone', mask: "(##) # ####-####", value: controller.general.customMask(item.phone, "(##) # ####-####"), disabled: true },
                                    { key: 'birthdate', type: "date", name: 'Data de Nascimento', value: controller.general.formatDate(item.birthdate ?? ""), disabled: true },
                                    { key: 'accountType', type: "text", name: 'Tipo de Conta', value: item.accountType == 1 ? 'Administrador' : 'Cliente', disabled: true },
                                    { key: 'lastLoginAt', type: "date", name: 'Último login', value: controller.general.formatDate(item.lastLoginAt ?? ""), disabled: true },
                                    { key: 'createdAt', type: "date", name: 'Criação de conta', value: controller.general.formatDate(item.createdAt ?? ""), disabled: true },
                                    { key: 'updatedAt', type: "date", name: 'Última atualização', value: controller.general.formatDate(item.updatedAt ?? ""), disabled: true },
                                ]} />
                            </div>
                        )
                    })}
                </S.operationsArea>
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    );
}

export default Accounts;