import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';

export const main = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #574f03;
`;
export const signupContainer = styled.form`
    width: 22%;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 9999;
    box-shadow: 1px 0px 15px 5px rgba(0, 0, 0, 0.65);
    overflow: auto;

    @media (max-width: 1366px) {
        width: 30%;
    }
    @media (max-width: 920px) {
        width: 100%;
        background-color: transparent;
    }
`;
export const title = styled.h2`
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: 2rem;
    margin: 20% 0 10% 0;
    text-align: center;

    @media (max-width: 1366px) {
        margin: 7.5% 0 5% 0;
    }
`;
export const inputDiv = styled.div`
    width: 85%;
    display: grid;
    grid-template-columns: 1fr;
    margin: 25px 0;

    @media (max-width: 1366px) {
        margin: 15px 0;
    }
`;
export const inputTitle = styled.h4`
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: 1.2rem;
    font-weight: bold;
`;
export const input = styled.input`
    width: 100%;
    height: 3rem;
    border: none;
    border-bottom: 1px solid ${colors.white};
    background-color: transparent;
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: 1.2rem;

    &:focus {
        outline: none;
    }

    @media (max-width: 1366px) {
        height: 2rem;
    }

    @media(max-width: 800px){
        margin-top: 10px;
    }
    @media(max-width: 600px){
        margin-top: 5px;
    }
`;
export const button = styled.div<{ backgroundColor?: string }>`
    width: 85%;
    height: 3rem;
    background-color: ${colors.white};
    color: ${props => props.backgroundColor ? colors.white : colors.black};
    background-color: ${props => props.backgroundColor ?? colors.white};
    border: 1px solid ${colors.white};
    text-align: center;
    line-height: 3rem;
    font-family: ${fonts.main};
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5%;
    text-transform: uppercase;
    
    &.signupBtn{
        animation: pulse 1.5s infinite;
        margin-top: 10%;

        @media(max-width: 1366px){
            margin-top: 5%;
        }

        @media (max-width: 600px) {
            margin-top: 15%;
        }
    }

    @media(max-width: 800px){
        height: 3.5rem;
    }

    &:hover {
        transform: scale(1.05);
    }
`;
export const submit = styled.input<{ backgroundColor?: string }>`
    width: 85%;
    height: 3rem;
    background-color: ${colors.white};
    color: ${props => props.backgroundColor ? colors.white : colors.black};
    background-color: ${props => props.backgroundColor ?? colors.white};
    border: 1px solid ${colors.white};
    text-align: center;
    line-height: 3rem;
    font-family: ${fonts.main};
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 5%;
    text-transform: uppercase;
    
    &.signupBtn{
        animation: pulse 1.5s infinite;
        margin-top: 10%;

        @media(max-width: 1366px){
            margin-top: 5%;
        }

        @media (max-width: 600px) {
            margin-top: 15%;
        }
    }

    @media(max-width: 800px){
        height: 3.5rem;
    }

    &:hover {
        transform: scale(1.05);
    }
`;
export const outContainer = styled.div`
    flex: 1;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;

    @media (max-width: 920px) {
        display: none;
    }
`;
export const backgroundVideo = styled.video`
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
`;
export const mainText = styled.h2`
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: 3rem;
    position: absolute;
    text-align: center;
    width: 50%;
    left: calc((100vw - 22%) / 2);
    z-index: 9999;

    @media (max-width: 1366px) {
        font-size: 2rem;
    }

    @media(max-width: 1200px){
        margin-top: -10%;
    }
`;