import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import { Toaster } from 'sonner';
import Particles from "@tsparticles/react";

const Login = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <Toaster richColors />
            {controller.general.DefaultLoading({ active: controller.loading })}
            <S.loginContainer onSubmit={e => controller.login(e)}>
                <S.title>AREZDOT ERP</S.title>
                <S.inputDiv>
                    <S.inputTitle>Email:</S.inputTitle>
                    <S.input autoComplete='email' type="email" name='email' />
                </S.inputDiv>
                <S.inputDiv>
                    <S.inputTitle>Senha:</S.inputTitle>
                    <S.input autoComplete='current-password' type="password" name='password' />
                </S.inputDiv>
                <S.forgotPassLink>{'ESQUECI MINHA SENHA '.toUpperCase()}</S.forgotPassLink>
                <S.submit type='submit' value={'login'} className='loginBtn' />
                <S.button onClick={() => controller.general.GoToRoute('/signup')} backgroundColor='transparent'>cadastre-se</S.button>
            </S.loginContainer>
            <S.outContainer>
                <S.mainText>{'arezdot erp, a ferramenta que vai impulsionar o crescimento da sua empresa'.toUpperCase()}</S.mainText>
            </S.outContainer>
            <Particles
                id="tsparticles"
                particlesLoaded={controller.particlesLoaded as any}
                options={controller.options}
            />
        </S.main>
    );
}

export default Login;