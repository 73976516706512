import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import { useController } from './controller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const errorInfoWindow = styled.div`
    width: 450px;
    padding: 20px;
    gap: 20px;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.darkerGrey};
    border-radius: 10px;

    @media (max-width: 500px) {
        width: 80%;
        flex-direction: column;
    }
`;

export const errorIcon = styled(FontAwesomeIcon)<{ color: string }>`
    font-size: 100px;
    color: ${props => props.color};
`;

export const errorInfo = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid ${colors.darkerGrey};
    padding: 15px 0 15px 20px;

    @media (max-width: 500px) {
        border-left: none;
        padding-left: 0;
    }
`;

export const errorTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 20px;
    color: ${colors.black};
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
    line-height: 1.5;
    width: 100%;

    @media (max-width: 500px) {
        text-align: center;
    }
`;

export const errorDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    color: ${colors.black};
`;