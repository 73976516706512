import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import moment from 'moment';

const NoItems = (props: any) => {
    return (
        <S.noItems>
            <S.noItemsIcon icon={props.controller.general.icons.faQuestionCircle} />
            <S.noItemsTitle>Nenhum item encontrado</S.noItemsTitle>
        </S.noItems>
    )
};

const Blocked = (props: any) => {
    return (
        <S.blockedItems>
            <S.blockedItemsIcon icon={props.controller.general.icons.faBan} />
            <S.blockedItemsTitle>Você não possui permissão para visualizar isso.</S.blockedItemsTitle>
        </S.blockedItems>
    )
};

const Dashboard = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Dashboard' />
                <S.mainArea>
                    <S.infoBits>
                        {(controller.infoBits ?? []).map((infoBit, index) => (
                            <S.infoBit key={index}>
                                <controller.general.DefaultLoadingFit active={controller.loaders.financialMovements} />
                                <S.infoBitTitle>{infoBit.title}</S.infoBitTitle>
                                {(infoBit.sections ?? []).map((section, index) => (
                                    <S.infoBitSection key={index}>
                                        <S.infoBitSectionTitle>{section.title}</S.infoBitSectionTitle>
                                        <S.infoBitSectionContent>{section.content}</S.infoBitSectionContent>
                                    </S.infoBitSection>
                                ))}
                            </S.infoBit>
                        ))}
                    </S.infoBits>
                    <S.middleInfoSections>
                        <S.middleSection>
                            <S.middleSectionTitle>Próximas faturas</S.middleSectionTitle>
                            {controller.nextExpenses.length > 0 ?
                                <S.middleSectionContent>
                                    <controller.general.DefaultLoadingFit active={controller.loaders.nextExpenses} />
                                    {(controller.nextExpenses ?? []).map((expense: any, index) => (
                                        <S.middleSectionPart key={index}>
                                            <S.middleSectionPartTitle>{expense.title} - {expense.date}</S.middleSectionPartTitle>
                                            <S.middleSectionPartContent>R$ {controller.general.formatMoney(controller.general.formatMoney(expense.value.toString()))}</S.middleSectionPartContent>
                                        </S.middleSectionPart>
                                    ))}
                                </S.middleSectionContent>
                                : (controller.financialPermissionBlock ? <Blocked controller={controller} /> : <NoItems controller={controller} />)
                            }
                        </S.middleSection>

                        <S.middleSection>
                            <S.middleSectionTitle>Próximos eventos</S.middleSectionTitle>
                            {controller.nextEvents.length > 0 ?
                                <S.middleSectionContent>
                                    <controller.general.DefaultLoadingFit active={controller.loaders.nextEvents} />
                                    {(controller.nextEvents ?? []).map((event: any, index) => (
                                        <S.middleSectionPart key={index}>
                                            <S.middleSectionPartTitle>{event.title}</S.middleSectionPartTitle>
                                            <S.middleSectionPartContent>{event.start} <br /> {event.end}</S.middleSectionPartContent>
                                        </S.middleSectionPart>
                                    ))}
                                </S.middleSectionContent>
                                : (controller.eventsPermissionBlock ? <Blocked controller={controller} /> : <NoItems controller={controller} />)
                            }
                        </S.middleSection>

                        <S.middleSection>
                            <S.middleSectionTitle>Estoque</S.middleSectionTitle>
                            {controller.stock.length > 0 ?
                                <S.middleSectionContent>
                                    <controller.general.DefaultLoadingFit active={controller.loaders.stock} />
                                    {(controller.stock ?? []).map((item: any, index) => (
                                        <S.middleSectionPart key={index}>
                                            <S.middleSectionPartTitle>{item.name}</S.middleSectionPartTitle>
                                            <S.middleSectionPartContent>{item.stock ?? 'Não definido'}</S.middleSectionPartContent>
                                        </S.middleSectionPart>
                                    ))}
                                </S.middleSectionContent>
                                : (controller.stockPermissionBlock ? <Blocked controller={controller} /> : <NoItems controller={controller} />)
                            }
                        </S.middleSection>

                        <S.annualBillingByMonth>
                            <controller.general.DefaultLoadingFit active={controller.loaders.financialMovements} />
                            {(controller.billingDataset ?? []).length > 0 ?
                                <S.barChart
                                    series={controller.billingSetExtra ?? []}
                                    dataset={controller.billingDataset ?? []}
                                    xAxis={[{ scaleType: 'band', dataKey: 'month', tickPlacement: 'middle' }]}
                                    height={controller.innerWidth <= 1200 ? (controller.innerWidth <= 500 ? 250 : 300) : 400}
                                    colors={['#0088FE', '#00C49F']}
                                    margin={{ left: 80 }}
                                    borderRadius={5}
                                    sx={{
                                        [`& .MuiChartsLegend-series > text > *`]: {
                                            color: '#FFF',
                                            fill: '#FFF'
                                        },
                                        [`& .MuiChartsAxis-tickLabel > *`]: {
                                            fill: '#FFF'
                                        },
                                        [`& .MuiChartsAxis-tickContainer > line`]: {
                                            stroke: '#FFF'
                                        },
                                        [`& .MuiChartsAxis-root > line`]: {
                                            stroke: '#FFF'
                                        },
                                    }} />
                                : (controller.financialPermissionBlock ? <Blocked controller={controller} /> : <NoItems controller={controller} />)
                            }
                        </S.annualBillingByMonth>
                    </S.middleInfoSections>

                    <S.bigInfoSections>
                        <S.profitsDivision>
                            <controller.general.DefaultLoadingFit active={controller.loaders.financialMovements} />
                            <S.bigSectionTitle>Maiores receitas {moment().year()}</S.bigSectionTitle>
                            {(controller.profitDivisionDataset ?? []).length > 0 ?
                                <S.profitsChartWrapper>
                                    <S.pieChart
                                        series={[{
                                            data: controller.profitDivisionDataset ?? [],
                                            innerRadius: controller.innerWidth <= 1200 ? (controller.innerWidth <= 500 ? 30 : 40) : 60,
                                            outerRadius: controller.innerWidth <= 1200 ? (controller.innerWidth <= 500 ? 125 : 105) : 135,
                                            cx: controller.innerWidth <= 1200 ? (controller.innerWidth <= 900 ? (controller.innerWidth <= 500 ? (controller.innerWidth / 2.8) : (controller.innerWidth / 2.55)) : (controller.innerWidth / 10)) : (controller.innerWidth <= 1366 ? controller.innerWidth / 9 : controller.innerWidth / 10),
                                        }]}
                                        sx={{
                                            [`& .MuiChartsLegend-series > text > *`]: {
                                                color: '#FFF',
                                                fill: '#FFF'
                                            },
                                            [`& .MuiChartsAxis-tickLabel > *`]: {
                                                fill: '#FFF'
                                            },
                                            [`& .MuiChartsAxis-tickContainer > line`]: {
                                                stroke: '#FFF'
                                            },
                                            [`& .MuiChartsAxis-root > line`]: {
                                                stroke: '#FFF'
                                            },
                                            flexGrow: 1,
                                        }}
                                        slotProps={{ legend: { hidden: true } }}
                                        height={controller.innerWidth <= 1200 ? (controller.innerWidth <= 500 ? 350 : 400) : 350}
                                    />
                                    <S.profitsPieChartItems>
                                        {(controller.profitDivisionDataset ?? []).map((item: any, index) => (
                                            <S.profitsPieChartUniqueItem key={index}>
                                                <S.profitsPieChartItemColor color={item.color} />
                                                <S.profitsPieChartItemContent>{item.label}</S.profitsPieChartItemContent>
                                            </S.profitsPieChartUniqueItem>
                                        ))}
                                    </S.profitsPieChartItems>
                                </S.profitsChartWrapper>
                                : (controller.financialPermissionBlock ? <Blocked controller={controller} /> : <NoItems controller={controller} />)
                            }
                        </S.profitsDivision>
                        <S.collaboratorsRanking>
                            <controller.general.DefaultLoadingFit active={controller.loaders.collaboratorsRanking} />
                            <S.bigSectionTitle>Ranking de colaboradores</S.bigSectionTitle>
                            {(controller.collaboratorsRanking ?? []).length > 0 ?
                                <S.collaboratorsOfTheRanking>
                                    <S.collaboratorsRankingContent isTitle={true}>
                                        <S.collaboratorsRankingSection>Posição</S.collaboratorsRankingSection>
                                        <S.collaboratorsRankingSection>Colaborador</S.collaboratorsRankingSection>
                                        <S.collaboratorsRankingSection>Vendas</S.collaboratorsRankingSection>
                                        <S.collaboratorsRankingSection>Valor médio</S.collaboratorsRankingSection>
                                    </S.collaboratorsRankingContent>
                                    {(controller.collaboratorsRanking ?? []).map((ranker: any, index) => {
                                        return (
                                            <S.collaboratorsRankingContent key={index}>
                                                <S.collaboratorsRankingSection>{index + 1}º</S.collaboratorsRankingSection>
                                                <S.collaboratorsRankingSection>{ranker.name}</S.collaboratorsRankingSection>
                                                {/* <S.collaboratorsRankingSectionAvatar title='Colaborador: Gustavo Faria' src={`${process.env.REACT_APP_BASE_URL}app/images/logo/logo_arezdot_icon.ico`} /> */}
                                                <S.collaboratorsRankingSection>{ranker.items}</S.collaboratorsRankingSection>
                                                <S.collaboratorsRankingSection>R$ {controller.general.formatMoney(((ranker.totalValue / 100) / ranker.items).toFixed(2).toString().replace(/[.,]/g, ""))}</S.collaboratorsRankingSection>
                                            </S.collaboratorsRankingContent>
                                        )
                                    })}
                                </S.collaboratorsOfTheRanking>
                                : (controller.financialPermissionBlock ? <Blocked controller={controller} /> : <NoItems controller={controller} />)
                            }
                        </S.collaboratorsRanking>
                    </S.bigInfoSections>
                </S.mainArea>
            </S.generalArea>
        </S.main>
    )
}

export default Dashboard;

// Página inicial do sistema (Dashboard)