import styled from 'styled-components';
import {colors} from '../../../utils/colors';
import {fonts} from '../../../utils/fonts';

export const mainContainer = styled.div`
    width: 100vw;
    height: 100dvh;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
    display: grid;
    background: black;
`;

export const container = styled.div`
    width: 100%;
    height: 50%;
    place-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -100px;

    @media(max-width: 768px){
        margin-top: -50px;
    }
`;
export const imgLogo = styled.img`
    width: 350px;
    object-fit: cover;
    align-self: center;
    justify-self: center;

    @media(max-width: 425px){
        width: 250px;
    }
`;
export const mainText = styled.h1`
    width: 100%;
    text-align: center;
    color: black;
    font-size: 40px;
    font-family: ${fonts.main};
    font-weight: 800;
    text-transform: uppercase;
    text-shadow: 0 0 10px #E6CF00, 0 0 10px #E6CF00;

    @media(max-width: 768px){
        font-size: 35px;
    }

    @media(max-width: 425px){
        font-size: 25px;
    }
`;