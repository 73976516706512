import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { addCompanyModalProps } from '../../../utils/types';
import { plan } from './types'
import axios from 'axios';

export const useController = (props: addCompanyModalProps) => {
    const [modalActive, setModalActive] = useState(false);
    const [step, setStep] = useState(0);
    const [currentLogo, setCurrentLogo] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [companyInfo, setCompanyInfo] = useState<any>({
        _id: general.getCompany()._id,
        fiscal: {
            nf_ibge_city_code: "",
            nf_city_inscription: "",
            nf_state_inscription: "",
            tributary_regime: "",
            main_cnae: ""
        },
    });

    const resetPage = () => {
        setStep(0);
        props.setModalActive(false);
    }

    const fieldFormat = (field: string, value: any) => {
        switch (field) {
            case 'address.zip_code': {
                if (value.length >= 10) {
                    axios.get(`https://brasilapi.com.br/api/cep/v1/${value.replace('-', '').replace('.', '')}`).then((response) => {
                        if (response.status === 200) {
                            setCompanyInfo((prev: any) => ({ ...prev, address: { ...prev.address, street: response?.data?.street ?? '', neighborhood: response?.data?.neighborhood ?? '', city: response?.data?.city, state: response?.data?.state ?? '' } }));
                        }
                    }).catch((err) => {
                        general.notify('CEP inválido', 'error');
                    });
                }
                return general.customMask(value, '##.###-###', "#", true);
            }
            default:
                return value;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        setCompanyInfo((prev: any) => ({ ...prev, [name]: fieldFormat(name, value) }));
    }

    const handleFiscalInfoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        let originalName = name;
        const child = name.includes('.') ? name.split('.')[1] : '';
        if (child !== '') {
            name = name.split('.')[0];
            setCompanyInfo((prev: any) => ({ ...prev, [name]: { ...prev.fiscal, [child]: fieldFormat(originalName, value) } }));
        }
        else {
            setCompanyInfo((prev: any) => ({ ...prev, [name]: fieldFormat(name, value) }));
        }
    }

    const getStoredCompany = () => {
        const company = general.getCompany();
        if (company) {
            setCompanyInfo({
                ...companyInfo,
                fiscal: {
                    nf_ibge_city_code: company.fiscal?.nf_ibge_city_code ?? "",
                    nf_city_inscription: company.fiscal?.nf_city_inscription ?? "",
                    nf_state_inscription: company.fiscal?.nf_state_inscription ?? "",
                    tributary_regime: company.fiscal?.tributary_regime ?? "",
                    main_cnae: company.fiscal?.main_cnae ?? ""
                }
            });
        }
    }

    const validateFields = () => {
        return true;
    }

    const updateInfo = async () => {
        if (!validateFields()) return;
        setLoading(true);
        await general.api.put(`/company/update/${general.getCompany()._id}`, {
            ...companyInfo,
            fiscal: {
                ...companyInfo.fiscal,
                nf_city_inscription: companyInfo.fiscal.nf_city_inscription.replace(/\D/g, ''),
                nf_state_inscription: companyInfo.fiscal.nf_state_inscription.replace(/\D/g, ''),
                nf_ibge_city_code: companyInfo.fiscal.nf_ibge_city_code.replace(/\D/g, ''),
                tributary_regime: companyInfo.fiscal.tributary_regime,
                main_cnae: companyInfo.fiscal.main_cnae
            }
        }).then((response: any) => {
            if (response.data.status == 403) {
                props.setModalActive(false);
                setLoading(false);
                return general.notify(response.data.message, 'error')
            };
            general.notify(response.data.message, 'success');
            localStorage.setItem('companyObject', JSON.stringify(response.data.data));
            getStoredCompany();
            resetPage();
            setLoading(false);
        }).catch((err) => {
            general.notify(err.response.data.message, 'error');
            setLoading(false);
        });
    }

    useEffect(() => {
        getStoredCompany();
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
        }
    }, [props.modalActive]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })
    }, []);

    return {
        general,
        modalActive,
        step,
        setStep,
        companyInfo,
        setCompanyInfo,
        handleInputChange,
        updateInfo,
        currentLogo,
        loading,
        handleFiscalInfoChange,
    };
}

export default useController;