import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import useController from './controller';

const showOptionsContainer = keyframes`
    from{
        transform: scaleY(0);
    }
    to{
        transform: scaleY(1);
    }
`;

export const mainContainer = styled.div<{ width?: string, isGeneralField?: boolean }>`
    display: flex;
    flex-direction: column;
    width: ${props => props.width ?? '100%'};
    position: relative;

    ${props => props.isGeneralField && css`
        @media(max-width: 1366px){
            width: 96.5%;
            margin-left: -3px;
        }

        @media(max-width: 1200px){
            width: 95%;
        }

        @media(max-width: 500px){
            width: 97%;
            align-self: center;
            justify-self: center;
        }
    `}
`;

export const searchContainer = styled.div<{ showOptionsContainer: boolean, transparent?: boolean }>`
    display: flex;
    align-items: center;
    width: calc(100% - 10px);
    background-color: ${props => props.transparent ? 'transparent' : colors.white};
    border: 1px solid ${colors.grey};
    border-radius: ${props => props.showOptionsContainer ? '5px 5px 0 0' : '5px'};
    padding: 5px;
    border-bottom: ${props => props.showOptionsContainer ? 'none' : `1px solid ${colors.grey}`};
    ${props => props.showOptionsContainer && css`
        padding-bottom: 6px;    
    `}
`;

export const searchInput = styled.input`
    display: flex;
    width: 95%;
    border: none;
    font-family: ${fonts.main};
    font-size: 1rem;
    padding: 8px;
    outline: none;
    background-color: transparent;
`;

export const searchIcon = styled(general.FontAwesomeIcon)`
    color: ${colors.black};
    font-size: 1.3rem;
    margin-left: 10px;
`;

export const optionsContainer = styled.div<{ showOptionsContainer: boolean }>`
    display: ${props => props.showOptionsContainer ? 'flex' : 'none'};
    flex-direction: column;
    width: 100%;
    max-height: 150px;
    border-top: none;
    top: 100%;
    left: 0;
    z-index: 9999;
    border: 1px solid ${colors.grey};
    animation: ${showOptionsContainer} 0.3s;
    position: absolute;
    transition-duration: 0.3s;
    transform-origin: top;
    overflow-y: auto;
    background-color: ${colors.white};
    border-radius: 0 0 5px 5px;
`;

export const option = styled.div`
    display: flex;
    width: calc(100% - 20px);
    text-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px;
    border-bottom: 1px solid ${colors.darkGrey};
    cursor: pointer;
    transition-duration: 0.3s;
    flex-direction: column;

    &:hover{
        background-color: ${colors.lightGrey};
    }

    &:last-child{
        border-bottom: none;
        border-radius: 0 0 5px 5px;
    }
`;

export const optionTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 1rem;
    font-weight: 700;
    color: ${colors.black};
`;
export const optionDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 0.9rem;
    color: ${colors.darkerGrey};
    text-align: justify;
    padding: 0 20px 0 1px;
`;

export const noOptions = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-family: ${fonts.main};
    font-size: 1rem;
    color: ${colors.darkGrey};
`;

export const noOptionsIcon = styled(general.FontAwesomeIcon)`
    color: ${colors.darkGrey};
    font-size: 1.5rem;
    margin-right: 10px;
`;

export const noOptionsText = styled.h2`
    font-family: ${fonts.main};
    font-size: 1rem;
    color: ${colors.darkGrey};
`;