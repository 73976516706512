import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import general from '../../../../utils/general';
import useController from './controller';
import Avatar from 'react-avatar';

export const main = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
export const inputContainer = styled.div<{hidden: boolean}>`
    width: 80%;
    display: ${props => props.hidden ? 'none' : 'flex'};
    flex-direction: row;
    background-color: ${colors.backgroundWhite};
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.grey};

    :focus-within{
        transform: scale(1.03);
    }

    @media(max-width: 1366px){
        width: 94%;
    }

    @media(max-width: 1200px){
        width: 93%;
    }

    @media(max-width: 500px){
        width: 94%;
        align-self: center;
        justify-self: center;
        :focus-within{
            transform: scale(1.025);
        }
    }
`;
export const input = styled.input<{ width?: number }>`
    display: flex;
    height: 20px;
    width: ${props => props.width ? props.width + 'ch' : 'auto'};
    border: none;
    outline: none;
    background-color: transparent;
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 5px;
    color: ${props => props.disabled ? colors.darkGrey : colors.black};

    :nth-child(1){
        font-weight: 600;
    }
    :nth-child(2){
        flex: 1;
        text-align: end;
        width: 100%;
    }
`;

export const deleteIcon = styled(FontAwesomeIcon)`
    color: ${colors.grey};
    font-size: 20px;
    cursor: pointer;
    margin-left: 10px;
    transition-duration: 0.05s;

    :hover{
        color: ${colors.red};
    }
`;