import urlRegex from 'url-regex'

export const validateEmail = (email: string) => {
    // Remover espaços em branco antes e depois da string
    const trimmedEmail = email.trim()

    // Expressão regular para validar o formato do e-mail
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Verificar se o e-mail é válido
    return emailRegex.test(trimmedEmail)
}

export function validateName(name: string): boolean {
    // Remover espaços em branco no início e no final
    const trimmedName = name.trim()

    // Remover caracteres especiais e números usando replace
    const nameOnlyLetters = trimmedName.replace(/[^a-zA-ZÀ-ÿ ]/g, '')

    // Verificar se o nome contém apenas letras
    if (trimmedName !== nameOnlyLetters) {
        return false
    }

    // Verificar se o nome tem pelo menos duas palavras separadas por espaço
    const words = nameOnlyLetters.split(' ')
    if (words.length < 2) {
        return false
    }

    return true
}

export const validateURL = (url: string) => {
    // Remover espaços em branco antes e depois da string
    const trimmedURL = url.trim()

    // Verificar se a URL é válida usando a biblioteca url-regex
    return urlRegex({ exact: true, strict: false }).test(trimmedURL)
}

export default {
    validateEmail,
    validateName,
    validateURL
}