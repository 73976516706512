import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import { useController } from './controller';

export const loadingContainer = styled.div<{borderRadius: number}>`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.60);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.60);
    justify-content: center;
    align-items: center;
    border-radius: ${props => props.borderRadius}px;
    display: flex;
    z-index: 99999999999999999999999999999999999999999;
    justify-content: center;
    align-items: center;
    animation: ${keyframes`
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    `} 0.25s linear;
`;
export const loadingDiv = styled.div<{size: number}>`
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    border-radius: 50%;
    border: 5px solid ${colors.primaryYellow};
    border-top: 5px solid ${colors.black};
    animation: ${keyframes`
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    `} 1s linear infinite;
`;