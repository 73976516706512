import general from '../../../utils/general';
import { useState, useRef, useEffect } from 'react';

export const useController = () => {

    return {
        general
    };
}

export default useController;