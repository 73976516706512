import general from '../../../utils/general';
import { useEffect, useState, useMemo } from 'react';
import { loadSlim } from "@tsparticles/slim";
import { initParticlesEngine } from "@tsparticles/react";
import {
    type Container,
    type ISourceOptions,
    MoveDirection,
    OutMode,
  } from "@tsparticles/engine";
import IAccount from '@customTypes/account'

export const useController = () => {
    const [init, setInit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [account, setAccount] = useState<IAccount>({
        name: '',
        email: '',
        phone: '',
        password: '',
        password_confirm: '',
        birthdate: null,
        accountType: 2,
        avatarUrl: '',
        lastLoginAt: new Date(),
        address: {
            street: '',
            number: '',
            neighborhood: '',
            city: '',
            state: '',
            country: '',
            zip_code: '',
            complement: '',
            latitude: 0,
            longitude: 0
        }
    });

    const particlesLoaded = (container?: Container) => {};

    const checkForObligatoryFields = (accountData: any) => {
        let blocked = false;
        let message = 'Os seguintes campos são obrigatórios: ';

        if(accountData.name === ''){
            blocked = true;
            message += 'Nome, ';
        }

        if(accountData.email === ''){
            blocked = true;
            message += 'Email, ';
        }

        if(accountData.password === ''){
            blocked = true;
            message += 'Senha, ';
        }

        if(accountData.password_confirm === ''){
            blocked = true;
            message += 'Confirmação de senha, ';
        }

        message = message.substring(0, message.length - 2) + '.';

        return {blocked, message};
    }

    const checkPassword = (passwords: any) => {
        if(passwords.password !== passwords.password_confirm){
            return false;
        }
        return true;
    }

    const checkForSpacesInPassword = (passwords: any) => {
        if(passwords.password.includes(' ') || passwords.password_confirm.includes(' ')){
            return true;
        }
        return false;
    }

    const createAccount = (e: any) => {
        e.preventDefault();
        let name = (document.querySelector('input[name="name"]') as HTMLInputElement).value;
        let email = (document.querySelector('input[name="email"]') as HTMLInputElement).value;
        let password = (document.querySelector('input[name="password"]') as HTMLInputElement).value;
        let password_confirm = (document.querySelector('input[name="password_confirm"]') as HTMLInputElement).value;

        const checkFields = checkForObligatoryFields({
            name,
            email,
            password,
            password_confirm
        });
        const checkPass = checkPassword({
            password,
            password_confirm
        });
        const checkSpaces = checkForSpacesInPassword({
            password,
            password_confirm
        });

        if(checkFields.blocked){
            general.notify(checkFields.message, 'error');
            return;
        }
        if(!checkPass){
            general.notify('As senhas não coincidem', 'error');
            return;
        }

        if(checkSpaces){
            general.notify('A senha não pode conter espaços', 'error');
            return;
        }

        try{
            setLoading(true);
            general.api.post('account/create', {
                ...account,
                name,
                email,
                password,
                password_confirm
            })
            .then((response: any) => {
                if(response.data.status === 409){
                    setLoading(false);
                    general.notify(response.data.message, 'error');
                  }
                else{
                    setLoading(false);
                    setTimeout(() => general.GoToRoute('login'), 2000);
                    general.notify('Conta criada com sucesso, redirecionando para login...', 'success');
                }

            })
            .catch((error: any) => {
                setLoading(false);
                general.notify('Erro ao tentar criar conta', 'error');
                console.log(error);
            })
        }
        catch(err){
            setLoading(false);
            general.notify('Erro ao tentar criar conta', 'error');
            console.log(err);
        }
    }

    useEffect(() => {
        initParticlesEngine(async (engine) => {
          await loadSlim(engine);
        }).then(() => {
          setInit(true);
        });
      }, []);

      const options: ISourceOptions = useMemo(
        () => ({
          background: {
            color: {
              value: "#574f03",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "grab",
              },
            },
            modes: {
              push: {
                quantity: 4,
              },
              grab: {
                distance: 200,
                quantity: 2,
                links: {
                  opacity: 1,
                },
              }
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 250,
              enable: true,
              opacity: 0.3,
              width: 1,
            },
            move: {
              direction: MoveDirection.none,
              enable: true,
              outModes: {
                default: OutMode.out,
              },
              random: true,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
              },
              value: 120,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 2 },
            },
          },
          detectRetina: true,
        }),
        [],
      );

    return {
        general,
        createAccount,
        account,
        particlesLoaded,
        options,
        loading
    }
}

export default useController;