import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import useController from './controller';

export const inputContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    background-color: ${colors.backgroundWhite};
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.grey};

    @media(max-width: 1366px){
        width: 94%;
    }

    @media(max-width: 1200px){
        width: 95%;
    }

    @media(max-width: 500px){
        width: 94%;
        align-self: center;
        justify-self: center;
        :focus-within{
            transform: scale(1.025);
        }
    }
`;
export const selectInput = styled.select`
    display: flex;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 5px;
    color: ${colors.black};
    cursor: pointer;
`;
export const option = styled.option`
    display: flex;
    height: 20px;
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 5px;
    color: ${colors.black};
    cursor: pointer;
`;