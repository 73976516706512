import React, { useEffect, useState } from 'react';
import * as S from './styles';

export const Select = (props: {
    name: string;
    required?: boolean;
    label: string;
    value: string;
    onChange: any;
    options?: Array<{ value: string | number; label: string }> | Array<string>;
}) => {
    return (
        <S.select>
            <S.label htmlFor={props.name}>{props.label}</S.label>
            <S.selectInput
                name={props.name}
                id={props.name}
                defaultValue={props.value}
                required={props.required ?? false}
                onChange={props.onChange}
            >
                {(props.options ?? []).map((option, index) => {
                    if (typeof option === 'string') {
                        return <option key={`${option.split(' ').join('_')}`} value={option.split(' ').join(' ')}>{option.split(' ').join(' ')}</option>;
                    }
                    return <option key={`option_${index}`} value={option.value}>{option.label}</option>;
                })}
            </S.selectInput>
        </S.select>
    );
}

export default Select;