import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';

const PaymentMissing = (props: any) => {
    const controller = useController();
    return (
        <S.main>
            <S.errorInfoWindow>
                <S.errorIcon icon={controller.general.icons.faDollarSign} color={(controller.general.getCompany().status != 'ACTIVE' && controller.general.getCompany().status != 'PAYMENT_PENDING') ? controller.general.utilColors.red : controller.general.utilColors.green} />
                <S.errorInfo>
                    <S.errorTitle>{(controller.general.getCompany().status != 'ACTIVE' && controller.general.getCompany().status != 'PAYMENT_PENDING') ? 'Erro no pagamento' : 'Tentativa de pagamento'}</S.errorTitle>
                    {(controller.general.getCompany().status != 'ACTIVE' && controller.general.getCompany().status != 'PAYMENT_PENDING') ?
                        <S.errorDescription>Seu plano expirou e/ou houve um problema com a renovação/ativação da conta, por favor siga os passos: <strong><br /><br />→ MENU <br />→ CONFIGURAR EMPRESA <br />→ CONFIGURAR PAGAMENTO <br />→ CONFIGURE UM NOVO CARTÃO <br />→ AGUARDE ALGUNS MINUTOS.</strong></S.errorDescription>
                        : 
                        <S.errorDescription>Uma tentativa de pagamento foi realizada, por favor aguarde alguns minutos e atualize a página.</S.errorDescription>
                    }
                </S.errorInfo>
            </S.errorInfoWindow>
        </S.main>
    )
}

export default PaymentMissing;