import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import Calendar from 'react-calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
`;

const modalOpenAnimation = keyframes`
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

export const calendarModal = styled.div`
    border-radius: 10px;
    padding: 10px 15px 15px 15px;
    background-color: white;
    animation: ${modalOpenAnimation} 0.3s;
    
    @media (max-width: 500px) {
        margin: -25% 0 0 0;
    }
`;

export const modalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 2px 5px;
`;

export const label = styled.p`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
`;

export const close = styled.p`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;

    :hover {
        color: ${colors.red};
    }
`;

export const calendar = styled(Calendar)`
    border-radius: 10px;
    padding: 5px;
    border:

    .react-calendar__navigation__arrow {
        :nth-child(1) {
            border-radius: 10px 0 0 0;
        }
        :nth-child(5) {
            border-radius: 0 10px 0 0;
        }
    }

    .react-calendar__tile--now{
        background-color: ${colors.black};
        border-radius: 3px;
        color: white;

        :hover {
            background-color: ${colors.black};
        }
    }

    .react-calendar__tile--active{
        border-radius: 3px;
    }
`;

export const date = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px 0;
`;

export const labelInput = styled.div`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
`;

export const dateInputDiv = styled.div`
    font-family: ${fonts.main};
    font-size: 13px;
    border-bottom: 1px solid ${colors.grey};
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
`;

export const dateInput = styled.p`
    font-family: ${fonts.main};
    font-size: 13px;
    color: ${colors.black};
`;

export const dateInputArrow = styled(FontAwesomeIcon)`
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
`;