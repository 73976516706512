import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
`;
export const operationsArea = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;

    &::after {
        content: '';
        flex: auto;
    }

    @media (max-width: 900px) {
        margin-bottom: 60px;
    }

    @media (max-width: 500px) {
        padding: 10px;
`;
export const calendarWrapper = styled.div`
    display: flex;
    position: absolute;
    flex-direction: column;
    width: 92.5%;
    height: 78%;
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 10px;

    @media (max-width: 1800px) {
        width: 92%;
    }

    @media (max-width: 1620px) {
        width: 91%;
    }

    @media (max-width: 1450px) {
        width: 90%;
    }

    @media (max-width: 1366px) {
        width: 89.5%;
        height: 74%;

        @media(max-height: 750px){
            height: 70%;
        }

        @media(max-height: 620px){
            height: 65%;
        }
    }

    @media (max-width: 1200px) {
        width: 87%;
        height: 67%;
    }

    @media (max-width: 900px) {
        width: 79.5%;
        height: 80%;
    }

    @media (max-width: 500px) {
        width: 74%;
        height: 78%;
        padding: 10px;
    }
`;
export const createTaskButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 40px);
    height: 50px;
    align-self: center;
    background-color: ${colors.black};
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: 1.15em;
    font-weight: 600;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.15s;

    &:hover {
        box-shadow: 0 0 5px ${colors.black};
    }

    @media (max-width: 500px) {
        width: calc(100% - 20px);
        font-size: 1em;
    }
`;
/* show more events */
const showMoreEventsModalAnimation = keyframes`
    from {
        opacity: 0;
        transform: translateY(100px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const showMoreEventsModal = styled.div<{ active: boolean }>`
    display: ${props => props.active ? 'flex' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    justify-content: center;
    align-items: center;
`;
export const showMoreEventsModalContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 50%;
    background-color: ${colors.white};
    border-radius: 10px;
    padding: 20px;
    animation: ${showMoreEventsModalAnimation} 0.3s ease forwards;

    @media (max-width: 1200px) {
        width: 60%;
        height: 60%;
        margin-top: -100px;
    }

    @media (max-width: 900px) {
        width: 80%;
        height: 70%;
    }

    @media (max-width: 500px) {
        width: 85%;
    }
`;
export const showMoreEventsModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;
export const showMoreEventsModalTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 1.5em;
    font-weight: 600;
`;
export const showMoreEventsModalClose = styled(FontAwesomeIcon)`
    color: ${colors.grey};
    font-size: 1.5em;
    cursor: pointer;
    transition: 0.1s;

    &:hover {
        color: ${colors.red};
    }
`;
export const showMoreEventsModalBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 100%;
    overflow-y: auto;
`;
export const showMoreEvent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px;
    margin: 0 5px;
    border-radius: 5px;
    background-color: ${colors.lightGrey};
    cursor: pointer;

    &:hover {
        background-color: ${colors.grey};
    }
`;
export const showMoreEventTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 1em;
    font-weight: 600;
`;
export const showMoreEventDescription = styled.p`
    font-family: ${fonts.main};
    font-size: .8em;
    font-weight: 600;
`;
export const showMoreEventTime = styled.p`
    font-family: ${fonts.main};
    font-size: .8em;
    font-weight: 600;
`;