import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { colors } from '../../../utils/colors';
import general from '../../../utils/general';


const Pagination = (props: {
    total_pages: number;
    page: number;
    setPage: (page: number) => void;
}) => {
    const controller = useController(props);

    return (
        <S.paginationArea>
            <S.paginationButton onClick={() => controller.changePage((props.page + 1) - 1)} icon={controller.general.icons.faChevronLeft} />
            <S.paginationInput type='number' value={general.formatNumber(controller.paginationPage, 1, false)} onChange={(e) => controller.changePage(parseInt(e.target.value))} />
            de {props.total_pages ?? 1}
            <S.paginationButton onClick={() => controller.changePage((props.page + 1) + 1)} icon={controller.general.icons.faChevronRight} />
        </S.paginationArea>
    );
}

export default Pagination;