import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import useController from './controller';
import Avatar from 'react-avatar';

const animationPopUp = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`;

export const main = styled.div<{ active: boolean, animationOpacity: boolean }>`
    display: flex;
    width: ${props => props.active ? '100vw' : '0px'};
    height: ${props => props.active ? '100vh' : '0px'};
    opacity: ${props => props.animationOpacity ? 1 : 0};
    transition-duration: 0.3s;
    transition-property: opacity;
    position: fixed;
    background-color: rgba(0,0,0,0.75);
    z-index: 999;
    justify-content: center;
    align-items: center;
`;
export const modal = styled.div<{ animationInOut: boolean, step: number }>`
    width: ${props => props.step === 2 ? '60%' : (props.step === 1 ? '35%' : '45%')};
    max-height: 80%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    opacity: ${props => props.animationInOut ? 1 : .5};
    transform: ${props => props.animationInOut ? 'translateY(0px)' : 'translateY(100px)'};
    transition-duration: 1s;

    @media(max-height: 600px){
        max-height: 90%;
    }

    @media(max-width: 1500px){
        width: 65%;
        margin-top: -5%;

        @media(max-height: 700px){
            width: 75%;
            max-height: 85%;
        }
    }
    @media(max-width: 1024px){
        @media(min-height: 1100px){
            width: 85%;
            margin-top: -25%;
            min-height: auto;
        }
    }
    @media(max-width: 800px){
        width: ${props => (props.step === 2 || props.step == 3) ? '95%' : '60%'};
        margin-top: -10%;

        @media(min-width: 700px){
            width: ${props => (props.step === 2 || props.step == 3) ? '90%' : '60%'};
            min-height: ${props => props.step === 2 ? '80%' : 'auto'};
            transition-duration: 0.3s;
        }
    }
    @media(max-width: 500px){
        max-height: 85%;
        width: 85%;
        margin-top: -20%;
        padding-top: 5px;
    }
`;
export const header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;
export const title = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;

    @media(max-width: 500px){
        margin: 20px 20px 20px 0;
        font-size: 14px;
    }
`;
export const close = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.red};
    cursor: pointer;

    @media(max-width: 500px){
        margin: 20px 0;
    }

    &:hover{
        transform: scale(1.2);
    }
`;
export const body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const stepContainer = styled.div`
    width: 100%;
    min-height: 100px;
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: column;

    @media(max-width: 900px){
        @media(min-width: 700px){
            margin: 0;
        }
    }
`;
const easeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
export const step = styled.div<{ active: boolean, mobileMod: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: space-between;
    animation: ${easeIn} 0.75s;

    ${props => props.active ? css`
        width: 100%;
        height: auto;
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.5s, transform 0s;

    ` : css`
        width: 0;
        height: 0;
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.5s, transform 0s;
    `}

    @media(max-width: 800px){
        flex-direction: ${props => props.mobileMod ? 'column' : 'row'};
    }
`;
export const uploadLogo = styled.div`
    display: flex;
    flex-direction: column;
    width: 47%;
    margin: 10px 0;
    position: relative;
    align-items: center;
    justify-content: center;
`;
export const avatar = styled(Avatar)<{logoImg: string}>`
    border-radius: 10px;
    transform: scale(1.2);
    cursor: pointer;
    ${props => props.logoImg && css`
        background: url(${props.logoImg}) no-repeat center center;
        background-size: cover;
        transform: scale(1.42);
        
        * {
            opacity: 0;
        }
    `}

    @media(max-width: 800px){
        transform: scale(1.05);
    }
    @media(max-width: 500px){
        transform: scale(1.1);
    }
`;
export const upload = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    margin: -26% 0 0 -26%;
    width: 150%;
    height: 150%;
    z-index: 9999;
`;
const tooltipStart = keyframes`
    0%{
        opacity: 0;
    }
    70%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
`;
const tooltipStartMobile = keyframes`
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0;
    }
    75%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
`;
export const uploadTooltip = styled.div<{logoImg: string}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 21.5%;
    margin: -21.75% 0 0 -21.75%;
    position: absolute;
    align-items: center;
    justify-content: center;
    gap: 25px;
    background-color: rgba(0,0,0,0.8);
    border-radius: 10px;
    opacity: 0;
    transition-duration: 0.3s;
    cursor: pointer;
    z-index: 1;

    ${main}:hover &{
        animation: ${tooltipStart} 2.5s 0.5s;
    }

    ${uploadLogo}:hover &{
        opacity: 1;
    }

    @media(max-width: 800px){
        margin: -5% 0 0 -5%;
        padding: 5%;
        ${props => !props.logoImg && css`animation: ${tooltipStartMobile} 7.5s 0.5s infinite;`}

        ${uploadLogo}:hover &{
            opacity: 0;
        }
    }
    @media(max-width: 500px){
        transform: scale(1.1);
    }
`;
export const uploadIcon = styled(general.FontAwesomeIcon)`
    font-size: 40px;
    color: ${colors.backgroundWhite};
`;
export const uploadText = styled.h3`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    color: ${colors.backgroundWhite};
    text-align: center;
`;
export const inputsArea = styled.div<{occupyAll?: boolean}>`
    width: ${props => props.occupyAll ? '100%' : '47%'};

    @media(max-width: 800px){
        width: 100%;
        margin-top: 20px;
    }
`;
export const inputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 5px 0;
    position: relative;

    @media(max-width: 800px){
        width: 100%;
    }
`;
export const inputLabel = styled.label`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${colors.black};
    text-decoration: capitalize;
`;
export const input = styled.input`
    font-family: ${fonts.main};
    wdith: 100%;
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;
    -moz-appearance   : textfield;
    -webkit-appearance: none;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;
export const inputSelect = styled.select`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;
export const inputOption = styled.option`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;
export const progressBar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5px;
    margin: 10px 0;
    align-items: center;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
`;
export const progress = styled.div<{ percentage: number }>`
    display: flex;
    flex-direction: row;
    width: ${props => props.percentage}%;
    height: 100%;
    justify-content: space-between;
    padding: 0 10px;
    background-color: ${colors.black};
    border-radius: 5px;
`;
export const progressPercentage = styled.div`
    font-family: ${fonts.main};
    font-size: 11px;
    font-weight: 600;
    margin-left: -8px;
    color: ${colors.white};
    padding: 3px;
    background-color: ${colors.black};
    border-radius: 5px;
    z-index: 999;
`;
export const nextBackBtns = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
`;
export const nextBackBtn = styled.button`
    border: 1px solid ${colors.black};
    border-radius: 5px;
    width: 175px;
    height: 40px;
    background-color: ${colors.backgroundWhite};
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition-duration: 0.3s;
    color: ${colors.black};
    animation: ${animationPopUp} 0.3s;

    &:hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }

    @media(max-width: 500px){
        width: 160px;
    }
`;

/* info */
export const infoArea = styled.div``;

/* plans */
export const planPeriodContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 98%;
    justify-content: center;
    align-items: center;
    margin: 10px 0 10px 1%;
    border-bottom: 1px solid ${colors.grey};
    gap: 50px;

    @media(max-width: 600px){
        gap: 5px;
    }
`;
export const planPeriod = styled.p<{ selected: boolean }>`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    color: ${props => props.selected ? colors.black : colors.darkGrey};
    margin: 10px 0;
    text-align: center;
    cursor: pointer;
    transition-duration: 0.3s;
    flex: 1;

    :nth-child(2){
        padding: 0 25px;
        border-right: 1px solid ${colors.grey};
        border-left: 1px solid ${colors.grey};

        @media(max-width: 500px){
            padding: 0 15px;
        }
    }

    &:hover{
        color: ${colors.black};
    }

    @media(max-width: 600px){
        font-size: 13px;
    }
`;
export const plansArea = styled.div`
    height: 50vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    position: relative;

    @media(max-width: 900px){
        @media(min-width: 700px){
            flex-direction: column;
            height: 62vh;
        }
    }

    @media(max-width: 600px){
        overflow-x: hidden;
    }
`;
const planAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
export const plan = styled.div<{ planShowing?: number, selected: boolean }>`
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    margin: 5px;
    transition-duration: 0.5s;
    cursor: pointer;
    height: 100%;
    z-index: 9999;

    &:hover{
        border: 1px solid ${colors.black};
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    }

    @media(max-width: 900px){
        @media(min-width: 700px){
            display: grid;
            grid-template: 
                "a a a a a a"
                "b b b c c c"
                "b b b c c c";
            width: 95%;
            height: 100%;
        }
    }

    @media(max-width: 600px){
        display: none;
        position: absolute;
        width: 78%;
        height: 90%;
        animation: ${planAnimation} .5s;

        ${props => props.planShowing && css`
            :nth-child(${props.planShowing}){
                display: flex;
            }
        `}
    }

    ${props => props.selected && css`
        border: 1px solid ${colors.black};
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);

        ${planHireBtn}{
            background-color: ${colors.black};
            color: ${colors.backgroundWhite};
        }
    `}
`;
export const planTitle = styled.h3`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    color: ${colors.black};
    margin: 10px 0;
    text-align: center;
    text-transform: uppercase;
    grid-area: a;

    @media(max-width: 1500px){
        font-size: 15px;
    }
`;
const textAnimation = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
export const planItems = styled.div`
    height: 100%;
    overflow-y: auto;
    grid-area: b;
    opacity: 0;
    animation: ${textAnimation} 0.3s ease-in-out 0.05s forwards;

    @media(max-width: 900px){
        @media(min-width: 700px){
            display: grid;
            grid-template-columns: 1fr 1fr;
            height: 100%;
            width: 98%;
        }
    }
`;
export const planItem = styled.p`
    font-family: ${fonts.main};
    font-size: 12px;
    font-weight: 600;
    color: ${colors.black};
    margin: 7px 0;

    :before{
        content: '•';
        margin-right: 5px;
    }

    @media(max-width: 1500px){
        font-size: 11px;
        margin: 4px 0;

        @media(max-height: 700px){
            margin: 2px 0;
            font-size: 10px;
        }
    }

    @media(max-width: 900px){
        @media(min-width: 700px){
            width: 100%;
        }
    }

    @media(max-width: 600px){
        font-size: 11px;
        margin: 4px 0;
    }
`;
export const planPriceAndHire = styled.div`
    grid-area: c;
`;
export const planBackNextContainer = styled.div<{ toBeReversed: boolean }>`
    display: none;
    width: 100%;
    flex-direction: ${props => props.toBeReversed ? 'row-reverse' : 'row'};
    justify-content: space-between;

    @media(max-width: 600px){
        display: flex;
    }
`;
export const planBackNextMobile = styled(general.FontAwesomeIcon)`
    display: none;
    font-size: 30px;
    color: ${colors.black};
    cursor: pointer;
    transition-duration: 0.3s;

    @media(max-width: 600px){
        display: block;
    }
`;
export const planPrice = styled.p`
    font-family: ${fonts.main};
    width: 100%;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: ${colors.darkGreen};
    margin: 10px 0 0 0;
`;
export const planMonthly = styled.p`
    font-family: ${fonts.main};
    font-size: 11px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    color: ${colors.darkerGrey};
    margin: 0 0 10px 0;
`;
export const planHireBtn = styled.p`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 800;
    padding: 10px;
    border: 1px solid ${colors.black};
    border-radius: 5px;
    transition-duration: 0.5s;
    cursor: pointer;
    color: ${colors.black};
    text-align: center;
    text-transform: uppercase;
`;
/* Confirm data */
export const confirmDataArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px 0;
    gap: 15px;
    
`;
export const confirmItem = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: relative;
`;
export const confirmLabel = styled.span`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    color: ${colors.black};
    text-transform: uppercase;
    text-align: left;
`;
export const confirmData = styled.span`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 400;
    text-transform: uppercase;
    text-shadow: 0px 0px 1px rgba(0,0,0,0.2);
    text-align: right;
`;
export const inputCupomWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    position: relative;
`;
export const inputCupom = styled.input`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 400;
    padding: 5px;
    text-transform: uppercase;
    text-align: right;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;
    -moz-appearance   : textfield;
    -webkit-appearance: none;

    &:focus{
        outline: none;
    }
`;
export const cupomStatus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 30px;
    position: relative;
`;

export const cupomStatusIcon = styled(general.FontAwesomeIcon)<{color: string}>`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 30px;
    font-size: 20px;
    color: ${props => props.color};
    position: relative;
`;

/* congratulations step */
export const congratulationsArea = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin: 50px 10%;
    gap: 100px;

    @media(max-width: 800px){
        flex-direction: column;
        margin: 20px 10%;
        gap: 50px;
    }
`;
export const congratulationsIcon = styled(general.FontAwesomeIcon)`
    font-size: 250px;
    color: ${colors.priceGreen};

    @media(max-width: 500px){
        font-size: 200px;
    }
`;
export const congratulationsTextArea = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
export const congratulationsTitle = styled.h3`
    font-family: ${fonts.main};
    font-size: 20px;
    font-weight: 600;
    color: ${colors.black};
    text-transform: uppercase;
    text-align: justify;

    @media(max-width: 800px){
        text-align: center;
    }
`;
export const congratulationsText = styled.h3`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 400;
    color: ${colors.black};
    text-align: justify;
`;