import styled from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const paginationArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
`;
export const paginationButton = styled(FontAwesomeIcon)`
    background-color: ${colors.darkGrey};
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    margin: 0 10px;
    cursor: pointer;

    &:hover {
        background-color: ${colors.darkerGrey};
    }
`;
export const paginationInput = styled.input`
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    margin: 0 10px;
    border: 1px solid ${colors.darkGrey};
    width: 50px;
    text-align: center;
    outline: none;
`;
