import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';

const DefaultLoadingFit = (props: {active: boolean, borderRadius?: number, size?: number}) => {
    const controller = useController();

    return props.active && props.active == true ? (
            <S.loadingContainer borderRadius={props.borderRadius ?? 20}>
                <S.loadingDiv size={props.size ?? 35}/>
            </S.loadingContainer>
    ) : null;
}

export default DefaultLoadingFit;