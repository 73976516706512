import general from '../../../utils/general';
import { useState, useEffect } from 'react';

export const useController = () => {
    const [loading, setLoading] = useState(false);
    const [modalCurrencyConvertion, setModalCurrencyConvertion] = useState(false);
    const [modalCEP, setModalCEP] = useState(false);
    const [modalCNPJ, setModalCNPJ] = useState(false);
    const [modalFreight, setModalFreight] = useState(false);
    const [modalTaxes, setModalTaxes] = useState(false);
    const [modalBarcode, setModalBarcode] = useState(false);
    const [modalQRCode, setModalQRCode] = useState(false);
    const [modalISBN, setModalISBN] = useState(false);
    const [modalDDD, setModalDDD] = useState(false);
    const [modalNCM, setModalNCM] = useState(false);
    const [measurementsModal, setMeasurementsModal] = useState(false);

    return {
        general,
        loading,
        setLoading,
        modalCurrencyConvertion,
        setModalCurrencyConvertion,
        modalCEP,
        setModalCEP,
        modalCNPJ,
        setModalCNPJ,
        modalFreight,
        setModalFreight,
        modalTaxes,
        setModalTaxes,
        modalBarcode,
        setModalBarcode,
        modalQRCode,
        setModalQRCode,
        modalISBN,
        setModalISBN,
        modalDDD,
        setModalDDD,
        modalNCM,
        setModalNCM,
        measurementsModal,
        setMeasurementsModal
    }
}

export default useController;