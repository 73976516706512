import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../../utils/types';

const ActionCardAlt = (props: any) => {
    const controller = useController();

    return (
        <S.main>
            {/*  @ts-expect-error */}
            <S.icon icon={controller.general.icons[props.mainIcon ?? 'faAdd']} />
            <S.text>{props.children}</S.text>
        </S.main>
    );
}

export default ActionCardAlt;