import styled, {keyframes} from 'styled-components';
import {colors} from '../../../utils/colors';
import {fonts} from '../../../utils/fonts';
import general from '../../../utils/general';
import Avatar from 'react-avatar';

export const notificationTabAnimation = keyframes`
    0% {
        transform: scale(0.2);
        transform-origin: top right;
    }
    100% {
        transform: scale(1);
        transform-origin: top right;
    }
`;

export const bellshake = keyframes`
    0% { transform: rotate(0);}
    2% { transform: rotate(10deg); }
    4% { transform: rotate(-10deg); }
    6% { transform: rotate(8deg); }
    8% { transform: rotate(-8deg); }
    10% { transform: rotate(4deg); }
    12% { transform: rotate(-4deg); }
    14% { transform: rotate(2deg); }
    16% { transform: rotate(0);}
`;

export const slideIn = keyframes`
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0%);
    }
`;

export const main = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 70px;

    @media (max-width: 1200px) {
        padding: 0px 20px 0 20px;
    }
`;
export const pageTitle = styled.div`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;

    @media (max-width: 700px) {
        display: none;
    }
`;
export const optionsArea = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 300px;
    padding: 0 20px;
    justify-content: space-between;

    @media (max-width: 900px) {
        width: 340px;
    }

    @media (max-width: 700px) {
        width: 100%;
        padding: 0 2px;
        gap: 15px;
    }
`;  
export const searchBar = styled.div`
    background-color: ${colors.white};
    display: flex;
    flex-direction: row;
    padding: 4px;
    border-radius: 5px;
    align-items: center;
    border: 1px solid ${colors.grey};

    &:focus-within {
        transform: scale(1.05);
    }

    @media (max-width: 700px) {
        width: 100%;
    }
`;
export const searchBarInput = styled.input`
    width: 140px;
    margin: none;
    border: none;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 500;
    padding: 0 10px;
    background-color: transparent;

    &:focus {
        outline: none;
    }

    @media (max-width: 900px) {
        width: 180px;
    }

    @media (max-width: 700px) {
        width: 100%;
    }
`;
export const searchBarIcon = styled(general.FontAwesomeIcon)`
    font-size: 20px;
`;
export const notificationsIcon = styled(general.FontAwesomeIcon)<{hasnotifications: number}>`
    font-size: 25px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    color: ${props => props.hasnotifications ? colors.orange : colors.black};
    animation: ${props => props.hasnotifications ? bellshake : 'none'} 5s infinite;

    &:hover {
        transform: scale(1.1);
    }
`;
export const notificationTabHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${colors.grey};
    height: 40px;
`;
export const notificationTabTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
`;
export const notificationTabClear = styled.p`
    font-family: ${fonts.main};
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${colors.linkBlue};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;
export const notificationsTab = styled.div<{visibility: number, parentX: number, parentY: number}>`
    position: absolute;
    top: ${props => props.parentY + 45}px;
    left: ${props => props.parentX - 280}px;
    width: 300px;
    height: 70vh;
    background-color: ${colors.white};
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px ${colors.grey};
    padding: 10px;
    overflow-y: auto;
    z-index: 1000;
    animation: ${notificationTabAnimation};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;

    @media (max-width: 700px) {
        top: ${props => props.parentY + 50}px;
        left: ${props => props.parentX - 240}px;
    }

    @media (max-height: 600px) {
        height: 55vh;
    }
`;
export const notificationsTabEmpty = styled.div`
    display: flex;
    height: calc(100% - 55px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
export const zeroNotifications = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${colors.grey};
    text-align: center;
    padding: 10px 0;
`;
export const zeroNotificationsIcon = styled(general.FontAwesomeIcon)`
    font-size: 40px;
    color: ${colors.grey};
    margin-bottom: 10px;
`;

export const notification = styled.div<{index: number}>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.grey};
    cursor: pointer;
    transform: translateX(-100%);
    animation: ${slideIn};
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-delay: ${props => props.index * 0.15}s;

    &:hover {
        background-color: ${colors.lightGrey};
    }

    &:last-child {
        border-bottom: none;
    }
`;
export const notificationInfoArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 10px;
    height: 100%;
    width: 80%;
    overflow: hidden;
    gap: 10px;
`;
export const notificationTitle = styled.div`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
`;
export const notificationDescription = styled.div`
    font-family: ${fonts.main};
    font-size: 12px;
    font-weight: 500;
    color: ${colors.darkerGrey};
    text-align: justify;
`;
export const notificationDeleteIcon = styled(general.FontAwesomeIcon)`
    font-size: 18px;
    color: ${colors.grey};
    transition-duration: 0.1s;

    &:hover {
        color: ${colors.red};
    }
`;
export const notificationDeleteBtn = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    margin: 0 5px;
    border-radius: 3px;
    cursor: pointer;
    transition-duration: 0.1s;
    
    &:hover {
        
        ${notificationDeleteIcon} {
            color: ${colors.red};
        }
    }
`;

export const userAvatar = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: ${colors.backgroundWhite};
    cursor: pointer;
    object-fit: cover;

    @media (max-width: 500px) {
        width: 52.5px;
        border-radius: 100%;
    }
`;

export const AvatarComponent = styled(Avatar)`
    border-radius: 50%;
    background-color: ${colors.backgroundWhite};
    cursor: pointer;
`;