import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import { useController } from './controller';

export const main = styled.div`
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    z-index: 99999999999999999999999999999999999999999;
    justify-content: center;
    align-items: center;
    animation: ${keyframes`
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    `} 0.25s linear;
`;
export const loadingContainer = styled.div`
    width: 300px;
    height: 300px;
    background-color: rgba(0,0,0,0.60);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.60);
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    position: relative;

    @media(max-width: 1200px){
        width: 250px;
        height: 250px;
        margin: -60px 0 0 0;
    }

    @media(max-width: 768px){
        margin: -90px 0 0 0;
    }

    @media(max-width: 480px){
        width: 200px;
        height: 200px;
        margin: -80px 0 0 0;
    }
`;
export const imgBit = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;

    &.logo_arezdot_glow{
        top: -20px;
        animation: ${keyframes`
            0%{
                opacity: 0.2;
            }
            50%{
                opacity: 1;
            }
            100%{
                opacity: 0.2;
            }
        `} 3s linear infinite;
    }

    &.logo_arezdot_draw{
        top: -20px;
    }

    &.logo_arezdot_name{
        animation: ${keyframes`
        `} 3s linear infinite;
    }
`;