import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { card2Props } from '../../../../utils/types';

const CardComponent2 = (props: card2Props) => {
    const controller = useController();

    return (
        <S.main>
            <S.title>{props.title}</S.title>
            <S.description>{props.description && props.description.length > 0 ? props.description : "Descrição não informada"}</S.description>
            <S.infoArea>
                {props.info?.map((item, index) => {
                    return (
                        <S.infoBit key={index}>
                            <S.infoBitTitle>{item.title.toUpperCase()}</S.infoBitTitle>
                            <S.infoBitValue>{item.value.toString().toUpperCase()}</S.infoBitValue>
                        </S.infoBit>
                    )
                })}
            </S.infoArea>
        </S.main>
    );
}

export default CardComponent2;