import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Authorization': localStorage.getItem('token') || ''
  }
});

api.interceptors.request.use((config) => {
  const controller = new AbortController();
  
  config.headers.Authorization = localStorage.getItem('token') || null;
  config.params = {
    ...config.params,
    company: localStorage.getItem('company') || null
  }
  return {
    ...config,
    signal: controller.signal
  };
});

export default api;