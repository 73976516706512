import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemComponent from '../../../components/app/NewItemComponent';
import CardGoals from '../../../components/app/Cards/CardGoals';
import Pagination from '../../../components/general/Pagination';
import SelectWithSearch from '../../../components/app/Inputs/SelectWithSearch';
import ActionCardAlt from '../../../components/app/Cards/ActionCardAlt';

const CreateEditGoalModal = (controller: any) => {
    return (
        <S.modal>
            {controller.deleteGoalModalActive ? (
                <S.modalDeleteGoal>
                    <S.deleteConfirmationModal>
                        <S.deleteConfirmationModalTitle>Tem certeza que deseja excluir essa meta?</S.deleteConfirmationModalTitle>
                        <S.deleteConfirmationModalBtns>
                            <S.modalButton onClick={() => {
                                controller.deleteGoal();
                                controller.setDeleteGoalModalActive(false);
                                controller.setEditModalActive(false);
                                controller.setCreateModalActive(false);
                            }}>Sim</S.modalButton>
                            <S.modalButton onClick={() => controller.setDeleteGoalModalActive(false)}>Não</S.modalButton>
                        </S.deleteConfirmationModalBtns>
                    </S.deleteConfirmationModal>
                </S.modalDeleteGoal>
            ) : null}
            <S.modalContent onSubmit={(e) => {
                e.preventDefault()
                if (controller.modalType == 'create') {
                    controller.createGoal();
                } else {
                    controller.editGoal();
                }
            }}>
                <S.modalHeader>
                    <S.modalTitle>{controller.modalType == 'create' ? 'Criar modal' : 'Editar modal'}</S.modalTitle>
                    <S.modalHeaderIcons>
                        {controller.modalType == 'edit' ? <S.deleteGoal icon={controller.general.icons.faTrash} onClick={() => controller.setDeleteGoalModalActive(true)} /> : null}
                        <S.modalClose onClick={() => {
                            controller.setCreateModalActive(false)
                            controller.setEditModalActive(false)
                        }} icon={controller.general.icons.faTimes} />
                    </S.modalHeaderIcons>
                </S.modalHeader>
                <S.modalBody>
                    <S.modalInput>
                        <S.modalLabel>Título</S.modalLabel>
                        <S.modalInputText
                            value={controller.currentItem?.title}
                            onChange={(e) => controller.setCurrentItem({ ...controller.currentItem, title: e.target.value })}
                        />
                    </S.modalInput>
                    <S.modalInput>
                        <S.modalLabel>Para quem</S.modalLabel>
                        <SelectWithSearch
                            executeOnSelect={(option: any) => controller.setCurrentItem({ ...controller.currentItem, option: option })}
                            defaultValue={controller.currentItem?.option?.title}
                            options={controller.goalForOptions}
                        />
                    </S.modalInput>
                    {controller.modalType == 'edit' ? (
                        <S.modalInput>
                            <S.modalLabel>Valor atual</S.modalLabel>
                            <S.modalInputText
                                type='number'
                                value={controller.currentItem?.current ?? 0}
                                onChange={(e) => controller.setCurrentItem({ ...controller.currentItem, current: e.target.value })}
                            />
                        </S.modalInput>
                    ) : null}
                    <S.modalInput>
                        <S.modalLabel>Meta</S.modalLabel>
                        <S.modalInputText
                            type='number'
                            value={controller.currentItem?.goal ?? 1}
                            onChange={(e) => controller.setCurrentItem({ ...controller.currentItem, goal: e.target.value })}
                        />
                    </S.modalInput>
                    <S.modalInput>
                        <S.modalLabel>Completar até</S.modalLabel>
                        <S.modalInputText
                            type='date'
                            value={controller.currentItem?.complete_until?.toString()?.split('T')[0]}
                            onChange={(e) => controller.setCurrentItem({ ...controller.currentItem, complete_until: e.target.value })}
                        />
                    </S.modalInput>
                </S.modalBody>
                <S.modalFooter>
                    <S.modalButton>{
                        controller.modalType == 'create' ? 'Criar' : 'Editar'
                    }</S.modalButton>
                    {controller.modalType == 'edit' ? <S.modalButton onClick={(e) => {
                        e.preventDefault();
                        controller.completeGoal();
                    }}>Concluir meta</S.modalButton> : null}
                </S.modalFooter>
            </S.modalContent>
        </S.modal>
    );
}

const Goals = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Metas' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            {controller.createModalActive ? <CreateEditGoalModal {...controller} /> : null}
            {controller.editModalActive ? <CreateEditGoalModal {...controller} /> : null}
            <controller.general.DefaultLoading active={controller.loading} />
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Metas' />
                {!controller.general.getCompany().plan.planModules.includes("module_goals") ? <controller.general.NoPermissionComponent permissionDeniedMessage={"O Seu plano não possui acesso a essa funcionalidade, por favor solicite um upgrade"} /> :
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <S.operationsArea>
                            <div className='content-cards-wrapper' onClick={() => {
                                controller.setCurrentItem({
                                    goal: 0,
                                })
                                controller.setModalType('create')
                                controller.setCreateModalActive(prev => !prev)
                            }}>
                                <NewItemComponent mainIcon='faBarsProgress' pageItem='Meta' />
                            </div>
                            <div className='content-cards-wrapper' onClick={() => {
                                controller.general.GoToRoute('/app/goals/completed')
                            }}>
                                <ActionCardAlt mainIcon='faCircleCheck'>
                                    Metas <br /> concluidas
                                </ActionCardAlt>
                            </div>
                            {(controller.goals ?? []).map((item: any, index: number) => {
                                return (
                                    <div className='content-cards-wrapper' key={`team_${index}`} onClick={() => {
                                        controller.setCurrentItem(item)
                                        controller.setModalType('edit')
                                    }}>
                                        <CardGoals
                                            key={index}
                                            title={item.title}
                                            current={item.current}
                                            goal={item.goal}
                                            openModal={controller.setEditModalActive}
                                            complete_until={item.complete_until}
                                            increase_goal={() => controller.increaseGoal(item)}
                                            decrease_goal={() => controller.decreaseGoal(item)}
                                        />
                                    </div>
                                );
                            })
                            }
                        </S.operationsArea>
                    )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    )
}

export default Goals;