import general from '../../../utils/general';
import { useState, useEffect } from 'react';

export const useController = () => {
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [forms, setForms] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);

    const getForms = async (showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            const response = await general.api.post('form/list', {
                page: page
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setForms(response.data.data.data);
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar formulários', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        getForms(true);
    }, [page]);
    
    useEffect(() => {
        getForms(true);
    }, []);

    useEffect(() => {
        getForms(false);
    }, [createModalActive]);

    return {
        general,
        createModalActive,
        setCreateModalActive,
        currentItem,
        setCurrentItem,
        forms,
        page,
        setPage,
        totalPages,
        loading,
        permissionBlock
    }
}

export default useController;