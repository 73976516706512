import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import { PieChart } from '@mui/x-charts/PieChart';
import { BarChart } from '@mui/x-charts/BarChart';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;
`;
export const mainArea = styled.div`
    display: grid;
    width: 100%;
    height: calc(100% - 110px);
    font-family: ${fonts.main};
    overflow-y: auto;
    padding: 0 0 2% 0;

    @media(max-width: 1200px) {
        height: calc(100% - 160px);
    }
`;
export const noItems = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    @media(max-width: 900px) {
        padding: 10% 0;
    }
`;
export const noItemsIcon = styled(FontAwesomeIcon)`
    font-size: 3rem;
    color: ${colors.darkeredGrey};
`;
export const noItemsTitle = styled.h2`
    font-size: 1.5rem;
    color: ${colors.darkeredGrey};
    margin-top: 1rem;
    text-align: center;
`;
export const blockedItems = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    @media(max-width: 900px) {
        padding: 10% 0;
    }
`;
export const blockedItemsIcon = styled(FontAwesomeIcon)`
    font-size: 3rem;
    color: ${colors.darkeredGrey};
`;
export const blockedItemsTitle = styled.h2`
    font-size: 1.5rem;
    color: ${colors.darkeredGrey};
    margin-top: 1rem;
    text-align: center;
`;
export const infoBits = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 98%;
    justify-self: center;
    align-self: center;
    gap: 2%;
    margin: 2% 10%;    
    justify-content: space-between;

    @media(max-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media(max-width: 900px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;
export const infoBit = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 10%;
    background-color: ${colors.black};
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    position: relative;
`;
export const infoBitTitle = styled.h2`
    font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    color: ${colors.white};

    @media(max-width: 1366px) {
        font-size: .9rem;
    }

    @media(max-width: 500px) {
        font-size: .8rem;
    }
`;
export const infoBitSection = styled.div`
    display: flex;
    width: 100%;
    margin-top: 1rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;
export const infoBitSectionTitle = styled.h3`
    font-size: .9rem;
    font-weight: 600;
    color: ${colors.white};

    @media(max-width: 1366px) {
        font-size: .8rem;
    }

    @media(max-width: 500px) {
        font-size: .7rem;
    }
`;
export const infoBitSectionContent = styled.p`
    font-size: .9rem;
    color: ${colors.white};

    @media(max-width: 1366px) {
        font-size: .8rem;
    }

    @media(max-width: 500px) {
        font-size: .7rem;
    }
`;
export const middleInfoSections = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 98%;
    margin: 2rem 1% 0 1%;

    @media(max-width: 1100px) {
        grid-template-columns: 1fr;
    }
`;
export const middleSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 94%;
    padding: 2% 2%;
    align-self: center;
    justify-self: center;
    background-color: ${colors.black};
    border-radius: 10px;
    height: 400px;
    position: relative;

    @media(max-width: 1200px) {
        width: 94%;
    }

    @media(max-width: 900px) {
        padding: 4% 3%;
        height: auto;
        max-height: 400px;
    }

    @media(max-width: 500px) {
        max-height: 300px;
    }
`;
export const middleSectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: ${colors.white};
    text-align: center;
`;
export const middleSectionContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    overflow-y: auto;
    margin-top: 1rem;
    padding: 0 1%;
`;
export const middleSectionPart = styled.div`
    display: flex;
    width: 100%;
    margin-top: 1rem;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.darkeredGrey};
    justify-content: space-between;
    align-items: center;

    &:last-child {
        border-bottom: none;
    }
`;
export const middleSectionPartTitle = styled.h3`
    font-size: 1.2rem;
    font-weight: 400;
    color: ${colors.white};
    width: 75%;

    @media(max-width: 1366px){
        width: 65%;
    }

    @media(max-width: 500px){
        width: 52%;
        font-size: 1rem;
    }
`;
export const middleSectionPartContent = styled.p`
    font-size: 1rem;
    color: ${colors.white};

    @media(max-width: 500px){
        font-size: .9rem;
    }
`;
export const annualBillingByMonth = styled.div`
    display: flex;
    flex-direction: column;
    width: 98%;
    justify-content: center;
    align-items: center;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 10px;
    align-self: center;
    justify-self: center;
    height: 400px;
    padding: 2% 0%;
    position: relative;

    @media(max-width: 1200px) {
        overflow-x: auto;
    }

    @media(max-width: 900px) {
        height: auto;
    }
`;
export const barChart = styled(BarChart)``;
export const bigInfoSections = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    width: 98%;
    margin: 2rem 1% 0 1%;

    @media(max-width: 1100px) {
        grid-template-columns: 1fr;
    }
`;
export const bigSectionTitle = styled.h2`
    font-size: 1.5rem;
    font-weight: 600;
    color: ${colors.white};
    text-align: center;

    @media(max-width: 900px) {
        margin-top: 1rem;
    }
`;
export const profitsChartWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 94%;
    justify-content: center;
    align-items: center;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 10px;
    align-self: center;
    justify-self: center;
    height: 90%;
    padding: 2% 2%;

    @media(max-width: 900px) {
        height: auto;
        flex-direction: column;
    }
`;
export const profitsDivision = styled.div`
    display: flex;
    flex-direction: column;
    width: 94%;
    justify-content: center;
    align-items: center;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 10px;
    align-self: center;
    justify-self: center;
    height: 400px;
    padding: 2% 2%;
    position: relative;

    @media(max-width: 900px) {
        height: auto;
    }
`;
export const pieChart = styled(PieChart)``;
export const profitsPieChartItems = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    width: 100%;
    max-height: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    @media(max-width: 1366px) {
        width: 55%;
    }

    @media(max-width: 1200px) {
        width: 70%;
    }

    @media(max-width: 900px) {
        width: 100%;
        max-height: 300px;
    }

    @media(max-width: 500px) {
        width: 100%;
    }
`;
export const profitsPieChartUniqueItem = styled.div`
    display: flex;
    gap: 1rem;
    align-items: center;
`;
export const profitsPieChartItemColor = styled.div<{color: string}>`
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    background-color: ${props => props.color};
    border-radius: 20px;
    border: 1px solid ${colors.white};
`;
export const profitsPieChartItemContent = styled.p`
    font-size: 1rem;
    color: ${colors.white};
`;

export const collaboratorsOfTheRanking = styled.div`
    height: 100%;
    width: 100%;
    overflow-y: auto;
`;
export const collaboratorsRanking = styled.div`
    display: flex;
    flex-direction: column;
    width: 94%;
    align-items: center;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 10px;
    align-self: center;
    justify-self: center;
    padding: 2% 2%;
    height: 400px;
    position: relative;

    @media(max-width: 1200px) {
        overflow-x: auto;
    }

    > h2 {
        margin-bottom: 1rem;
    }
`;
export const collaboratorsRankingContent = styled.div<{isTitle?: boolean}>`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr;
    width: 100%;
    gap: 5%;
    padding: 1% 0;
    margin-bottom: 1rem;
    border-bottom: ${props => props.isTitle ? '1px solid ' + colors.darkeredGrey : 'none'};
`;
export const collaboratorsRankingSection = styled.p`
    font-size: 1rem;
    font-weight: 400;
    color: ${colors.white};
    width: 100%;
    place-self: center;
    text-align: center;

    @media(max-width: 1200px) {
        font-size: .8rem;
    }

    @media(max-width: 500px) {
        font-size: .65rem;
    }
`;
export const collaboratorsRankingSectionAvatar = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    place-self: center;
    cursor: pointer;
`;