import styled, { keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import { Toaster } from 'sonner';

export const main = styled.div<{ detailedMenu?: number }>`
    width: 15%;
    height: 100%;
    background-color: ${colors.black};
    overflow-y: auto;
    text-align: center;
    align-items: center;
    transition-duration: 0.3s;
    z-index: 888;

    @media (max-width: 1600px) {
        width: 20%;
    }
    @media (max-width: 1120px) {
        width: 25%;
    }
    @media (max-width: 900px) {
        position: ${props => props.detailedMenu ? 'absolute' : 'relative'};
        width: 100%;
    }

    ${props => !props.detailedMenu ? 'width: 65px !important;' : ''}
`;
export const menuItem = styled.a<{ detailedMenu?: number }>`
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 12px 20px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: ${colors.darkeredGrey};
        margin: 0;
    }

    &:nth-child(1) {
        justify-content: space-between;
        ${props => props.detailedMenu ? 'border-bottom: 1px solid ' + colors.white + ';' : ''}
    }

    &:nth-last-child(1) {
        margin-bottom: 80px;
    }

    @media (max-width: 1200px) {
        padding: 12px 10px;
        gap: 15px;
    }
`;
export const menuItemText = styled.div<{ detailedMenu?: number }>`
    color: ${colors.white};
    font-weight: 600;
    font-size: 0.8rem;
    display: ${props => props.detailedMenu ? "flex" : "none"};
    white-space: nowrap;

    &:nth-child(1) {
        font-size: 1.2rem;
    }
`;
export const icon = styled(general.FontAwesomeIcon)`
    color: ${colors.white};
    font-size: 1.2rem;
    width: 30px;
`;

/* AI Modal */
export const IAModalBackground = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 99999999999;
`;
const modalAnimation = keyframes`
    from {
        opacity: 0;
        trandform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;
export const IAModal = styled.div<{ ref?: any }>`
    width: 800px;
    background-color: ${colors.black};
    border-radius: 10px;
    z-index: 888;
    animation: ${modalAnimation} 0.5s;
    box-shadow: 0 0 10px ${colors.white};
    max-height: 100%;

    @media (max-width: 900px) {
        width: 600px;
        transform: translateY(30px);
    }

    @media (max-width: 500px) {
        width: 380px;
        transform: translateY(-30px);
    }
`;
export const IAModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid ${colors.white};
`;
export const IAModalTitle = styled.div`
    color: ${colors.white};
    font-size: 1.5rem;
    font-weight: 600;
`;
export const IAModalButton = styled.div`
    cursor: pointer;
`;
export const AIAllowed = styled.form`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    min-height: 150px;
    position: relative;
`;
export const AIAnswer = styled.div`
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 10px;
    padding: 5px;
    max-height: 400px;
    overflow-y: auto;

    @media (max-width: 1200px) {
        @media (max-height: 800px) {
            max-height: 300px;
        }
    }
`;
export const AIButtonsAnswers = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`;
export const AIButtonAnswer = styled.div`
    cursor: pointer;
    color: ${colors.white};
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    border: 1px solid ${colors.white};
    padding: 10px;
    transition-duration: 0.3s;
    text-align: center;

    :nth-child(1) {
        border-right: none;
        border-radius: 5px 0 0 5px;
    }

    :nth-child(2) {
        border-radius: 0 5px 5px 0;
    }

    &:hover {
        background-color: ${colors.white};
        color: ${colors.black};
    }
`;
export const AIQuestion = styled.div`
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 600;
`;
export const AIInput = styled.input`
    padding: 10px;
    border: none;
    border-bottom: 1px solid ${colors.grey};
    outline: none;
    background-color: transparent;
    color: ${colors.white};
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px;
`;
export const AIButton = styled.div`
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    color: ${colors.white};
    font-size: 1.1rem;
    font-weight: 600;
    border: 1px solid ${colors.white};
    border-radius: 5px;
    text-align: center;
    transition-duration: 0.3s;

    &:hover {
        background-color: ${colors.white};
        color: ${colors.black};
    }
`;
export const AINotAllowedText = styled.div`
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 600;
    padding: 20px;
    text-align: center;
    margin: 10px 20px;
`;

export const Toast = styled(Toaster)`
    @media print {
        display: none;
    }
`;
export const supportModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 99999999999;
`;
export const supportModalContent = styled.div`
    width: 400px;
    background-color: ${colors.black};
    border-radius: 10px;
    box-shadow: 0 0 10px ${colors.white};
    animation: ${modalAnimation} 0.5s;
    padding-bottom: 20px;
`;
export const modalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid ${colors.white};
`;
export const modalTitle = styled.div`
    color: ${colors.white};
    font-size: 1.5rem;
    font-weight: 600;
`;
export const modalClose = styled.div`
    cursor: pointer;
`;
export const closeIcon = styled(general.FontAwesomeIcon)`
    color: ${colors.white};
    font-size: 1.2rem;
`;
export const modalBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
`;
export const supportText = styled.div`
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
`;
export const supportIcon = styled(general.FontAwesomeIcon)<{size?: string}>`
    color: ${colors.white};
    font-size: ${props => props.size ? props.size : '1.2rem'};
    transition-duration: 0.1s;
    padding-top: 3px;
`;
export const supportContactText = styled.div`
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 600;
    transition-duration: 0.3s;
    text-align: center;
    cursor: pointer;
`;
export const supportContact = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    cursor: pointer;
    transition-duration: 0.3s;
    border: 1px solid ${colors.white};
    padding: 10px;
    width: 80%;
    border-radius: 5px;
    text-align: center;
    text-decoration: none;

    &:hover {
        background-color: ${colors.white};
        color: ${colors.black};

        ${supportIcon} {
            color: ${colors.black};
        }

        ${supportContactText} {
            color: ${colors.black};
    }
`;