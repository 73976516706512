import React, { useState, useEffect } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import EditCompanyModal from '../EditCompanyModal';
import DefaultLoadingFit from '../DefaultLoadingFit';

const SupportModal = (controller: any) => {
    return (
        <S.supportModal>
            <S.supportModalContent>
                <S.modalHeader>
                    <S.modalTitle>{'Suporte'.toLocaleUpperCase()}</S.modalTitle>
                    <S.modalClose onClick={() => controller.setSupportModalOpen(false)}>
                        <S.closeIcon icon={controller.general.icons.faTimes} />
                    </S.modalClose>
                </S.modalHeader>
                <S.modalBody>
                    <S.supportText>{'Caso tenha alguma dúvida ou problema, entre em contato:'.toLocaleUpperCase()}</S.supportText>
                    <S.supportContact href='mailto:suporte@arezdot.com' target='_blank'>
                        <S.supportIcon icon={controller.general.icons.faEnvelope} />
                        <S.supportContactText>{'suporte@arezdot.com'}</S.supportContactText>
                    </S.supportContact>
                    <S.supportContact href='https://wa.me/5535998811741?text=Olá, preciso de suporte com o Arezdot ERP!' target='_blank'>
                        {/* @ts-ignore */}
                        <S.supportIcon size={'1.5rem'} icon={controller.general.brandIcons.faWhatsapp} />
                        <S.supportContactText>{'+55 35 99881-1741'}</S.supportContactText>
                    </S.supportContact>
                </S.modalBody>
            </S.supportModalContent>
        </S.supportModal>
    )
}

const MenuComponent = (props: defaultProps) => {
    const controller = useController();

    return (
        <>
            {controller.supportModalOpen && <SupportModal {...controller} />}
            {controller.AIModalOpen && (
                <S.IAModalBackground>
                    <S.IAModal ref={controller.loadingAIAnswer ? controller.glitch.ref : null}>
                        <S.IAModalHeader>
                            <S.IAModalTitle>{'Inteligência artificial'.toLocaleUpperCase()}</S.IAModalTitle>
                            <S.IAModalButton onClick={() => controller.setAIModalOpen(false)}>
                                <S.icon icon={controller.general.icons.faTimes} />
                            </S.IAModalButton>
                        </S.IAModalHeader>
                        {controller.AIAllowed ? (
                            <S.AIAllowed onSubmit={(e) => {
                                e.preventDefault()
                                controller.AIRequest()
                            }}>
                                {controller.AIAnswer != '' ? (
                                    <>
                                        <S.AIAnswer dangerouslySetInnerHTML={{ __html: controller.AIAnswer }} />
                                        <S.AIButtonsAnswers>
                                            <S.AIButtonAnswer onClick={() => {
                                                controller.setAIAnswer('')
                                                controller.setPrompt('')
                                            }}>{'Novo prompt'.toLocaleUpperCase()}</S.AIButtonAnswer>
                                            <S.AIButtonAnswer onClick={() => {
                                                controller.general.copyToClipboard(controller.AIAnswer.replace(/<br>/g, '\n'))
                                                controller.general.notify('Resposta copiada para a área de transferência', 'success')
                                            }}>{'Copiar resposta'.toLocaleUpperCase()}</S.AIButtonAnswer>
                                        </S.AIButtonsAnswers>
                                    </>
                                ) : (controller.loadingAIAnswer ? (
                                    <DefaultLoadingFit active={controller.loadingAIAnswer} />
                                ) : (
                                    <>
                                        <S.AIQuestion>Faça uma pergunta ou solicite algo:</S.AIQuestion>
                                        <S.AIInput autoComplete='off' id='AIInput' onClick={() => { document.getElementById('AIInput')?.focus() }} value={controller.prompt} onChange={controller.changePrompt} />
                                        <S.AIButton onClick={() => { controller.AIRequest() }}>{'Enviar'.toLocaleUpperCase()}</S.AIButton>
                                    </>
                                ))}
                            </S.AIAllowed>
                        ) : (
                            <S.AINotAllowedText>{'Inteligência artificial não disponível no plano atual, para acessá-la faça um upgrade para o plano intermedário ou superior'.toLocaleUpperCase()}</S.AINotAllowedText>
                        )}
                    </S.IAModal >
                </S.IAModalBackground>
            )}
            <EditCompanyModal modalActive={controller.editCompanyModal} setModalActive={controller.setEditCompanyModal} />
            <S.Toast richColors className='toast-notifications' />
            <S.main detailedMenu={controller.detailedMenu}>
                <S.menuItem title='Menu' detailedMenu={controller.detailedMenu} onClick={() => controller.setDetailedMenu(controller.detailedMenu == 0 ? 1 : 0)}>
                    <S.menuItemText detailedMenu={controller.detailedMenu}>{'arezdot erp'.toLocaleUpperCase()}</S.menuItemText>
                    <S.icon icon={controller.detailedMenu ? controller.general.icons.faChevronLeft : controller.general.icons.faBars} />
                </S.menuItem>
                {controller.AIAllowed && (
                    <S.menuItem title='Inteligência artificial' detailedMenu={controller.detailedMenu} onClick={() => controller.setAIModalOpen(true)}>
                        <S.icon icon={controller.general.icons.faRobot} />
                        <S.menuItemText detailedMenu={controller.detailedMenu}>{'Inteligência artificial'.toLocaleUpperCase()}</S.menuItemText>
                    </S.menuItem>
                )}
                {controller.menuPreset[props.menuPreset ?? 0].map((item, index) => {
                    return (
                        <S.menuItem href={item.route} title={item.title} key={index} onClick={() => controller.general.GoToRoute(item.route)} detailedMenu={controller.detailedMenu}>
                            {/*  @ts-expect-error */}
                            <S.icon icon={controller.general.icons[item.icon]} />
                            <S.menuItemText detailedMenu={controller.detailedMenu}>{item.title.toLocaleUpperCase()}</S.menuItemText>
                        </S.menuItem>
                    )
                })}
                <S.menuItem title={'Sair'} onClick={() => {
                    controller.setSupportModalOpen(true)
                }} detailedMenu={controller.detailedMenu}>
                    <S.icon icon={controller.general.icons['faHeadset']} />
                    <S.menuItemText detailedMenu={controller.detailedMenu}>{'Suporte'.toLocaleUpperCase()}</S.menuItemText>
                </S.menuItem>
                <S.menuItem title={'Sair'} onClick={() => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('company');
                    localStorage.removeItem('account');
                    localStorage.removeItem('companyObject');
                    controller.general.GoToRoute(props.menuPreset == 2 ? '/backoffice' : '/login')
                }} detailedMenu={controller.detailedMenu}>
                    <S.icon icon={controller.general.icons['faRightFromBracket']} />
                    <S.menuItemText detailedMenu={controller.detailedMenu}>{'Sair'.toLocaleUpperCase()}</S.menuItemText>
                </S.menuItem>
            </S.main>
        </>
    );
}

export default MenuComponent;