import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { addCompanyModalProps } from '../../../utils/types';
import { plan } from './types'
import axios from 'axios';

export const useController = (props: addCompanyModalProps) => {
    const [modalActive, setModalActive] = useState(false);
    const [step, setStep] = useState(0);
    const [currentLogo, setCurrentLogo] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [companyInfo, setCompanyInfo] = useState<any>({
        _id: general.getCompany()._id,
        address: {
            zip_code: "",
            street: "",
            number: "",
            complement: "",
            neighborhood: "",
            city: "",
            state: "",
            country: ""
        },
    });

    const resetPage = () => {
        setStep(0);
        props.setModalActive(false);
    }

    const fieldFormat = (field: string, value: any) => {
        switch (field) {
            case 'address.zip_code': {
                if (value.length >= 10) {
                    axios.get(`https://brasilapi.com.br/api/cep/v1/${value.replace('-', '').replace('.', '')}`).then((response) => {
                        if (response.status === 200) {
                            setCompanyInfo((prev: any) => ({ ...prev, address: { ...prev.address, street: response?.data?.street ?? '', neighborhood: response?.data?.neighborhood ?? '', city: response?.data?.city, state: response?.data?.state ?? '' } }));
                        }
                    }).catch((err) => { 
                        general.notify('CEP inválido', 'error');
                    });
                }
                return general.customMask(value, '##.###-###', "#", true);
            }
            default:
                return value;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        setCompanyInfo((prev: any) => ({ ...prev, [name]: fieldFormat(name, value) }));
    }

    const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        let originalName = name;
        const child = name.includes('.') ? name.split('.')[1] : '';
        if (child !== '') {
            name = name.split('.')[0];
            setCompanyInfo((prev: any) => ({ ...prev, [name]: { ...prev.address, [child]: fieldFormat(originalName, value) } }));
        }
        else {
            setCompanyInfo((prev: any) => ({ ...prev, [name]: fieldFormat(name, value) }));
        }
    }

    const getStoredCompany = () => {
        const company = general.getCompany();
        if (company) {
            setCompanyInfo({
                ...companyInfo,
                address: {
                    zip_code: general.customMask(company?.address?.zip_code, '##.###-###') ?? '',
                    street: company?.address?.street ?? '',
                    number: company?.address?.number ?? '',
                    complement: company?.address?.complement ?? '',
                    neighborhood: company?.address?.neighborhood ?? '',
                    city: company?.address?.city ?? '',
                    state: company?.address?.state ?? '',
                    country: company?.address?.country ?? ''
                }
            });
        }
    }

    const validateFields = () => {
        if(companyInfo.address.zip_code.length < 8) {
            general.notify('CEP inválido', 'error');
            return false;
        }
        if(companyInfo.address.street.length < 3) {
            general.notify('Rua inválida', 'error');
            return false;
        }
        if(companyInfo.address.number.length < 1) {
            general.notify('Número inválido', 'error');
            return false;
        }
        if(companyInfo.address.neighborhood.length < 3) {
            general.notify('Bairro inválido', 'error');
            return false;
        }
        if(companyInfo.address.city.length < 3) {
            general.notify('Cidade inválida', 'error');
            return false;
        }
        if(companyInfo.address.state.length < 2) {
            general.notify('Estado inválido', 'error');
            return false;
        }
        if(companyInfo.address.country.length < 3) {
            general.notify('País inválido', 'error');
            return false;
        }
        return true;
    }

    const updateInfo = async () => {
        if(!validateFields()) return;
        setLoading(true);
        await general.api.put(`/company/update/${general.getCompany()._id}`, {
            ...companyInfo,
            address: {
                ...companyInfo.address,
                zip_code: companyInfo.address.zip_code.replace(/\D/g, '')
            }
        }).then((response: any) => {
            if (response.data.status == 403) {
                props.setModalActive(false);
                setLoading(false);
                return general.notify(response.data.message, 'error')
            };
            general.notify(response.data.message, 'success');
            localStorage.setItem('companyObject', JSON.stringify(response.data.data));
            getStoredCompany();
            resetPage();
            setLoading(false);
        }).catch((err) => {
            general.notify(err.response.data.message, 'error');
            setLoading(false);
        });
    }

    useEffect(() => {
        getStoredCompany();
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
        }
    }, [props.modalActive]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })
    }, []);

    return {
        general,
        modalActive,
        step,
        setStep,
        companyInfo,
        setCompanyInfo,
        handleInputChange,
        updateInfo,
        currentLogo,
        loading,
        handleAddressChange,
    };
}

export default useController;