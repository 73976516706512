import general from '../../../utils/general';
import { useState, useRef, useEffect } from 'react';
export const useController = () => {
    const [notifPosition, setNotifPosition] = useState({ x: 0, y: 0 })
    const [notificationsVisibility, setNotificationsVisibility] = useState<boolean>(false)
    const notificationRef = useRef<SVGSVGElement>(null)
    const [notifications, setNotifications] = useState<any[]>([])
    const [account, setAccount] = useState<any>(null)
    const [showEditAccountModal, setShowEditAccountModal] = useState<boolean>(false)

    const getElementPosition = () => {
        if (notificationRef.current) {
            const { x, y } = notificationRef.current.getBoundingClientRect()
            setNotifPosition({ x, y })
        }
    }

    const getNotifications = async () => {
        try{
            const response = await general.api.get('/notification/list')
            if (response.status === 200) {
                setNotifications(response.data.data)
            }
        }
        catch(err){
            console.log(err)
        }
    }

    const removeNotification = async (id: number) => {
        try {
            await general.api.delete(`/notification/delete/${id}`).then(() => {
                getNotifications()
            })
            
        }
        catch (err) {
            console.log(err)
        }
    }
    const removeAllNotifications = () => {
        try {
            notifications.forEach(async (item) => {
                removeNotification(item._id)
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const getStoredAccount = () => {
        const account = localStorage.getItem('account')
        if (account) {
            setAccount(JSON.parse(account))
        }
    }

    useEffect(() => {
        getNotifications()
        getStoredAccount()
    }, [])

    return {
        general,
        notifPosition,
        getElementPosition,
        notificationsVisibility,
        setNotificationsVisibility,
        notificationRef,
        notifications,
        removeNotification,
        removeAllNotifications,
        account,
        showEditAccountModal,
        setShowEditAccountModal
    }
}

export default useController;