import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';

const CardTeams = (props: {
    title: string,
    members: {
        collaborator_id: string,
        collaborator_name: string,
        collaborator_role: string,
    }[]
}) => {
    const controller = useController();

    return (
        <S.main>
            <S.cardData>
                <S.cardTitles>
                    <S.cardTitle>{props.title ?? 'Não informado'}</S.cardTitle>
                </S.cardTitles>
                <S.cardTeam>
                    {(props.members ?? []).map((member, index) => {
                        return (
                            <S.member key={`team_member_${index}`}>
                                <S.memberAvatar src={`${process.env.REACT_APP_BASE_URL}app/images/defaultUser.png`} />
                                <S.memberInfo>
                                    <S.memberName>{member.collaborator_name}</S.memberName>
                                    <S.memberRole>{member.collaborator_role}</S.memberRole>
                                </S.memberInfo>
                            </S.member>
                        );
                    })}
                </S.cardTeam>
            </S.cardData>
        </S.main>
    );
}

export default CardTeams;