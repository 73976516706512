import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { addCompanyModalProps } from '../../../utils/types';
import { plan } from './types'
import moment from 'moment';
import axios from 'axios';

export const useController = (props: addCompanyModalProps) => {
    const [modalActive, setModalActive] = useState(false);
    const [step, setStep] = useState(0);
    const [currentLogo, setCurrentLogo] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [remoteIp, setRemoteIp] = useState<string>("");
    const [companyInfo, setCompanyInfo] = useState<any>({
        _id: JSON.parse(localStorage.getItem('account') as string)._id,
        cc: {
            number: "",
            name: "",
            expiration: "",
            cvc: "",
            focus: ""
        }
    });

    const resetPage = () => {
        setStep(0);
        props.setModalActive(false);
    }

    const fieldFormat = (field: string, value: any) => {
        switch (field) {
            case 'cc.number':
                return general.customMask(value, '#### #### #### ####', '#', true);
            case 'cc.expiration':
                return general.customMask(value, '##/##', '#', true);
            case 'cc.cvc':
                return general.customMask(value, '###', '#', true);
            default:
                return value;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        setCompanyInfo((prev: any) => ({ ...prev, [name]: fieldFormat(name, value) }));
    }

    const handleCardChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        let originalName = name;
        const child = name.includes('.') ? name.split('.')[1] : '';
        if (child !== '') {
            name = name.split('.')[0];
            setCompanyInfo((prev: any) => ({ ...prev, [name]: { ...prev.cc, [child]: fieldFormat(originalName, value) } }));
        }
        else {
            setCompanyInfo((prev: any) => ({ ...prev, [name]: fieldFormat(name, value) }));
        }
    }

    const fieldsValidation = () => {
        if (
            companyInfo.cc.number === "" || 
            companyInfo.cc.name === "" || 
            companyInfo.cc.expiration === "" || 
            companyInfo.cc.cvc === "" || 
            general.validateCustomMask(companyInfo.cc.number, '#### #### #### ####') === false || 
            general.validateCustomMask(companyInfo.cc.expiration, '##/##') === false || 
            general.validateCustomMask(companyInfo.cc.cvc, '###') === false || 
            parseInt(companyInfo.cc.expiration.split('/')[0]) > 12 || 
            parseInt(companyInfo.cc.expiration.split('/')[1]) < parseInt(moment().year().toString().slice(2)) || 
            (parseInt(companyInfo.cc.expiration.split('/')[0]) < parseInt(moment().month().toString()) && parseInt(companyInfo.cc.expiration.split('/')[1]) == parseInt(moment().year().toString().slice(2))) ||
            companyInfo.cc.cvc.length < 3 
        ){
            return false;
        }
        return true;
    }

    const updateInfo = async () => {
        if (!fieldsValidation()) return general.notify('Preencha todos os campos corretamente', 'error');
        setLoading(true);
        await general.api.put(`/company/tokenizecard/${general.getCompany()._id}`, {
            cc: {
                number: companyInfo.cc.number.replace(/\D/g, ''),
                name: companyInfo.cc.name,
                expiration: companyInfo.cc.expiration.replace(/\D/g, ''),
                cvc: companyInfo.cc.cvc.replace(/\D/g, '')
            },
            remoteIp
        }).then((response: any) => {
            if(response.data.status == 403) {
                props.setModalActive(false);
                setLoading(false);
                return general.notify(response.data.message, 'error')
            };
            if(response.data.status == 500){
                props.setModalActive(false);
                setLoading(false);
                return general.notify(response.data.message, 'error');
            }
            general.notify(response.data.message, 'success');
            resetPage();
            setLoading(false);
        }).catch((err) => {
            general.notify(err.response.data.message, 'error');
            setLoading(false);
        });
    }

    useEffect(() => {
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
        }
    }, [props.modalActive]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })

        axios.get('https://api.db-ip.com/v2/free/self').then((response) => {
            if (response.status === 200) {
                setRemoteIp(response.data.ipAddress);
            }
        }).catch((err) => { 
            console.log(err);
        });
    }, []);

    return {
        general,
        modalActive,
        step,
        setStep,
        companyInfo,
        setCompanyInfo,
        handleCardChange,
        updateInfo,
        currentLogo,
        loading
    };
}

export default useController;