import general from '../../../../utils/general';
import { useState } from 'react';

export const useController = (props: any) => {
    const handleNameChange = (e: any, id: string) => {
        document.getElementById(id)!.style.width = (e.target.value.length > 0 ? (e.target.value.length + 2) : 'Digite um nome para o campo'.length) + 'ch';
    }

    const handleChange = (e: any) => {
        if (props.field.type == 'number') {
            e.target.value = e.target.value.replace(/\D/g, '');
        }
        if (props.field.type == 'money') {
            e.target.value = e.target.value.replace(/\D/g, '');
            e.target.value = general.formatMoney(e.target.value);
        }
        if (props.field.mask) {
            e.target.value = general.customMask(e.target.value, props.field.mask);
        }
    }

    const removeItem = () => {
        props.item.infos = props.item.infos?.filter((item: any) => item.key != props.field.key);
        props.setItem({ ...props.item });
    }

    return {
        general,
        handleNameChange,
        handleChange,
        removeItem
    };
}

export default useController;