import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';

const StoreEdit = (props: defaultProps) => {
    const controller = useController();

    return <h1 style={{color: "white"}}>Store Edit</h1>;
}

export default StoreEdit;