import React, { useState } from 'react';
import { useController } from './controller';
import { addCompanyModalProps } from '../../../utils/types';
import * as S from './styles';
import { plan } from './types';

const EditCompanyAddress = (props: any) => {
    const controller = useController(props);

    return (
        <S.main active={controller.modalActive} animationOpacity={props.modalActive} onClick={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal step={controller.step} onClick={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                <S.header>
                    <S.title>Editar endereço</S.title>
                    <S.close onClick={() => {
                        controller.setStep(0);
                        props.setModalActive((active: boolean) => !active)
                    }}>X</S.close>
                </S.header>
                <S.body>
                    <S.stepContainer>
                        {controller.step == 0 ?
                            <S.step active={controller.step == 0} mobileMod={true}>
                                <S.inputsArea>
                                <S.inputContainer>
                                        <S.inputLabel>CEP:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.zip_code' value={controller.companyInfo.address.zip_code}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Estado:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.state' value={controller.companyInfo.address.state}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Cidade:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.city' value={controller.companyInfo.address.city}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Bairro:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.neighborhood' value={controller.companyInfo.address.neighborhood}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Rua:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.street' value={controller.companyInfo.address.street}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Número:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.number' value={controller.companyInfo.address.number}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Complemento:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.complement' value={controller.companyInfo.address.complement}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}
                    </S.stepContainer>
                    <S.saveBtn onClick={() => controller.updateInfo()}>
                        <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={15}/>
                        Salvar
                    </S.saveBtn>
                </S.body>
            </S.modal>
        </S.main>
    );
}

export default EditCompanyAddress;