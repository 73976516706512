import React, { useEffect, useState, useRef } from 'react';
import * as S from './styles';
import general, { convertColorHexToRgba } from '../../../../utils/general';

export const Text = (props: {
    name: string;
    placeholder?: string;
    required?: boolean;
    label: string;
    value: string;
    type?: string;
    onChange: any;
}) => {
    const textRef = useRef<HTMLInputElement>(null);

    return (
        <S.text>
            <S.label htmlFor={props.name}>{props.label}</S.label>
            <S.textInput
                name={props.name}
                id={props.name}
                ref={textRef}
                placeholder={props.placeholder ?? ''}
                defaultValue={props.value ?? ''}
                required={props.required ?? false}
                onKeyUp={(() => {
                    let newValue = textRef.current!.value;
                    if (props.type) {
                        newValue = textRef.current!.value = props.type == 'numeric' ? textRef.current!.value.replace(/[^0-9.,]/g, '') : (props.type == 'money' ? general.formatMoney(textRef.current!.value) : (props.type == 'phone' ? general.phoneMask.phoneMask(textRef.current!.value) : textRef.current!.value));
                    }
                })}
                onKeyDown={(() => {
                    let newValue = textRef.current!.value;
                    if (props.type) {
                        newValue = textRef.current!.value = props.type == 'numeric' ? textRef.current!.value.replace(/[^0-9.,]/g, '') : (props.type == 'money' ? general.formatMoney(textRef.current!.value) : (props.type == 'phone' ? general.phoneMask.phoneMask(textRef.current!.value) : textRef.current!.value));
                    }
                })}
                onFocus={(() => {
                    let newValue = textRef.current!.value;
                    if (props.type) {
                        newValue = textRef.current!.value = props.type == 'numeric' ? textRef.current!.value.replace(/[^0-9.,]/g, '') : (props.type == 'money' ? general.formatMoney(textRef.current!.value) : (props.type == 'phone' ? general.phoneMask.phoneMask(textRef.current!.value) : textRef.current!.value));
                    }
                })}
                onBlur={(() => {
                    let newValue = textRef.current!.value;
                    if (props.type) {
                        newValue = textRef.current!.value = props.type == 'numeric' ? textRef.current!.value.replace(/[^0-9.,]/g, '') : (props.type == 'money' ? general.formatMoney(textRef.current!.value) : (props.type == 'phone' ? general.phoneMask.phoneMask(textRef.current!.value) : textRef.current!.value));
                    }
                })}
                onChange={props.onChange}
                autoComplete='disabled'
            />
        </S.text>
    );
}

export default Text;