import React, { useState } from 'react';
import { useController } from './controller';
import { addCompanyModalProps } from '../../../utils/types';
import * as S from './styles';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css'
import CountUp from 'react-countup';
import { plan } from './types';

const AddCompanyModal = (props: addCompanyModalProps) => {
    const controller = useController(props);
    const countUpDuration = 1;
    const decimals = 2;
    const decimalSeparator = ',';
    const countUpStart = 0;

    return (
        <S.main active={controller.modalActive} animationOpacity={props.modalActive} onClick={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal step={controller.step} onClick={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                <S.header>
                    <S.title>Criar Empresa - {controller.stepName[controller.step].title}</S.title>
                    <S.close onClick={() => {
                        controller.setStep(0);
                        props.setModalActive((active: boolean) => !active)
                    }}>X</S.close>
                </S.header>
                <S.body>
                    <S.stepContainer>
                        {controller.step == 0 ?
                            <S.step active={controller.step == 0} mobileMod={true}>
                                <S.uploadLogo>
                                    <div style={{ position: 'relative', display: 'flex' }}>
                                        <S.avatar logoImg={controller.currentLogo} maxInitials={2} textSizeRatio={5.25} style={{
                                            fontSize: '40px',
                                        }} size='150' name={controller.companyInfo.name}></S.avatar>
                                        <S.uploadTooltip logoImg={controller.currentLogo}>
                                            <S.uploadIcon icon={controller.general.icons.faUpload}></S.uploadIcon>
                                            <S.uploadText>Clique para adicionar uma logo</S.uploadText>
                                        </S.uploadTooltip>
                                        {/* title has a ' '[white space] so it doesnt show anything on hover */}
                                        <S.upload onInput={controller.handleInputFile} type='file' accept='image/*' title=' '></S.upload>
                                    </div>
                                </S.uploadLogo>
                                <S.inputsArea>
                                    <S.inputContainer>
                                        <S.inputLabel>Nome da empresa:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='name' value={controller.companyInfo.name}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>CPF / CNPJ:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='document' value={controller.companyInfo.document}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Email:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='email' value={controller.companyInfo.email}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Telefone:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleInputChange(e) }} name='phone' value={controller.companyInfo.phone}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}
                        {controller.step == 1 ?
                            <S.step active={controller.step == 1} mobileMod={true}>
                                <S.inputsArea occupyAll>
                                    <S.inputContainer>
                                        <S.inputLabel>CEP:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.zip_code' value={controller.companyInfo.address.zip_code}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Estado:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.state' value={controller.companyInfo.address.state}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Cidade:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.city' value={controller.companyInfo.address.city}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Bairro:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.neighborhood' value={controller.companyInfo.address.neighborhood}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Rua:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.street' value={controller.companyInfo.address.street}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Número:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.number' value={controller.companyInfo.address.number}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Complemento:</S.inputLabel>
                                        <S.input onChange={(e) => { controller.handleAddressChange(e) }} name='address.complement' value={controller.companyInfo.address.complement}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}
                        {controller.step == 2 ?
                            <S.step active={controller.step == 2} mobileMod={true}>
                                <S.planPeriodContainer>
                                    <S.planPeriod selected={controller.companyInfo.planPeriod == 1 ? true : false} onClick={() => controller.setCompanyInfo({ ...controller.companyInfo, planPeriod: 1 })}>Mensal</S.planPeriod>
                                    <S.planPeriod selected={controller.companyInfo.planPeriod == 6 ? true : false} onClick={() => controller.setCompanyInfo({ ...controller.companyInfo, planPeriod: 6 })}>Semestral (-5%)</S.planPeriod>
                                    <S.planPeriod selected={controller.companyInfo.planPeriod == 12 ? true : false} onClick={() => controller.setCompanyInfo({ ...controller.companyInfo, planPeriod: 12 })}>Anual (-10%)</S.planPeriod>
                                </S.planPeriodContainer>
                                <S.plansArea>
                                    <S.planBackNextContainer toBeReversed={controller.planShowing == 2 ? true : false}>
                                        {controller.planShowing > 2 ? <S.planBackNextMobile onClick={() => controller.changeShowingPlan('back')} icon={controller.general.icons.faChevronLeft}></S.planBackNextMobile> : null}
                                        {controller.planShowing < 4 ? <S.planBackNextMobile onClick={() => controller.changeShowingPlan('next')} icon={controller.general.icons.faChevronRight}></S.planBackNextMobile> : null}
                                    </S.planBackNextContainer>

                                    {controller.plans.map((plan: plan, index: number) => {
                                        return (
                                            <S.plan key={index} selected={controller.companyInfo.planSelected == plan._id ? true : false} planShowing={controller.planShowing} onClick={() => controller.setCompanyInfo({ ...controller.companyInfo, planSelected: plan._id })}>
                                                <S.planTitle>{plan.name}</S.planTitle>

                                                <S.planItems>
                                                    {plan.planItems.map((item, index) => {
                                                        return <S.planItem key={index}>{item}</S.planItem>
                                                    })}
                                                </S.planItems>

                                                <S.planPriceAndHire>
                                                    <S.planPrice>R$ <CountUp duration={countUpDuration} decimals={decimals} decimal={decimalSeparator} start={countUpStart} end={(((plan.value / 100) * controller.companyInfo.planPeriod) * (controller.companyInfo.planPeriod == 6 ? controller.discounts.semestral : (controller.companyInfo.planPeriod == 12 ? controller.discounts.anual : controller.discounts.monthly)))} /></S.planPrice>
                                                    {controller.companyInfo.planPeriod != 0 ? <S.planMonthly>R$ <CountUp duration={countUpDuration} decimals={decimals} decimal={decimalSeparator} start={countUpStart} end={(((((plan.value / 100) * controller.companyInfo.planPeriod) * (controller.companyInfo.planPeriod == 6 ? controller.discounts.semestral : (controller.companyInfo.planPeriod == 12 ? controller.discounts.anual : controller.discounts.monthly)))) / controller.companyInfo.planPeriod)} />/mês</S.planMonthly> : null}
                                                    <S.planHireBtn>{controller.companyInfo.planSelected == plan._id ? 'Selecionado' : 'Selecionar'}</S.planHireBtn>
                                                </S.planPriceAndHire>
                                            </S.plan>
                                        )
                                    })}
                                </S.plansArea>
                            </S.step>
                            : null}

                        {controller.step == 3 ?
                            <S.step active={controller.step == 3} mobileMod={true}>
                                <S.confirmDataArea>
                                    <S.confirmItem>
                                        <S.confirmLabel>Nome da empresa:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.name == '' ? 'Não informado' : controller.companyInfo.name}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>CPF / CNPJ:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.document == '' ? 'Não informado' : controller.companyInfo.document}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Email:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.email == '' ? 'Não informado' : ((controller.companyInfo.email.length >= 30) ? controller.companyInfo.email.slice(0, 28) + '...' : controller.companyInfo.email)}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Telefone:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.phone == '' ? 'Não informado' : controller.companyInfo.phone}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Endereço:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.address.zip_code == '' ? 'Não informado' : `${controller.companyInfo.address.street}, ${controller.companyInfo.address.number} - ${controller.companyInfo.address.neighborhood}, ${controller.companyInfo.address.city} - ${controller.companyInfo.address.state}`}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Plano:</S.confirmLabel>
                                        <S.confirmData>{controller.plans.filter(iPlan => iPlan._id == controller.companyInfo.planSelected)[0].name}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Período do plano:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.planPeriod} meses</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Valor total:</S.confirmLabel>
                                        <S.confirmData>R$ <CountUp duration={countUpDuration} decimals={decimals} decimal={decimalSeparator} start={countUpStart} end={((controller.plans.filter(iPlan => iPlan._id == (controller.companyInfo.planSelected))[0].value * (1 - (controller.cupomDiscount / 100))) / 100) * (controller.companyInfo.planPeriod == 6 ? controller.discounts.semestral : (controller.companyInfo.planPeriod == 12 ? controller.discounts.anual : controller.discounts.monthly)) * controller.companyInfo.planPeriod} />{controller.cupomDiscount > 0 ? ` (- ${controller.cupomDiscount}% Cupom)` : ''}</S.confirmData>
                                    </S.confirmItem>
                                    <S.confirmItem>
                                        <S.confirmLabel>Parcelas:</S.confirmLabel>
                                        <S.confirmData>{controller.companyInfo.cc.installments}x de R$ <CountUp duration={countUpDuration} decimals={decimals} decimal={decimalSeparator} start={countUpStart} end={(((controller.plans.filter(iPlan => iPlan._id == controller.companyInfo.planSelected))[0].value * (1 - (controller.cupomDiscount / 100)) / 100) * (controller.companyInfo.planPeriod == 6 ? controller.discounts.semestral : (controller.companyInfo.planPeriod == 12 ? controller.discounts.anual : controller.discounts.monthly))) * controller.companyInfo.planPeriod / controller.companyInfo.cc.installments} />{controller.cupomDiscount > 0 ? ` (- ${controller.cupomDiscount}% Cupom)` : ''}</S.confirmData>
                                    </S.confirmItem>
                                </S.confirmDataArea>
                                <S.confirmItem>
                                    <S.confirmLabel>Código:</S.confirmLabel>
                                    <S.inputCupomWrapper>
                                        <S.inputCupom placeholder='Ex: BETASTART2024' onChange={(e) => controller.handleCupom(e)} name='cupom' value={controller.companyInfo.cupom}></S.inputCupom>
                                        {controller.cupomStatus != 0 ?
                                            <S.cupomStatus>
                                                {controller.cupomStatus == 1 ? <controller.general.DefaultLoadingFit size={13} active={controller.cupomStatus == 1 ? true : false} /> : null}
                                                {controller.cupomStatus == 2 ? <S.cupomStatusIcon color={controller.general.utilColors.darkGreen} icon={controller.general.icons.faCheckCircle}/> : null}
                                                {controller.cupomStatus == 3 ? <S.cupomStatusIcon color={controller.general.utilColors.pureRed} icon={controller.general.icons.faTimesCircle}/> : null}
                                            </S.cupomStatus>
                                            : null}
                                    </S.inputCupomWrapper>
                                </S.confirmItem>
                            </S.step>
                            : null}

                        {controller.step == 4 ?
                            <S.step active={controller.step == 4} mobileMod={true}>
                                <Cards
                                    number={controller.companyInfo.cc.number ?? ""}
                                    expiry={controller.companyInfo.cc.expiration ?? ""}
                                    cvc={controller.companyInfo.cc.cvc ?? ""}
                                    name={controller.companyInfo.cc.name ?? ""}
                                    focused={controller.companyInfo.cc.focus.split('.')[1] ?? ""}
                                    locale={{ valid: 'válido até' }}
                                    placeholders={{ name: 'NOME NO CARTÃO' }}
                                />
                                <S.inputsArea>
                                    <S.inputContainer>
                                        <S.inputLabel>Número do cartão:</S.inputLabel>
                                        <S.input onFocus={(e) => controller.handleInputFocus(e)} onChange={(e) => controller.handleInputChange(e)} name='cc.number' value={controller.companyInfo.cc.number}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Nome no cartão:</S.inputLabel>
                                        <S.input onFocus={(e) => controller.handleInputFocus(e)} onChange={(e) => controller.handleInputChange(e)} name='cc.name' value={controller.companyInfo.cc.name}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Data de validade:</S.inputLabel>
                                        <S.input onFocus={(e) => controller.handleInputFocus(e)} onChange={(e) => controller.handleInputChange(e)} name='cc.expiration' value={controller.companyInfo.cc.expiration}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Código de segurança (CVC):</S.inputLabel>
                                        <S.input onFocus={(e) => controller.handleInputFocus(e)} onChange={(e) => controller.handleInputChange(e)} name='cc.cvc' value={controller.companyInfo.cc.cvc}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Parcelas (influenciado pelo período do plano):</S.inputLabel>
                                        <S.inputSelect onFocus={(e) => controller.handleInputFocus(e)} onChange={(e) => controller.handleSelectChange(e)} name='cc.installments' value={controller.companyInfo.cc.installments}>
                                            {new Array(controller.companyInfo.planPeriod).fill(0).map((item, index) => {
                                                return <S.inputOption key={index} value={index + 1}>{index + 1}x de R$ <CountUp duration={countUpDuration} decimals={decimals} decimal={decimalSeparator} start={countUpStart} end={((((controller.plans.filter(iPlan => iPlan._id == controller.companyInfo.planSelected))[0].value  * (1 - (controller.cupomDiscount / 100)) / 100) * controller.companyInfo.planPeriod) * (controller.companyInfo.planPeriod == 6 ? controller.discounts.semestral : (controller.companyInfo.planPeriod == 12 ? controller.discounts.anual : controller.discounts.monthly))) / (index + 1)} /></S.inputOption>
                                            })}
                                        </S.inputSelect>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}

                        {controller.step == 5 ?
                            <S.step active={controller.step == 5} mobileMod={true}>
                                <S.congratulationsArea>
                                    <S.congratulationsIcon icon={controller.general.icons.faBuildingCircleCheck}></S.congratulationsIcon>
                                    <S.congratulationsTextArea>
                                        <S.congratulationsTitle>Parabéns! Sua empresa foi criada com sucesso!</S.congratulationsTitle>
                                        <S.congratulationsText>Você acaba de dar mais um passo em direção ao sucesso do seu negócio. Em instantes será enviado um email confirmando seu cadastro juntamente com a nota fiscal. Caso tenha qualquer dúvida contate-nos no suporte.</S.congratulationsText>
                                    </S.congratulationsTextArea>
                                </S.congratulationsArea>
                            </S.step>
                            : null}
                    </S.stepContainer>
                    <S.progressBar>
                        <S.progress percentage={controller.calculateStepPercentage()}></S.progress>
                        <S.progressPercentage>
                            {controller.calculateStepPercentage() + '%'}
                        </S.progressPercentage>
                    </S.progressBar>
                    <S.nextBackBtns>
                        <S.nextBackBtn onClick={() => controller.changeStep('next')}>{controller.stepName[controller.step].nextBtn ? controller.stepName[controller.step].nextBtn : ''}</S.nextBackBtn>
                        {(controller.step == controller.stepName.length - 1 || controller.step == 0) ? null : <S.nextBackBtn onClick={() => controller.changeStep('back')}>{controller.stepName[controller.step].backBtn ? controller.stepName[controller.step].backBtn : ''}</S.nextBackBtn>}
                    </S.nextBackBtns>
                </S.body>
            </S.modal>
        </S.main>
    );
}

export default AddCompanyModal;