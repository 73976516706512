import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import moment from 'moment';

const CardGoals = (props: {
    title: string,
    goal: number,
    current: number,
    complete_until: Date | string,
    increase_goal: () => void,
    decrease_goal: () => void,
    openModal: Function
}) => {
    const controller = useController();
    const [oldValue, setOldValue] = useState(0);

    return (
        <S.main>
            <S.cardData>
                <S.cardTitles>
                    <S.cardTitle>{props.title ?? 'Não informado'}</S.cardTitle>
                    <S.cardSubTitle>{`${props.complete_until ? 'Completar até: ' + moment(props.complete_until).format('DD/MM/YYYY') : ''}`}</S.cardSubTitle>
                </S.cardTitles>
                <S.cardGoal>
                    <S.goalArea>
                        <S.modifyGoalButton onClick={() => {
                            setOldValue(props.current);
                            props.decrease_goal()
                        }} icon={controller.general.icons.faMinusCircle} />
                        <S.goalShowArea>
                            <S.goalBar oldPercentage={(oldValue ?? 0) / (props.goal ?? 1) * 100} percentage={(props.current ?? 0) / (props.goal ?? 1) * 100} />
                            <S.goalInfo>
                                <S.goal>{props.current ?? 0} de {props.goal ?? 1}</S.goal>
                            </S.goalInfo>
                        </S.goalShowArea>
                        <S.modifyGoalButton onClick={() => {
                            setOldValue(props.current);
                            props.increase_goal()
                        }} icon={controller.general.icons.faPlusCircle} />
                    </S.goalArea>
                </S.cardGoal>
                <S.modifyGoalBtn onClick={() => props.openModal(true)}>
                    Modificar Meta
                </S.modifyGoalBtn>
            </S.cardData>
        </S.main>
    );
}

export default CardGoals;