import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import useController from './controller';
import Avatar from 'react-avatar';

const animationPopUp = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`;

export const main = styled.div<{ active: boolean, animationOpacity: boolean }>`
    display: flex;
    width: ${props => props.active ? '100vw' : '0px'};
    height: ${props => props.active ? '100vh' : '0px'};
    opacity: ${props => props.animationOpacity ? 1 : 0};
    transition-duration: 0.3s;
    transition-property: opacity;
    position: fixed;
    background-color: rgba(0,0,0,0.75);
    z-index: 999;
    justify-self: center;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
`;
export const modal = styled.div<{ animationInOut: boolean, step: number }>`
    width: ${props => props.step === 1 ? '60%' : '35%'};
    max-height: 75%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    opacity: ${props => props.animationInOut ? 1 : .5};
    transform: ${props => props.animationInOut ? 'translateY(0px)' : 'translateY(100px)'};
    transition-duration: 1s;

    @media(max-width: 1500px){
        width: 65%;
        margin-top: -5%;

        @media(max-height: 700px){
            width: 55%;
            max-height: 85%;
        }
    }
    @media(max-width: 1024px){
        @media(min-height: 1100px){
            width: 85%;
            margin-top: -25%;
            min-height: auto;
        }
    }
    @media(max-width: 800px){
        width: ${props => props.step === 1 ? '95%' : '60%'};
        margin-top: -10%;

        @media(min-width: 700px){
            width: ${props => props.step === 1 ? '90%' : '60%'};
            min-height: auto;
            transition-duration: 0.3s;
        }
    }
    @media(max-width: 500px){
        max-height: 85%;
        width: 85%;
        margin-top: -20%;
        padding-top: 5px;
    }
`;
export const header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;
export const title = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;

    @media(max-width: 500px){
        margin: 20px 20px 20px 0;
        font-size: 14px;
    }
`;
export const close = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.red};
    cursor: pointer;

    @media(max-width: 500px){
        margin: 20px 0;
    }

    &:hover{
        transform: scale(1.2);
    }
`;
export const body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const stepContainer = styled.div`
    width: 100%;
    min-height: 100px;
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: column;

    @media(max-width: 900px){
        @media(min-width: 700px){
            margin: 0;
        }
    }
`;
const easeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
export const step = styled.div<{ active: boolean, mobileMod: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: space-between;
    animation: ${easeIn} 0.75s;

    ${props => props.active ? css`
        width: 100%;
        height: auto;
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.5s, transform 0s;

    ` : css`
        width: 0;
        height: 0;
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.5s, transform 0s;
    `}

    @media(max-width: 800px){
        flex-direction: ${props => props.mobileMod ? 'column' : 'row'};
    }
`;
export const inputsArea = styled.div`
    width: 100%;

    @media(max-width: 800px){
        width: 100%;
    }
`;
export const inputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 98%;
    margin: 5px 0;
    position: relative;

    @media(max-width: 800px){
        width: 100%;
    }
`;
export const inputLabel = styled.label`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 5px;
    color: ${colors.black};
    text-decoration: capitalize;
`;
export const input = styled.input`
    font-family: ${fonts.main};
    wdith: 100%;
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;
    -moz-appearance   : textfield;
    -webkit-appearance: none;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;
export const inputSelect = styled.select`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;
export const inputOption = styled.option`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    transition-duration: 0.3s;

    &:focus{
        outline: none;
        border: 1px solid ${colors.black};
    }
`;

export const saveBtn = styled.button`
    border: 1px solid ${colors.black};
    border-radius: 5px;
    width: 98.5%;
    margin-top: 15px;
    justify-self: center;
    align-self: center;
    height: 40px;
    background-color: ${colors.backgroundWhite};
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition-duration: 0.3s;
    color: ${colors.black};
    animation: ${animationPopUp} 0.3s;
    position: relative;

    &:hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }
`;