import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import EditAccountModal from '../EditAccountModal';

const NoPermissionComponent = (props: any) => {
    const controller = useController();

    return (
        <S.blockedItems>
            <S.blockedItemsIcon icon={controller.general.icons.faBan} />
            <S.blockedItemsTitle>{props.permissionDeniedMessage ?? "Você não possui permissão para visualizar isso."}</S.blockedItemsTitle>
        </S.blockedItems>
    );
}

export default NoPermissionComponent;