import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import useController from './controller';
import Avatar from 'react-avatar';

const animationPopUp = keyframes`
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
`;

export const main = styled.div<{ active: boolean, animationOpacity: boolean }>`
    display: flex;
    width: ${props => props.active ? '100vw' : '0px'};
    height: ${props => props.active ? '100vh' : '0px'};
    opacity: ${props => props.animationOpacity ? 1 : 0};
    transition-duration: 0.3s;
    transition-property: opacity;
    position: fixed;
    background-color: rgba(0,0,0,0.75);
    z-index: 999;
    justify-content: center;
    align-items: center;

    @media print {
        display: none;
    }
`;
export const modal = styled.div<{ animationInOut: boolean, step: number }>`
    width: ${props => props.step === 0 ? '36%' : '45%'};
    max-height: 90%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 999;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    opacity: ${props => props.animationInOut ? 1 : .5};
    transform: ${props => props.animationInOut ? 'translateY(0px)' : 'translateY(100px)'};
    transition-duration: 1s;

    @media(max-width: 1500px){
        width: 65%;
        margin-top: -5%;

        @media(max-height: 700px){
            width: 75%;
            max-height: 85%;
        }
    }
    @media(max-width: 1024px){
        @media(min-height: 1100px){
            width: 85%;
            margin-top: -25%;
            min-height: auto;
        }
    }
    @media(max-width: 800px){
        width: ${props => props.step === 1 ? '95%' : '60%'};
        margin-top: -10%;

        @media(min-width: 700px){
            width: ${props => props.step === 1 ? '90%' : (props.step == 2 ? '85%' : (props.step == 3 ? '90%' : '70%'))};
            min-height: auto;
            transition-duration: 0.3s;
        }
    }
    @media(max-width: 500px){
        max-height: 85%;
        width: 85%;
        margin-top: -20%;
        padding-top: 5px;
    }
`;
export const header = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
`;
export const title = styled.h2`
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;

    @media(max-width: 500px){
        margin: 20px 20px 20px 0;
        font-size: 14px;
    }
`;
export const close = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;
    color: ${colors.red};
    cursor: pointer;

    @media(max-width: 500px){
        margin: 20px 0;
    }

    &:hover{
        transform: scale(1.2);
    }
`;
export const body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
export const stepContainer = styled.div<{step: number}>`
    width: 100%;
    min-height: ${props => props.step == 1 ? '350px' : '100px'};
    margin: 0 0 10px 0;
    display: flex;
    flex-direction: column;

    @media(max-width: 900px){
        @media(min-width: 700px){
            margin: 0;
        }
    }
`;
const easeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;
export const step = styled.div<{step: number, active: boolean, mobileMod: boolean }>`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    align-self: center;
    justify-self: center;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;
    animation: ${easeIn} 0.75s;

    ${props => props.active ? css`
        width: 100%;
        height: ${props.step == 1 ? '350px' : 'auto'};
        opacity: 1;
        transform: scale(1);
        transition: opacity 0.5s, transform 0s;

    ` : css`
        width: 0;
        height: 0;
        opacity: 0;
        transform: scale(0);
        transition: opacity 0.5s, transform 0s;
    `}

    > .selectWithSearchFinancesModal {
        width: 97%;
        height: auto;
        align-self: flex-start;
        display: flex;
        margin: 10px 0 0 1.5%;
    }
`;
export const progressBar = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 5px;
    margin: 10px 0;
    align-items: center;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    gap: 5px;
`;
export const progressPercentage = styled.div`
    font-family: ${fonts.main};
    font-size: 11px;
    font-weight: 600;
    margin-left: -8px;
    color: ${colors.white};
    padding: 3px;
    background-color: ${colors.black};
    border-radius: 5px;
    z-index: 999;
`;
export const progress = styled.div<{ percentage: number }>`
    display: flex;
    flex-direction: row;
    width: ${props => props.percentage}%;
    height: 100%;
    justify-content: space-between;
    padding: 0 10px;
    background-color: ${colors.black};
    border-radius: 5px;
`;
export const nextBackBtns = styled.div`
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
`;
export const nextBackBtn = styled.button`
    border: 1px solid ${colors.black};
    border-radius: 5px;
    width: 200px;
    height: 40px;
    background-color: ${colors.backgroundWhite};
    font-family: ${fonts.main};
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
    transition-duration: 0.3s;
    color: ${colors.black};
    animation: ${animationPopUp} 0.3s;
    position: relative;

    &:hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }

    @media(max-width: 500px){
        width: 160px;
    }
`;

/* step 1 - movement type */
export const movementTypeContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 10px 0;
    justify-content: center;
    gap: 20px;
`;
export const movementType = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 180px;
    border: 1px solid ${colors.black};
    border-radius: 10px;
    padding: 25px 10px;
    cursor: pointer;
    transition-duration: 0.3s;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: ${colors.backgroundWhite};

    &:hover{
        ${props => !props.selected ? css`
            &:nth-child(1){
                background-color: ${colors.priceGreen};
                border: 1px solid ${colors.priceGreen};
            }
            &:nth-child(2){
                background-color: ${colors.red};
                border: 1px solid ${colors.red};
            }
        ` : ''}
        * {
        color: ${colors.backgroundWhite};
        }
    }

    ${props => props.selected ? css`
        * {
            color: ${colors.backgroundWhite};
        }
        &:nth-child(1){
            background-color: ${colors.priceGreen};
            border: 1px solid ${colors.priceGreen};
        }
        &:nth-child(2){
            background-color: ${colors.red};
            border: 1px solid ${colors.red};
        }
    ` : css`
        background-color: ${colors.backgroundWhite};
        * {
            color: ${colors.black};
        }
    `}

    @media(max-width: 800px){
        width: 100%;
    }
`;
export const movementTypeIcon = styled(general.FontAwesomeIcon)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
`;
export const movementTypeTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    transition-duration: 0.3s;
`;
export const movementTypeDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    text-align: center;
`;

/* step 2 - movement items */
export const movementItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    margin: 10px 0;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
`;
export const totalValueContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: calc(100% - 20px);
    margin-top: -10px;
`;
export const totalValue = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 10px;
    margin-left: auto;
`;
export const movementItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media(max-width: 800px){
        width: 90%;
    }
`;
export const itemImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 10px;

    @media(max-width: 800px){
        width: 90px;
        height: 90px;
    }
`;
export const itemContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    gap: 10px;

    @media(max-width: 800px){
        flex-direction: column;
        width: 100%;
    }
`;
export const itemInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
    flex: 1;
    justify-content: center;

    @media(max-width: 800px){
        gap: 5px;
        padding: 0;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
`;
export const itemTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: justify;

    @media(max-width: 1200px){
        font-size: 16px;
    }
    @media(max-width: 800px){
        padding-right: 10px;
        font-size: 14px;
    }
`;
export const itemDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    text-align: justify;

    @media(max-width: 800px){
        display: none;
    }
`;
export const itemInputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    @media(max-width: 800px){
        flex-direction: row;
        width: 100%;
    }
`;
export const itemInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
`;
export const itemInputLabel = styled.label`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
`;
export const itemInputField = styled.input`
    width: 80%;
    height: 30px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    padding: 5px;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    outline: none;
`;
export const deleteItemIcon = styled(general.FontAwesomeIcon)`
    font-size: 30px;
    margin: 5px 15px 0 0;
    color: ${colors.grey};
    cursor: pointer;
    transition-duration: 0.15s;

    &:hover{
        transform: scale(1.2);
        color: ${colors.red};
    }

    @media(max-width: 800px){
        display: none;
    }
`;

export const deleteItemIconMobile = styled(general.FontAwesomeIcon)`
    font-size: 20px;
    color: ${colors.grey};
    cursor: pointer;
    transition-duration: 0.15s;
    margin-right: 10px;
    align-self: center;

    &:hover{
        transform: scale(1.2);
        color: ${colors.red};
    }

    @media(min-width: 800px){
        display: none;
    }
`;

export const noItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: absolute;
    gap: 10px;
`;

export const noItemsIcon = styled(general.FontAwesomeIcon)`
    font-size: 40px;
    color: ${colors.grey};
`;

export const noItemsTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
`;

export const noItemsDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    text-align: center;
`;

/* step 3 - payment method */
export const paymentMethodContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

export const paymentMethodBit = styled.div<{ selected: boolean }>`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 20%;
    height: 80px;
    border: 1px solid ${colors.black};
    border-radius: 10px;
    padding: 25px 10px;
    cursor: pointer;
    transition-duration: 0.3s;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: ${colors.backgroundWhite};

    &:hover{
        ${props => !props.selected ? css`
            background-color: ${colors.black};
            border: 1px solid ${colors.black};
        ` : ''}
        * {
        color: ${colors.backgroundWhite};
        }
    }

    ${props => props.selected ? css`
        * {
            color: ${colors.backgroundWhite};
        }
        background-color: ${colors.black};
        border: 1px solid ${colors.black};
    ` : css`
        background-color: ${colors.backgroundWhite};
        * {
            color: ${colors.black};
        }
    `}

    @media(max-width: 500px){
        width: 20%;
    }
`;
export const paymentMethodIcon = styled(general.FontAwesomeIcon)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 40px;

    @media(max-width: 500px){
        width: 40%;
    }
`;
export const paymentMethodTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    transition-duration: 0.3s;
`;

/* step 4 - movement data */

export const inputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin: 10px 0;
    justify-content: center;
    align-items: center;

    > div > input {
        width: 100%;
        align-self: center;
    }

    > div > label {
        width: 100%;
        align-self: center;
    }

    > div {
        width: 95%;
    }

    .selectWithSearchCurrency{
        width: calc(100% + 10px);
        
        > div > div {
            width: calc(100% - 10px);
            margin: 0 0 0 -5px;
        }
    }
`;

export const printArea = styled.div`
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${colors.backgroundWhite};
    z-index: 99999999;

    @media print {
        display: block;
    }
`;

export const fiscalNotesContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 10px;
    margin: 10px 0;
    justify-content: center;
    align-items: center;

    @media(max-width: 500px){
        flex-direction: column;
    }
`;
export const noteTypeCard = styled.div<{ selected: boolean, disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 180px;
    border: 1px solid ${colors.black};
    border-radius: 10px;
    padding: 25px 10px;
    cursor: pointer;
    transition-duration: 0.3s;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    background-color: ${colors.backgroundWhite};
    opacity: ${props => props.disabled ? 0.3 : 1};

    &:hover{
        ${props => !props.selected ? css`
            background-color: ${colors.black};
            border: 1px solid ${colors.black};
        ` : ''}
        * {
        color: ${colors.backgroundWhite};
        }
    }

    ${props => props.selected ? css`
        * {
            color: ${colors.backgroundWhite};
        }
        background-color: ${colors.black};
        border: 1px solid ${colors.black};
    ` : css`
        background-color: ${colors.backgroundWhite};
        * {
            color: ${colors.black};
        }
    `}

    @media(max-width: 800px){
        width: 100%;
        gap: 10px;
    }

    @media(max-width: 500px){
        width: 95%;
        height: 80px;
        flex-direction: row;
        padding: 10px;
    }
`;
export const noteTypeIcon = styled(general.FontAwesomeIcon)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    height: 50px;
    width: 50px;

    @media(max-width: 500px){
        margin: 0;
        width: 40px;
        height: 40px;
    }
`;
export const noteTexts = styled.div`
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    gap: 10px;
    flex: 1;
    align-items: center;

    @media(max-width: 500px){
        gap: 5px;
        align-items: flex-start;
        padding: 0 10px;
    }
`;
export const noteTypeTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 15px;
    text-align: center;
    align-self: center;
    justify-self: center;
    font-weight: 600;
    margin: 5px 0;
    text-transform: uppercase;
    transition-duration: 0.3s;
    flex: 1;

    @media(max-width: 500px){
        align-self: flex-start;
        justify-self: flex-start;
        flex: 0;
        text-align: justify;
    }
`;
export const noteTypeDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    align-self: center;
    justify-self: center;
    flex: 1;

    @media(max-width: 800px){
        font-size: 12px;
    }

    @media(max-width: 500px){
        align-self: flex-start;
        justify-self: flex-start;
        text-align: justify;
        font-size: 14px;
    }
`;