import styled, {keyframes} from 'styled-components';
import general from '../../../../utils/general';
import {colors} from '../../../../utils/colors';
import {fonts} from '../../../../utils/fonts';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 1280px) {
        height: calc(100% - 60px);
    }

    @media (max-width: 500px) {
        width: 1px;
    }
`;
export const operationsArea = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
    grid-template-rows: auto 1fr;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;
    justify-content: center;

    &::after {
        content: '';
        flex: auto;
    }

    @media (max-width: 1366px) {
        grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
    }

    @media (max-width: 900px) {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }

    @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        justify-content: left;
    }
`;

export const formNotFound = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 75px;
`;
export const formNotFoundIcon = styled(general.FontAwesomeIcon)`
    align-self: center;
    justify-self: center;
    font-size: 144px;
    color: ${colors.black};

    @media (max-width: 500px) {
        font-size: 120px;
    }
`;
export const formNotFoundTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.black};
    text-align: center;
`;
export const deleteFormWrapper = styled.div<{active: boolean}>`
    display: ${props => props.active ? 'flex' : 'none'};
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    justify-content: center;
    align-items: center;
    z-index: 999;
`;
const deleteModalAnimation = keyframes`
    from {
        opacity: 0;
        transform: scale(0.5);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;
export const deleteForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 35px;
    width: 300px;
    height: 150px;
    background-color: ${colors.white};
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    animation: ${deleteModalAnimation} 0.3s;

    @media (max-width: 500px) {
        margin-top: -15%;
    }
`;
export const deleteFormTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-align: center;
`;
export const deleteFormButtons = styled.div`
    display: flex;
    gap: 10px;
    justify-content: center;
`;
export const deleteFormButton = styled.button<{warning?: boolean}>`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    transition-duration: 0.2s;
    background-color: ${props => props.warning ? colors.red : colors.black};
    color: ${colors.white};
    text-transform: uppercase;

    &:hover {
        transform: scale(1.05);
    }
`;