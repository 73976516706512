import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import ReactQuill from 'react-quill';

export const main = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;

export const modal = styled.div`
    width: 60%;
    height: 75%;
    display: flex;
    flex-direction: column;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 15px 20px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 99999999;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    transition-duration: 0.3s;

    @media(max-width: 1200px){
        width: 75%;
        margin-top: -5%;
    }
    @media(max-width: 800px){
        margin-top: -10%;
        width: 80%;
    }
    @media(max-width: 500px){
        width: 85%;
        height: 90%;
        margin-top: -10%;
        padding-top: 5px;
        flex-direction: column;
    }
`;

export const header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media(max-width: 500px){
        margin: 15px 0;
    }
`;

export const title = styled.h1`
    font-family: ${fonts.main};
    font-size: 21px;
    font-weight: 600;
    text-transform: uppercase;

    @media(max-width: 500px){
        font-size: 17px;
    }
`;

export const close = styled.div`
    cursor: pointer;
    font-size: 24px;
    font-weight: 600;

    &:hover {
        color: ${colors.red};
    }
`;

export const fields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
    padding: 0 10px 10px 0;

    @media(max-width: 500px){
        padding: 0 3px 10px 0;
    }
`;

const popUpFieldAnimation = keyframes`
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(0.95);
    }
    75% {
        transform: scale(1.025);
    }
    100% {
        transform: scale(1);
    }
`;

export const field = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: ${colors.white};
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    margin: 0 10px;
    animation: ${popUpFieldAnimation} 0.6s;
`;

export const requestFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const add = styled.div`
    cursor: pointer;
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
`;

export const footer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1px;
`;

export const button = styled.div`
    cursor: pointer;
    padding: 10px;
    background-color: ${colors.green};
    color: ${colors.white};
    border-radius: 5px;
    text-align: center;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    &:hover {
        background-color: ${colors.darkGreen};
    }

    :first-child {
        border-radius: 5px 0 0 5px;
    }

    :last-child {
        border-radius: 0 5px 5px 0;
    }
`;

export const optionsArea = styled.div`
    display: flex;
    flex-direction: row;
    gap: 35px;
    align-items: center;
    justify-content: center;

    @media(max-width: 500px){
        gap: 10px;
    }
`;

export const fieldOption = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    transition-duration: 0.1s;
`;

export const optionIcon = styled(general.FontAwesomeIcon)`
    cursor: pointer;
    font-size: 14px;
    transition-duration: 0.1s;
    margin-top: -2px;
`;

export const optionLabel = styled.label`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    transition-duration: 0.1s;
    cursor: pointer;

    @media(max-width: 500px){
        font-size: 11px;
    }
`;

export const allowedDays = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin: 10px 5px;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const label = styled.label`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
`;

export const day = styled.div<{active: boolean}>`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${props => props.active ? colors.grey : colors.black};
    border-radius: 5px;
    padding: 10px 5px;
    flex: 1;
    min-width: 100px;
    background-color: ${props => props.active ? colors.black : colors.white};
`;

export const dayIcon = styled(general.FontAwesomeIcon)<{active: boolean}>`
    font-size: 22px;
    cursor: pointer;
    transition-duration: 0.1s;
    color: ${props => props.active ? colors.white : colors.black};
`;

export const dayLabel = styled.label<{active: boolean}>`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    color: ${props => props.active ? colors.white : colors.black};
`;

const moveIntoViewAnimation = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
`;

export const warning = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 10px 5px;
    font-family: ${fonts.main};
    font-size: 12px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    color: #FFA500;
    animation: ${moveIntoViewAnimation} 0.5s;
`;