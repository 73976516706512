import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';

export const wrapper = styled.div<{active: boolean}>`
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid ${props => props.active ? colors.grey : colors.black};
    border-radius: 5px;
    padding: 10px 5px;
    flex: 1;
    min-width: 100px;
    background-color: ${props => props.active ? colors.black : colors.white};
`;

export const checkboxIcon = styled(general.FontAwesomeIcon)<{active: boolean}>`
    font-size: 22px;
    cursor: pointer;
    transition-duration: 0.1s;
    color: ${props => props.active ? colors.white : colors.black};
`;

export const checkboxLabel = styled.label<{active: boolean}>`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    color: ${props => props.active ? colors.white : colors.black};

    @media (max-width: 500px) {
        font-size: 12px;
    }
`;