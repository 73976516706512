import general from '../../../utils/general';
import { useState, useEffect } from 'react';

export const useController = (props: {
    total_pages: number;
    page: number;
    setPage: (page: number) => void;
}) => {
    const [paginationPage, setPaginationPage] = useState(props.page + 1);
    const [page, setPage] = useState(props.page);

    const changePage = (newPage: number) => {
        if (newPage > 0 && newPage <= props.total_pages) {
            setPaginationPage(newPage);
        }
    }

    useEffect(() => {
        props.setPage(paginationPage - 1);
    }, [paginationPage]);
    
    return {
        general,
        paginationPage,
        setPaginationPage,
        page,
        setPage,
        changePage
    };
}

export default useController;