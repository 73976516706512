import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import nfceTemplate from './print/nfce'
import nfCupomTemplate from './print/nfcupom'

export const useController = (props: any) => {
    enum PrintOptions {
        DontPrint = 0,
        NFCe = 1,
        NonFiscalCupom = 2,
        Warranty = 3
    }

    enum PaymentMethod {
        CREDIT_CARD = 0,
        DEBIT_CARD = 1,
        VOUCHER = 2,
        CASH = 3,
        PIX = 4,
        TRANSFER = 5,
        BANK_SLIP = 6,
        OTHER = 7
    }

    const [modalActive, setModalActive] = useState(false);
    const [step, setStep] = useState(0);
    const [printed, setPrinted] = useState(false);
    const [movementType, setMovementType] = useState(1);
    const [productsAndServices, setProductsAndServices] = useState([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [title, setTitle] = useState('');
    const [currency, setCurrency] = useState('BRL');
    const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.CREDIT_CARD);
    const [totalValue, setTotalValue] = useState('');
    const [installments, setInstallments] = useState(1);
    const [dueAt, setDueAt] = useState('');
    const [paidAt, setPaidAt] = useState(new Date().toLocaleDateString());
    const [clientDocument, setClientDocument] = useState('');
    const [loading, setLoading] = useState(false);
    const [loadingItems, setLoadingItems] = useState(false);
    const [companyClients, setCompanyClients] = useState<any>([]);
    const [warrantyTime, setWarrantyTime] = useState(7);
    const [serviceFee, setServiceFee] = useState(general.formatMoney('0'));
    const [client, setClient] = useState<any>({});
    const [printOption, setPrintOption] = useState(PrintOptions.NonFiscalCupom);
    const [forceUpdate, setForceUpdate] = useState('');
    const [stepName, setStepName] = useState([
        {
            title: 'Tipo de movimentação',
            nextBtn: 'Seleção de itens'
        },
        {
            title: 'Seleção de itens',
            nextBtn: 'Dados da movimentação',
            backBtn: 'Voltar'
        },
        {
            title: 'Dados da movimentação!',
            nextBtn: 'Forma de pagamento',
            backBtn: 'Voltar',
        },
        {
            title: 'Forma de pagamento',
            nextBtn: 'Gerar comprovantes',
            backBtn: 'Voltar',
        },
        {
            title: 'Nota fiscal',
            nextBtn: 'Finalizar',
        }
    ]);

    const executeOnCreate = () => {
        props.executeOnCreate();
    }

    const calculateStepPercentage = () => {
        return (step * 100) / (stepName.length - 1);
    }

    const clearModal = () => {
        props.setModalActive(false);
        setStep(0);
        setMovementType(1);
        setSelectedItems([]);
        setTitle('');
        setCurrency('BRL');
        setTotalValue('');
        setDueAt('');
        setPaidAt('');
        executeOnCreate();
    }

    const getCompanyClients = async () => {
        try {
            setLoadingItems(true);
            await general.api.post('stakeholder/list', {
                page: 0,
                type: 1,
                limit: 999999999999
            }).then(async (response) => {
                if (response.status === 200) {
                    await general.api.post('stakeholder/list', {
                        page: 0,
                        type: 2,
                        limit: 999999999999
                    }).then((responseSuppliers) => {
                        if (responseSuppliers.status === 200) {
                            setCompanyClients([...response.data.data.data, ...responseSuppliers.data.data.data]);
                        }
                    });
                }
                setLoadingItems(false);
            });
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar clientes!', 'error');
            setLoadingItems(false);
        }
    }

    const changeStep = (optionStep: string, print = true) => {
        if (optionStep === 'next') {
            if (step == 1) {
                let generateTitle = '';
                selectedItems.forEach((item: any) => {
                    generateTitle += `${item.quantity}x ${item.name}, `;
                });
                setTitle(generateTitle.substring(0, generateTitle.length - 2));
            }
            if (step == 3) {
                createFinancialMovement();
            }
            if (step == 4) {
                if (printed || !print) {
                    clearModal();
                }
                else {
                    setPrintOption(PrintOptions.NonFiscalCupom);
                    setPrinted(printed => !printed);
                    setTimeout(() => {
                        window.print();
                    }, 200);
                }
                return;
            }
            setStep(step => step + 1);
        }
        if (optionStep === 'back') {
            if (step == 0) return;
            if (step == 2 && movementType == 0) {
                setStep(step => step - 2);
                return;
            }
            setStep(step => step - 1);
        }
    }

    const getProductsAndServices = async () => {
        let items: any = [];

        try {
            setLoadingItems(true);
            await general.api.post('product/list', {
                page: 0,
                limit: 999999999999
            }).then((response) => {
                if (response.status === 200) {
                    items = [...items, ...response.data.data.data.map((item: any) => {
                        return {
                            ...item,
                            quantity: 1,
                            type: 'product',
                            image_url: (item.image_url && item.image_url != '') ? item.image_url : `${process.env.REACT_APP_BASE_URL}app/images/default_product.jpg`
                        }
                    })
                    ];
                }
                setLoadingItems(false);
            });
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar produtos!', 'error');
            setLoadingItems(false);
        }

        try {
            setLoadingItems(true);
            await general.api.post('service/list', {
                page: 0,
                limit: 999999999999
            }).then((response) => {
                if (response.status === 200) {
                    items = [...items, ...response.data.data.data.map((item: any) => {
                        return {
                            ...item,
                            quantity: 1,
                            type: 'service',
                            image_url: (item.image_url && item.image_url != '') ? item.image_url : `${process.env.REACT_APP_BASE_URL}app/images/default_service.jpg`
                        }
                    })
                    ];
                }
                setLoadingItems(false);
            });
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar serviços!', 'error');
            setLoadingItems(false);
        }

        items = [...items]

        setProductsAndServices(items.sort((a: any, b: any) => a.name.localeCompare(b.name)));
    }

    const deleteItem = (item: any) => {
        let tempItems = selectedItems;
        tempItems = tempItems.filter((tempItem: any) => tempItem._id !== item._id);
        setSelectedItems([...tempItems]);
    }

    const calculateTotalValue = () => {
        let total = 0;
        selectedItems.forEach((item: any) => {
            total += parseFloat(movementType == 1 ? item.price : (item.buy_price ?? 0)) * parseInt(item.quantity);
        });
        setTotalValue((total / 100).toFixed(2).toString().replace('.', ','));
    }

    const executeOnItemSelect = (item: any) => {
        let tempItems = selectedItems;
        if (tempItems.find((tempItem: any) => tempItem._id === item._id)) {
            tempItems = tempItems.map((tempItem: any) => {
                if (tempItem._id === item._id) {
                    tempItem.quantity += 1;
                }
                return tempItem;
            });
            setSelectedItems([...tempItems]);
            return;
        };
        tempItems.unshift({ ...item, quantity: 1 });
        setSelectedItems([...tempItems]);
    }

    const executeOnCurrencySelect = (currency: any) => {
        setCurrency(currency.value);
    }

    const changeItemQuantity = (quantity: number, item: any) => {
        let tempItems = selectedItems;
        tempItems = tempItems.map((tempItem: any) => {
            if (tempItem._id === item._id) {
                tempItem.quantity = quantity < 1 ? 1 : quantity;
            }
            return tempItem;
        });
        setSelectedItems([...tempItems]);
    }

    const changeItemPrice = (price: string, item: any) => {
        let tempItems = selectedItems;
        tempItems = tempItems.map((tempItem: any) => {
            if (tempItem._id === item._id) {
                tempItem.price = parseFloat(price.replace(/\./g, '').replace(/\,/g, '.')) * 100
            }
            return tempItem;
        });
        setSelectedItems([...tempItems]);
    }

    const buildRequestObject = () => {
        let products: any = [];
        let services: any = [];

        selectedItems.forEach((item: any) => {
            if (item.type === 'product') {
                products.push({
                    product: item,
                    quantity: item.quantity,
                    value: item.price
                });
            }
            if (item.type === 'service') {
                services.push({
                    service: item,
                    quantity: item.quantity,
                    value: item.price
                });
            }
        });

        return {
            title: title,
            type: movementType,
            products: products,
            services: services,
            total_value: parseInt(totalValue.toString().replace(/\,/g, '.').replace(/\./g, '')),
            installments: installments,
            payment_method: paymentMethod,
            currency: currency,
            due_at: dueAt.split('/').reverse().join('-'),
            paid_at: paidAt.split('/').reverse().join('-'),
            client: client ?? null,
        }
    }

    const createFinancialMovement = async () => {
        try {
            setLoading(true);
            let requestObject = buildRequestObject();
            await general.api.post('financial-movement/create', requestObject).then((response) => {
                if (response?.data?.status == 403) {
                    return general.notify(response.data.message, 'error');
                }
                if (response.status === 200) {
                    executeOnCreate();
                    general.notify('Movimentação financeira criada com sucesso!', 'success');
                }
                setLoading(false);
            });
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao criar movimentação financeira!', 'error');
            setLoading(false);
        }
    }

    const readBarcode = (barcode: string) => {
        let scannedItems = productsAndServices.filter((item: any) => item.barcode === barcode);
        if (scannedItems.length > 0) {
            executeOnItemSelect(scannedItems[0]);
        }
    }

    const selectClient = (client: any) => {
        setClient(client);
        !clientDocument && setClientDocument(client?.document ?? '');
    }

    useEffect(() => {
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
            getProductsAndServices();
            getCompanyClients();
        }
    }, [props.modalActive]);

    useEffect(() => {
        let tempSteps = stepName;
        /* tempSteps[0].nextBtn = movementType == 1 ? 'Seleção de itens' : 'Dados da movimentação'; */
        tempSteps[0].nextBtn = 'Seleção de itens';
        setStepName([...tempSteps]);
        calculateTotalValue();
    }, [movementType]);

    useEffect(() => {
        calculateTotalValue();
    }, [selectedItems]);

    function keydownListener(e: any) {
        if (e.key === 'Escape') {
            props.setModalActive(false);
        }
        if (e.key == 'Enter') {
            changeStep('next');
        }
        /* arrows */
        if (e.key == 'ArrowRight') {
            if (step == 0) {
                setMovementType(movementType == 1 ? 0 : 1);
            }
            if (step == 3) {
                setPaymentMethod(paymentMethod > 6 ? 0 : paymentMethod + 1);
            }
        }
        if (e.key == 'ArrowLeft') {
            if (step == 0) {
                setMovementType(movementType == 1 ? 0 : 1);
            }
            if (step == 3) {
                setPaymentMethod(paymentMethod < 1 ? 7 : paymentMethod - 1);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', keydownListener);
        return () => {
            window.removeEventListener('keydown', keydownListener);
        }
    }, [
        step,
        printed,
        movementType,
        selectedItems,
        title,
        currency,
        totalValue,
        dueAt,
        paidAt,
        clientDocument,
        warrantyTime,
        serviceFee,
        client,
        printOption,
        forceUpdate,
        installments,
        paymentMethod
    ]);

    return {
        general,
        modalActive,
        step,
        setStep,
        stepName,
        changeStep,
        calculateStepPercentage,
        movementType,
        setMovementType,
        productsAndServices,
        executeOnItemSelect,
        selectedItems,
        deleteItem,
        changeItemQuantity,
        changeItemPrice,
        title,
        setTitle,
        currency,
        setCurrency,
        totalValue,
        setTotalValue,
        dueAt,
        setDueAt,
        paidAt,
        setPaidAt,
        executeOnCurrencySelect,
        createFinancialMovement,
        loading,
        loadingItems,
        readBarcode,
        companyClients,
        clientDocument,
        setClientDocument,
        warrantyTime,
        setWarrantyTime,
        serviceFee,
        setServiceFee,
        client,
        setClient,
        selectClient,
        PrintOptions,
        printOption,
        setPrintOption,
        keydownListener,
        setForceUpdate,
        installments,
        setInstallments,
        paymentMethod,
        setPaymentMethod
    };
}

export default useController;