import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultPropsStore } from '../../../utils/types';

const Account = (props: defaultPropsStore) => {
    const controller = useController();

    return <h1 style={{color: "white"}}>Store Account</h1>;
}

export default Account;