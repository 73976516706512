import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import Cards from 'react-credit-cards-2';

const EditCompanyPayment = (props: any) => {
    const controller = useController(props);

    return (
        <S.main active={controller.modalActive} animationOpacity={props.modalActive} onClick={() => props.setModalActive((active: boolean) => !active)}>
            <S.modal step={controller.step} onClick={(e) => e.stopPropagation()} animationInOut={props.modalActive}>
                <S.header>
                    <S.title>Editar empresa</S.title>
                    <S.close onClick={() => {
                        controller.setStep(0);
                        props.setModalActive((active: boolean) => !active)
                    }}>X</S.close>
                </S.header>
                <S.body>
                    <S.stepContainer>
                        {controller.step == 0 ?
                            <S.step active={controller.step == 0} mobileMod={true}>
                                <Cards
                                    number={controller.companyInfo.cc.number ?? ""}
                                    expiry={controller.companyInfo.cc.expiration ?? ""}
                                    cvc={controller.companyInfo.cc.cvc ?? ""}
                                    name={controller.companyInfo.cc.name ?? ""}
                                    focused={controller.companyInfo.cc.focus.split('.')[1] ?? ""}
                                    locale={{ valid: 'válido até' }}
                                    placeholders={{ name: 'NOME NO CARTÃO' }}
                                />
                                <S.inputsArea>
                                    <S.inputContainer>
                                        <S.inputLabel>Número do cartão:</S.inputLabel>
                                        <S.input onFocus={(e) => controller.handleCardChange(e)} onChange={(e) => controller.handleCardChange(e)} name='cc.number' value={controller.companyInfo.cc.number}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Nome no cartão:</S.inputLabel>
                                        <S.input onFocus={(e) => controller.handleCardChange(e)} onChange={(e) => controller.handleCardChange(e)} name='cc.name' value={controller.companyInfo.cc.name}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Data de validade:</S.inputLabel>
                                        <S.input onFocus={(e) => controller.handleCardChange(e)} onChange={(e) => controller.handleCardChange(e)} name='cc.expiration' value={controller.companyInfo.cc.expiration}></S.input>
                                    </S.inputContainer>
                                    <S.inputContainer>
                                        <S.inputLabel>Código de segurança (CVC):</S.inputLabel>
                                        <S.input onFocus={(e) => controller.handleCardChange(e)} onChange={(e) => controller.handleCardChange(e)} name='cc.cvc' value={controller.companyInfo.cc.cvc}></S.input>
                                    </S.inputContainer>
                                </S.inputsArea>
                            </S.step>
                            : null}
                    </S.stepContainer>
                    <S.saveBtn onClick={() => controller.updateInfo()}>
                        <controller.general.DefaultLoadingFit active={controller.loading} borderRadius={4} size={15} />
                        Salvar
                    </S.saveBtn>
                </S.body>
            </S.modal>
        </S.main>
    );
}

export default EditCompanyPayment;