import general from '../../../../utils/general';
import { useState, useRef } from 'react';
export const useController = (props: any) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const [expandedInfoModal, setExpandedInfoModal] = useState(false);

    const deleteResponseCard = (id: string) => {
        if(!id) {
            general.notify('Erro ao deletar resposta de formulário', 'error');
            return;
        }
        props.form && async function () {
            try {
                let newForm = props.form;
                newForm.answers = newForm.answers.filter((answer: any) => answer._id !== id);
                const response = await general.api.post('form/update/' + props.form._id, newForm);
                if (response.status === 200) {
                    if (response.data.status == 403) {
                        general.notify(response.data.message, 'error');
                        return;
                    }
                    general.notify('Resposta excluída com sucesso', 'success');
                    props.reloadItems && props.reloadItems(true);
                }
            }
            catch (err) {
                console.log(err);
                general.notify('Erro ao deletar resposta de formulário', 'error');
            }
        }()
        setDeleteModalActive(false);
    }

    return {
        general,
        isExpanded,
        deleteModalActive,
        expandedInfoModal,
        setIsExpanded,
        deleteResponseCard,
        setDeleteModalActive,
        setExpandedInfoModal
    };
}

export default useController;