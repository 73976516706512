import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import MenuComponent from '../../../components/app/MenuComponent';
import HeaderBarComponent from '../../../components/app/HeaderBarComponent';
import NewItemComponent from '../../../components/app/NewItemComponent';
import CardTeams from '../../../components/app/Cards/CardTeams';
import Pagination from '../../../components/general/Pagination';
import SelectWithSearch from '../../../components/app/Inputs/SelectWithSearch';


const CreateEditModal = (controller: any) => {
    const [addCollaboratorModal, setAddCollaboratorModal] = useState(false);
    return (
        <S.modalBackground>
            {controller.deleteTeamModalActive ? (
                <S.deleteTeamConfirmationModalWrapper>
                    <S.deleteTeamConfirmationModal>
                        <S.modalContentTitle style={{ textAlign: 'center' }}>Tem certeza que deseja excluir este time?</S.modalContentTitle>
                        <S.deleteTeamBtns>
                            <S.deleteTeamBtn onClick={() => {
                                controller.deleteTeam(controller.currentItem._id);
                                controller.setCreateModalActive(false);
                                controller.setEditModalActive(false);
                            }}>Sim</S.deleteTeamBtn>
                            <S.deleteTeamBtn onClick={() => controller.setDeleteTeamModalActive(false)}>Não</S.deleteTeamBtn>
                        </S.deleteTeamBtns>
                    </S.deleteTeamConfirmationModal>
                </S.deleteTeamConfirmationModalWrapper>
            ) : null}
            {addCollaboratorModal ? (
                <S.modalAddCollaborator>
                    <S.modalAddCollaboratorArea onSubmit={(e: any) => {
                        e.preventDefault();
                        controller.addCollaborator();
                        setAddCollaboratorModal(false);
                    }}>
                        <S.modalContentTitle style={{ textAlign: 'center' }}>Adicionar colaborador</S.modalContentTitle>
                        <SelectWithSearch
                            executeOnSelect={controller.setCollaboratorNameAndEmail}
                            clearOnClick={false}
                            defaultValue={controller.toAddCollaborator?.collaborator_name ?? ''}
                            options={[{
                                value: { name: controller.general.formatName(controller.general.getAccount().name), email: controller.general.getAccount().email },
                                name: 'Eu'
                            }, ...(controller.collaborators ?? []).filter((item: any) => !((controller.currentItem?.members ?? []).map((member: any) => member.collaborator_email) ?? []).includes(item._id)).map((item: any) => ({ value: item, name: controller.general.formatName(item.name) }))]}
                            placeholder='Selecione um colaborador'
                        />
                        <S.textInput
                            value={controller.toAddCollaborator?.collaborator_role ?? ''}
                            onChange={(e: any) => controller.setToAddCollaborator((collaborator: any) => ({ ...collaborator, collaborator_role: e.target.value }))}
                            placeholder='Cargo do colaborador'
                            autoComplete='disabled'
                        />
                        <S.collaboratorBtns>
                            <S.collaboratorSaveBtn type='submit'>Adicionar</S.collaboratorSaveBtn>
                            <S.collaboratorBtn onClick={() => {
                                controller.clearCollaboratorToAdd();
                                setAddCollaboratorModal(false);
                            }}>Cancelar</S.collaboratorBtn>
                        </S.collaboratorBtns>
                    </S.modalAddCollaboratorArea>
                </S.modalAddCollaborator>
            ) : null}
            <S.modalArea>
                <S.modalHeader>
                    <S.modalTitle>{controller.modalType == 'edit' ? 'Editar Time' : 'Criar Time'}</S.modalTitle>
                    <S.modalClose>
                        {controller.modalType == 'edit' && (
                            <S.deleteTeamIcon
                                title='Excluir Time'
                                onClick={() => {
                                    controller.setDeleteTeamModalActive(true);
                                }}
                                icon={controller.general.icons.faTrash}
                            />
                        )}
                        <S.closeIcon
                            title='Fechar'
                            onClick={() => {
                                controller.setCreateModalActive(false);
                                controller.setEditModalActive(false);
                            }} icon={controller.general.icons.faTimes} />
                    </S.modalClose>
                </S.modalHeader>
                <S.modalContent onSubmit={(e: any) => {
                    e.preventDefault();
                    controller.createEditTeam();
                }}>
                    <S.modalContentArea>
                        <S.modalContentTitle>Nome do time</S.modalContentTitle>
                        <S.textInput
                            value={controller.currentItem ? controller.currentItem?.name : ''}
                            onChange={(e: any) => controller.setCurrentItem({ ...controller.currentItem, name: e.target.value })}
                            placeholder='Nome do time'
                            autoComplete='disabled'
                        />
                    </S.modalContentArea>
                    <S.modalContentArea className='modalContentAreaFlexAll'>
                        <S.modalContentTitle>Colaboradores</S.modalContentTitle>
                        <S.collaboratorsArea>
                            {(controller.currentItem?.members ?? []).length == 0 ? (
                                <S.noCollaborators>
                                    <S.noCollaboratorsIcon icon={controller.general.icons.faUsers} />
                                    <S.noCollaboratorsText>Nenhum colaborador adicionado</S.noCollaboratorsText>
                                </S.noCollaborators>
                            ) : null}
                            {(controller.currentItem?.members ?? []).map((member: any, index: number) => {
                                return (
                                    <S.collaborator key={`collaborator_${index}`}>
                                        <S.collaboratorInfo>
                                            <S.collaboratorName>{member.collaborator_name ?? 'Não informado'}</S.collaboratorName>
                                            <S.collaboratorRole>{member.collaborator_role ?? 'Não informado'}</S.collaboratorRole>
                                        </S.collaboratorInfo>
                                        <S.collaboratorDelete onClick={() => controller.deleteTeamMember(member.collaborator_email)}>
                                            <S.deleteIcon icon={controller.general.icons.faTrash} />
                                        </S.collaboratorDelete>
                                    </S.collaborator>
                                );
                            })}
                        </S.collaboratorsArea>
                    </S.modalContentArea>
                    <S.modalContentArea>
                        <S.collaboratorBtns>
                            <S.collaboratorBtn onClick={() => setAddCollaboratorModal(true)}>Adicionar Colaborador</S.collaboratorBtn>
                            <S.collaboratorSaveBtn type='submit'>Salvar</S.collaboratorSaveBtn>
                        </S.collaboratorBtns>
                    </S.modalContentArea>
                </S.modalContent>
            </S.modalArea>
        </S.modalBackground>
    );
}

const Teams = (props: defaultProps) => {
    const controller = useController();

    if (controller.general.getCompany().plan_expire_at < controller.general.getCurrentDateTime()) {
        return (
            <S.main>
                <MenuComponent menuPreset={1} />
                <S.generalArea>
                    <HeaderBarComponent pageName='Times' />
                    <controller.general.PaymentMissing />
                </S.generalArea>
            </S.main>
        )
    }

    return (
        <S.main>
            {controller.createModalActive ? <CreateEditModal {...controller} /> : null}
            {controller.editModalActive ? <CreateEditModal {...controller} item={controller.currentItem ?? null} /> : null}
            <controller.general.DefaultLoading active={controller.loading} />
            <MenuComponent menuPreset={1} />
            <S.generalArea>
                <HeaderBarComponent pageName='Times' />
                {!controller.general.getCompany().plan.planModules.includes("module_teams") ? <controller.general.NoPermissionComponent permissionDeniedMessage={"O Seu plano não possui acesso a essa funcionalidade, por favor solicite um upgrade"} /> :
                    controller.permissionBlock ? <controller.general.NoPermissionComponent /> : (
                        <S.operationsArea>
                            <div className='content-cards-wrapper' onClick={() => {
                                controller.setModalType('create')
                                controller.setCurrentItem(null)
                                controller.setCreateModalActive(prev => !prev)
                            }}>
                                <NewItemComponent mainIcon='faUsers' pageItem='Time' />
                            </div>
                            {(controller.teams ?? []).map((item: any, index: number) => {
                                return (
                                    <div className='content-cards-wrapper' key={`team_${index}`} onClick={() => {
                                        controller.setModalType('edit')
                                        controller.setCurrentItem(item)
                                        controller.setEditModalActive(active => !active)
                                    }}>
                                        <CardTeams
                                            key={index}
                                            title={item.name}
                                            members={item.members}
                                        />
                                    </div>
                                );
                            })
                            }
                        </S.operationsArea>
                    )}
                {controller.totalPages > 1 ? <Pagination total_pages={controller.totalPages} page={controller.page} setPage={controller.setPage} /> : null}
            </S.generalArea>
        </S.main>
    )
}

export default Teams;