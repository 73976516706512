import React, { useEffect, useState } from 'react';
import * as S from './styles';
import general from '../../../../utils/general';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment';

const convertDayToNumber = (day: string): number => {
    switch (day) {
        case 'sunday':
            return 0;
        case 'monday':
            return 1;
        case 'tuesday':
            return 2;
        case 'wednesday':
            return 3;
        case 'thursday':
            return 4;
        case 'friday':
            return 5;
        case 'saturday':
            return 6;
        default:
            return -1;
    }
}

export const Calendar = (props: {
    name: string;
    placeholder?: string;
    required?: boolean;
    label: string;
    onChange: any;
    value?: any;
    calendarType: 'gregory' | 'hebrew' | 'islamic' | 'iso8601';
    minDate: Date;
    disabledDays: string[];
}) => {
    const [modalActive, setModalActive] = useState(false);

    return (
            <>
                <S.date>
                    <S.labelInput>{props.label}</S.labelInput>
                    <S.dateInputDiv onClick={() => setModalActive(active => !active)}>
                        <S.dateInput>{props.value ? moment(props.value).format('DD/MM/YYYY') : 'Clique para informar'}</S.dateInput>
                        <S.dateInputArrow icon={general.icons.faChevronDown} />
                    </S.dateInputDiv>
                </S.date>
                {modalActive && (
                    <S.main onClick={() => setModalActive(false)}>
                        <S.calendarModal onClick={(e: any) => e.stopPropagation()}>
                            <S.modalHeader>
                                <S.label>{props.label}</S.label>
                                <S.close onClick={() => setModalActive(false)}>X</S.close>
                            </S.modalHeader>
                            <S.calendar
                                calendarType={props.calendarType ?? 'gregory'}
                                minDate={props.minDate ?? new Date()}
                                tileDisabled={({ activeStartDate, date, view }) => (props.disabledDays.map((x: string) => convertDayToNumber(x)).includes(date.getDay()) && view === 'month')}
                                onChange={(e) => {
                                    props.onChange(e)
                                    setModalActive(false)
                                }}
                            />
                        </S.calendarModal>
                    </S.main>
                )}
            </>
    );
}

export default Calendar;