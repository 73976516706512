import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { addCompanyModalProps } from '../../../utils/types';
import { plan } from './types'

export const useController = (props: addCompanyModalProps) => {
    const [modalActive, setModalActive] = useState(false);
    const [step, setStep] = useState(0);
    const [currentLogo, setCurrentLogo] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [accountInfo, setAccountInfo] = useState({
        _id: JSON.parse(localStorage.getItem('account') as string)._id,
        logo: { name: "", type: "", size: "", result: "" },
        name: "",
        phone: "",
        birthdate: "",
        oldpass: "",
        newpass: "",
        avatarUrl: ""
    });

    const resetPage = () => {
        setStep(0);
        props.setModalActive(false);
    }

    const fieldFormat = (field: string, value: any) => {
        switch (field) {
            case 'phone':
                return general.phoneMask.customPhoneMask(value, '55');
            case 'birthdate':
                return general.customMask(value, '##/##/####');
            default:
                return value;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        setAccountInfo((prev) => ({ ...prev, [name]: fieldFormat(name, value) }));
    }

    const onlyImages = (file: File) => {
        if (file.type.includes('image')) return true;
        return false;
    }

    const handleInputFile = async (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            if (!onlyImages(file)) return alert('Arquivo inválido');
            setAccountInfo((prev) => ({ ...prev, logo: { name: file.name, type: file.type, size: file.size.toString(), result: reader.result as string } }));
            setCurrentLogo(reader.result as string);
        }
        let fileUrl = await reader.readAsDataURL(file);
    }

    const getStoredAccount = () => {
        const account = localStorage.getItem('account');
        if (account) {
            setAccountInfo({
                ...accountInfo,
                name: JSON.parse(account).name ?? '',
                phone: JSON.parse(account).phone ?? '',
                birthdate: JSON.parse(account).birthdate?.split("T")[0].split("-").reverse().join("/") ?? '',
                avatarUrl: JSON.parse(account).avatarUrl ?? '',
                oldpass: "",
                newpass: "",
            });
        }
    }

    const validateFields = () => {
        if (accountInfo.newpass && !accountInfo.oldpass) {
            props.setModalActive(false);
            general.notify('Você digitou uma nova senha porém não digitou a senha antiga', 'error')
            return false
        };
        if (accountInfo.oldpass && !accountInfo.newpass) {
            props.setModalActive(false);
            general.notify('Você digitou a senha antiga porém não digitou a nova senha', 'error')
            return false
        };
        if (accountInfo.name.length < 3){
            props.setModalActive(false);
            general.notify('Nome inválido', 'error')
            return false
        };
        return true;
    }

    const updateInfo = async () => {
        if(!validateFields()) return;
        setLoading(true);
        await general.api.put(`/account/update/${accountInfo._id}`, {
            ...accountInfo, birthdate: accountInfo.birthdate ?
                accountInfo.birthdate.split('/').reverse().join('-') : null
        }).then((response) => {
            general.notify(response.data.message, 'success');
            localStorage.setItem('account', JSON.stringify(response.data.data));
            getStoredAccount();
            resetPage();
            setLoading(false);
        }).catch((err) => {
            general.notify(err.response.data.message, 'error');
            setLoading(false);
        });
    }

    useEffect(() => {
        getStoredAccount();
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
        }
    }, [props.modalActive]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })
    }, []);

    return {
        general,
        modalActive,
        step,
        setStep,
        accountInfo,
        setAccountInfo,
        handleInputChange,
        handleInputFile,
        updateInfo,
        currentLogo,
        loading
    };
}

export default useController;