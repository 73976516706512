import { useState, useEffect } from 'react';
import general from '../../../utils/general';
import moment from 'moment';

export const useController = () => {
    const [events, setEvents] = useState<any[]>([]);
    const [holidays, setHolidays] = useState<any[]>([]);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [editModalActive, setEditModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [currentMonth, setCurrentMonth] = useState<any>(new Date().getMonth() + 1);
    const [currentYear, setCurrentYear] = useState<any>(moment().format('YYYY'));
    const [eventsToShowMore, setEventsToShowMore] = useState<any>([]);
    const [showMoreEventsModal, setShowMoreEventsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const eventCreateTemplate = {
        title: 'Novo evento',
        subtitle: 'Crie um novo evento',
        infos: [
            {
                key: 'title',
                name: 'Nome',
                type: "text",
                required: true,
                value: '',
                disabled: true
            },
            {
                key: 'description',
                name: 'Descrição',
                type: "text",
                required: false,
                value: '',
                disabled: true
            },
            {
                key: 'start',
                name: 'Início',
                type: "datetime",
                required: true,
                value: '',
                disabled: true
            },
            {
                key: 'end',
                name: 'Fim',
                type: "datetime",
                required: true,
                value: '',
                disabled: true
            },
            {
                key: 'company',
                name: 'Evento para toda empresa',
                type: "checkbox",
                value: '',
                disabled: true
            },
            {
                key: 'status',
                name: 'Status do evento',
                type: "select",
                required: true,
                value: '',
                disabled: true,
                selectOptions: [
                    {
                        name: 'Aceito',
                        value: '1'
                    },
                    {
                        name: 'Recusado',
                        value: '2'
                    },
                    {
                        name: 'Pendente',
                        value: '3'
                    },
                    {
                        name: 'Cancelado',
                        value: '4'
                    },
                    {
                        name: 'Concluído',
                        value: '5'
                    }
                ]
            },
        ]
    }

    const getEvents = async (searchMonth: any, showLoader?: boolean) => {
        try{
            showLoader == true && setLoading(true);
            const response = await general.api.post('event/list', {
                searchMonth
            });
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setEvents(response.data.data.map((event: any) => {
                    return {
                        ...event,
                        start: new Date(new Date(event.start).getTime() + 60 * 60 * 3 * 1000),
                        end: new Date(new Date(event.end).getTime() + 60 * 60 * 3 * 1000)
                    }
                }));
                getHolidays();
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar eventos', 'error');
            setLoading(false);
        }
    }

    const getHolidays = async () => {
        try {
            const response = await general.api.get(`https://brasilapi.com.br/api/feriados/v1/${currentYear}`);
            if (response.status === 200) {
                let holidays = response.data;
                let holidaysBuild: any[] = [];
                holidays.forEach((holiday: any) => {
                    if(holidaysBuild.find((h) => h.title === (holiday?.name + ' [Feriado]'))) return;
                    
                    holidaysBuild.push({
                        title: holiday?.name + ' [Feriado]',
                        description: 'Feriado - ' + holiday?.name,
                        start: moment(holiday?.date).toDate(),
                        end: moment(holiday?.date).toDate(),
                        company: false,
                        status: 1
                    });
                });
                
                
                setHolidays(holidaysBuild);
            }
        }
        catch (err) {
            console.log(err);
        }
    }


    useEffect(() => {
        getEvents(currentMonth, true);
    }, []);

    useEffect(() => {
        getEvents(currentMonth, false);
    }, [createModalActive, editModalActive]);

    useEffect(() => {
        getEvents(currentMonth, true);
    }, [currentMonth]);

    return {
        general,
        events,
        setEvents,
        holidays,
        setHolidays,
        createModalActive,
        setCreateModalActive,
        editModalActive,
        setEditModalActive,
        currentItem,
        setCurrentItem,
        eventCreateTemplate,
        setCurrentMonth,
        currentMonth,
        eventsToShowMore,
        setEventsToShowMore,
        showMoreEventsModal,
        setShowMoreEventsModal,
        loading,
        permissionBlock,
        currentYear,
        setCurrentYear
    }
}

export default useController;