import styled, { css, keyframes } from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import general from '../../../utils/general';
import useController from './controller';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div<{ active: boolean, animationOpacity: boolean }>`
    display: flex;
    width: ${props => props.active ? '100vw' : '0px'};
    height: ${props => props.active ? '100vh' : '0px'};
    opacity: ${props => props.animationOpacity ? 1 : 0};
    transition-duration: 0.3s;
    transition-property: opacity;
    position: fixed;
    background-color: rgba(0,0,0,0.75);
    z-index: 999;
    justify-content: center;
    align-items: center;
`;
export const modal = styled.div<{ animationInOut: boolean }>`
    width: 75%;
    height: 75%;
    display: flex;
    flex-direction: row;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 20px 5px;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    z-index: 99999999;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    opacity: ${props => props.animationInOut ? 1 : .5};
    transform: ${props => props.animationInOut ? 'translateY(0px)' : 'translateY(100px)'};
    transition-duration: 0.3s;

    @media(max-width: 1200px){
        width: 85%;
        margin-top: -5%;
    }
    @media(max-width: 800px){
        margin-top: -10%;
    }
    @media(max-width: 500px){
        max-height: 68%;
        margin-top: -20%;
        padding-top: 5px;
        flex-direction: column;
    }
`;
export const closeModal = styled(FontAwesomeIcon)`
    position: absolute;
    font-size: 20px;
    color: ${colors.red};
`;
export const confirmDeleteModal = styled.div<{ active: boolean }>`
    display: ${props => props.active ? 'flex' : 'none'};
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 999999999999;
`;
export const confirmDeleteModalContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 20px;
    gap: 10px;
`;
export const confirmDeleteModalTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
`;
export const confirmDeleteModalText = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    text-align: center;
`;
export const confirmDeleteModalButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
`;
export const confirmDeleteModalButton = styled.button`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${colors.backgroundWhite};
    border: 1px solid ${colors.black};
    cursor: pointer;

    :hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }
`;
export const incrementStockModal = styled.div<{ active: boolean }>`
    display: ${props => props.active ? 'flex' : 'none'};
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    justify-content: center;
    align-items: center;
    z-index: 999;
`;
export const incrementStockModalContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 20px;
    gap: 10px;
`;
export const incrementStockModalTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
`;
export const incrementStockModalInput = styled.input`
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid ${colors.grey};
    outline: none;
`;
export const incrementStockModalButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    justify-content: center;
`;
export const incrementStockModalButton = styled.button`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
    padding: 10px 30px;
    border-radius: 5px;
    background-color: ${colors.backgroundWhite};
    border: 1px solid ${colors.black};
    cursor: pointer;

    :hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }
`;

export const container = styled.div<{ showItem?: string }>`
    padding: 25px 20px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    place-items: center;

    :nth-child(1){
        width: 15%;
        border-right: 1px solid ${colors.grey};
    }

    :nth-child(3){
        width: 50%;
        border-right: 1px solid ${colors.grey};
    }

    :nth-child(4){
        width: 35%;
        border-left: ${props => props.showItem == 'comments' ? 'none' : '1px solid ' + colors.grey};
        overflow-y: auto;
    }
    
    ${props => props.showItem == 'form' && css`
        :nth-child(3){
            display: none;
        }
    `}

    @media(max-width: 800px){
        display: none;
        :nth-child(1){
            width: 20%;
            display: flex;
        }

        :nth-child(3){
            width: 99%;
        }

        :nth-child(4){
            width: 80%;
        }


        :nth-child(${props => props.showItem == 'comments' ? 4 : (props.showItem == 'products' ? 3 : 2)}){
            display: flex;
        }
    }

    @media(max-width: 500px){
        :nth-child(1){
            flex-direction: row;
            width: 100%;
            height: 20%;
            border-right: none;
            border-bottom: 1px solid ${colors.grey};

            h2 {
                display: none;
            }
        }

        :nth-child(3){
            width: 97%;
            height: 70%;
            padding: 10px 0 0 0;
            border-left: none;
            margin-left: 1.5%;
            border-right: none;

            h2 {
                margin: 10px 0 0 0;
            }
        }

        :nth-child(4){
            width: 90%;
            height: 100%;
        }
    }
`;
export const containerForm = styled.form<{ showItem?: string }>`
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    width: 50%;
    max-height: 100%;
    overflow-y: auto;

    ${props => props.showItem != 'form' && css`
        display: none;
    `}

    @media(max-width: 800px){
        ${props => props.showItem != 'comments' && css`
            width: 80%;
        `}
        ${props => props.showItem != 'form' && css`
            display: none;
        `}
    }

    @media(max-width: 500px){
        width: 90%;
        height: 70%;
        padding: 0 15px;
        flex: 1;
    }
`;
export const title = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
    text-transform: uppercase;

    @media(max-width: 500px){
        margin: 20px 0;
    }
`;
export const allFormInputs = styled.div`
    display: grid;
    width: 100%;
    max-height: 75%;
    overflow-y: auto;
    gap: 10px;
    padding: 0 0 10px 0;
    place-items: center;

    @media(max-width: 1366px){
        max-height: 70%;
    }
    @media(max-width: 500px){
        max-height: 280px;
        margin-bottom: 5px;
        width: 100%;
        place-items: start;
    }
`;
export const inputContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
    background-color: ${colors.backgroundWhite};
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    border: 1px solid ${colors.grey};

    :focus-within{
        transform: scale(1.05);
    }

    @media(max-width: 1366px){
        width: 96%;
    }

    @media(max-width: 500px){
        width: 94%;
        align-self: center;
        justify-self: center;
        :focus-within{
            transform: scale(1);
        }
    }
`;
export const input = styled.input<{ width?: number }>`
    display: flex;
    height: 20px;
    width: ${props => props.width ? props.width + 'ch' : 'auto'};
    border: none;
    outline: none;
    background-color: transparent;
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 5px;
    color: ${props => props.disabled ? colors.grey : colors.black};

    :nth-child(1){
        font-weight: 600;
    }
    :nth-child(2){
        flex: 1;
        text-align: end;
        width: 100%;
    }
`;
export const avatar = styled.img``;
export const defaultAvatar = styled(Avatar)`
    transform: scale(1);
    @media(max-width: 1366px){
        transform: scale(0.9);
    }
`;
export const options = styled.div`
    overflow-y: auto;
    flex: 1;

    @media(max-width: 500px){
        height: 80%;
    }
`;
export const uniqueOption = styled.p<{ mobileOption?: boolean, pcOption?: boolean, color: string }>`
    width: 150px;
    min-height: 35px;
    display: ${props => props.mobileOption ? 'none' : 'flex'};
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
    transition-duration: 0.3s;
    transition-property: transform;
    font-family: ${fonts.main};
    font-weight: 800;
    font-size: 14px;
    color: ${props => props.color};
    text-align: center;

    :hover{
        text-decoration: underline;
    }

    @media(max-width: 800px){
        ${props => props.mobileOption && css`
            display: flex;
        `}
        ${props => props.pcOption && css`
            display: none;
        `}
    }
    @media(max-width: 1366px){
        min-height: 25px;
    }
`;
export const button = styled.button`
    width: 80%;
    height: 35px;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid ${colors.black};
    cursor: pointer;
    color: ${colors.black};
    align-self: center;
    margin-top: 10px;
    font-family: ${fonts.main};
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;

    :hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }

    @media(max-width: 1366px){
        width: 95%;
        height: 40px;
    }
    
    @media(max-width: 500px){
        width: 96%;
        height: 30px;
    }
`;

export const buttonAddField = styled.div`
    width: 80%;
    height: 35px;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid ${colors.black};
    cursor: pointer;
    color: ${colors.black};
    align-self: center;
    margin-top: 10px;
    font-family: ${fonts.main};
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;

    :hover{
        background-color: ${colors.black};
        color: ${colors.backgroundWhite};
    }

    @media(max-width: 1366px){
        width: 95%;
        height: 40px;
    }
    
    @media(max-width: 500px){
        width: 96%;
        height: 30px;
    }
`;
export const commentSection = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    @media(max-width: 500px){
        max-height: 450px;
    }
`;
export const allComments = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    gap: 5px;
    overflow-y: auto;
    align-items: center;
    margin: 5px 0;
`;
export const comment = styled.div`
    display: flex;
    width: 88%;
    flex-direction: column;
    margin-bottom: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    background-color: ${colors.backgroundWhite};
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.25);
    gap: 10px;
    position: relative;
    z-index: 9999999;
    font-family: ${fonts.main};
    font-size: 14px;
    color: ${colors.black};
    text-align: justify;

    :nth-last-child(1){
        margin-top: 5px;
    }

    @media(max-width: 1500px){
        width: 81%;
        font-size: 14px;
    }

    @media(max-width: 900px){
        width: 79%;
        font-size: 12px;
    }

    @media(max-width: 800px){
        width: 82%;
        font-size: 12px;
    }

    @media(max-width: 500px){
        width: 82%;
    }
`;
export const commentHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${colors.grey};
    gap: 10px;
`;
export const commentAvatar = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
`;
export const commentItemInfo = styled.div`
    flex: 1;
`;
export const commentName = styled.p`
    font-weight: 600;
`;
export const commentDate = styled.p`
    font-size: 12px;
    font-weight: 400;

    @media(max-width: 1200px){
        font-size: 11px;
    }
`;
export const commentContent = styled.p`
    margin-bottom: 5px;
    width: 100%;
    white-space: pre-wrap;

    @media(max-width: 1200px){
        font-size: 12px;
    }
`;
export const sendCommentSection = styled.form`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 99.5%;
    align-self: center;

    @media(max-width: 800px){
        width: 98.2%;
    }

    @media(max-width: 500px){
        margin-top: 10px;
    }
`;
export const commentToSendInput = styled.textarea`
    resize: none;
    flex: 1;
    border: none;
    outline: none;
    background-color: transparent;
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 5px;
    color: ${colors.black};
    border-radius: 5px;
    border: 1px solid ${colors.grey};
    height: 60px;

    :focus{
        border: 1px solid ${colors.priceGreen};
    }
`;
export const commentSendButton = styled(general.FontAwesomeIcon)`
    border-radius: 5px;
    margin-left: 7px;
    width: 20px;
    height: 52px;
    padding: 10px;
    background-color: ${colors.priceGreen};
    color: ${colors.backgroundWhite};
    cursor: pointer;

    :hover{
        background-color: ${colors.priceGreenHover};
    }
`;
export const noComments = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
    height: 100%;
`;
export const noCommentsIcon = styled(general.FontAwesomeIcon)`
    font-size: 50px;
    color: ${colors.grey};
`;
export const noCommentsText = styled.p`
    font-family: ${fonts.main};
    font-size: 16px;
    color: ${colors.grey};
`;
export const inputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin: 10px 0;
    justify-content: center;
    align-items: center;

    > div > input {
        width: 100%;
        align-self: center;
    }

    > div > label {
        width: 100%;
        align-self: center;
    }

    > div {
        width: 95%;
    }

    .selectWithSearchCurrency{
        width: calc(100% + 10px);
        
        > div > div {
            width: calc(100% - 10px);
            margin: 0 0 0 -5px;
        }
    }
`;
export const itemInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
`;
export const itemInputLabel = styled.label`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
`;
export const itemInputField = styled.input`
    width: 80%;
    height: 30px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    padding: 5px;
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    outline: none;
`;
export const itemInputSelect = styled.select`
    width: calc(100% + 10px);
    height: 45px;
    border: 1px solid ${colors.grey};
    border-radius: 5px;
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 5px;
    font-weight: 400;
    outline: none;
    margin: 0 0 0 -5px;
    background-color: ${colors.white};
`;
export const itemInputOption = styled.option`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
`;
export const movementItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 250px;
    margin: 10px 0;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    overflow-y: auto;
`;
export const movementItem = styled.div`
    display: flex;
    flex-direction: row;
    width: 95%;
    margin: 10px 0;
    padding: 10px;
    border: 1px solid ${colors.grey};
    border-radius: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    @media(max-width: 800px){
        width: 90%;
    }
`;
export const itemImage = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 10px;

    @media(max-width: 800px){
        width: 90px;
        height: 90px;
    }
`;
export const itemContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    gap: 10px;

    @media(max-width: 800px){
        flex-direction: column;
        width: 100%;
    }
`;
export const itemInfos = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0 10px;
    flex: 1;
    justify-content: center;

    @media(max-width: 800px){
        gap: 5px;
        padding: 0;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
    }
`;
export const itemTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: justify;

    @media(max-width: 1200px){
        font-size: 16px;
    }
    @media(max-width: 800px){
        padding-right: 10px;
        font-size: 14px;
    }
`;
export const itemDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    text-align: justify;

    @media(max-width: 800px){
        display: none;
    }
`;
export const itemInputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    @media(max-width: 800px){
        flex-direction: row;
        width: 100%;
    }
`;
export const deleteItemIcon = styled(general.FontAwesomeIcon)`
    font-size: 30px;
    margin: 5px 15px 0 0;
    color: ${colors.grey};
    cursor: pointer;
    transition-duration: 0.15s;

    &:hover{
        transform: scale(1.2);
        color: ${colors.red};
    }

    @media(max-width: 800px){
        display: none;
    }
`;

export const deleteItemIconMobile = styled(general.FontAwesomeIcon)`
    font-size: 20px;
    color: ${colors.grey};
    cursor: pointer;
    transition-duration: 0.15s;
    margin-right: 10px;
    align-self: center;

    &:hover{
        transform: scale(1.2);
        color: ${colors.red};
    }

    @media(min-width: 800px){
        display: none;
    }
`;

export const noItemsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

export const noItemsIcon = styled(general.FontAwesomeIcon)`
    font-size: 40px;
    color: ${colors.grey};
`;

export const noItemsTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
`;

export const noItemsDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 400;
    text-align: center;
`;