import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';

const DefaultLoading = (props: {active: boolean}) => {
    const controller = useController();

    return props.active && props.active == true ? (
        <S.main>
            <S.loadingContainer>
                <S.imgBit src={'/app/images/logo/logo_arezdot_glow_only.png'} className='logo_arezdot_glow'/>
                <S.imgBit src={'/app/images/logo/logo_arezdot_draw_only.png'} className='logo_arezdot_draw'/>
                <S.imgBit src={'/app/images/logo/logo_arezdot_name_only.png'} className='logo_arezdot_name'/>
            </S.loadingContainer>
        </S.main>
    ) : null;
}

export default DefaultLoading;