import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import useController from './controller';

export const main = styled.div<{ deleteActive?: boolean, expandedActive?: boolean }>`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 330px;
    height: 360px;
    background-color: ${colors.black};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    ${(props) => props.deleteActive ? `
        box-shadow: 0px 0px 10px 0px ${colors.pureRed};
    ` : ''}

    ${props => !props.expandedActive ? `
        &:hover {
            transform: ${props.deleteActive ? 'scale(1)' : 'scale(1.05)'};
        }
    `: `
        transition-duration: 0s;
    `}

    @media (max-width: 1366px) {
        width: 230px;
        height: 280px;
    }

    @media (max-width: 900px) {
        width: 280px;
    }

    @media (max-width: 700px) {
        width: 100%;
        flex-direction: row;
        height: 360px;
    }
`;
/* delete confirmation modal */
const opacityAnimation = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;
export const deleteAnswerModal = styled.div`
    display: ${(props: { active: boolean }) => props.active ? 'flex' : 'none'};
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    border-radius: 10px;
    animation: ${opacityAnimation} 0.3s ease forwards;
`;
const popInAnimation = keyframes`
    0% {
        transform: scale(0);
    }
    25% {
        transform: scale(1.05);
    }
    50% {
        transform: scale(0.95);
    }
    75% {
        transform: scale(1.025);
    }
    100% {
        transform: scale(1);
    }
`;
export const deleteAnswerModalContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75%;
    height: auto;
    background-color: ${colors.white};
    border-radius: 10px;
    padding: 20px;
    animation: ${popInAnimation} 0.5s ease forwards;
`;
export const deleteAnswerModalTitle = styled.div`
    font-family: ${fonts.main};
    font-size: 18px;
    color: ${colors.black};
    margin-bottom: 10px;
    text-align: center;

    @media (max-width: 1200px) {
        font-size: 16px;
    }
`;
export const deleteAnswerModalButtons = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;
export const deleteAnswerModalButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45%;
    height: 40px;
    background-color: ${(props: { warning?: boolean }) => props.warning ? colors.lightRed : colors.lightBlue};
    color: ${colors.white};
    font-family: ${fonts.main};
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
        background-color: ${(props: { warning?: boolean }) => props.warning ? colors.pureRed : colors.linkBlue};
    }

    @media (max-width: 1200px) {
        font-size: 14px;
    }
`;

/* Expanded Info Modal */

export const expandedInfoModal = styled.div<{ active: boolean }>`
    display: ${props => props.active ? 'flex' : 'none'};
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
`;
export const expandedInfoModalContent = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    max-width: 50%;
    padding: 20px;
    gap: 10px;
    animation: ${popInAnimation} 0.5s ease forwards;

    @media(max-width: 1200px){
        max-width: 75%;
        margin-top: -8%;
    }

    @media(max-width: 800px){
        margin-top: -20%;
    }
`;
export const expandedInfoFields = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 500px;
    overflow-y: auto;
    gap: 10px;

    @media(max-width: 1200px){
        max-height: 400px;
    }

    @media(max-width: 800px){
        max-height: 500px;
    }
`;
export const expandedInfoModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
    border-bottom: 1px solid ${colors.grey};
    padding: 5px 0 10px 0;
    margin-bottom: 10px;
`;
export const expandedInfoModalClose = styled(FontAwesomeIcon)`
    font-size: 25px;
    color: ${colors.black};
    cursor: pointer;
    margin-top: -3px;
    transition-duration: 0.1s;

    :hover{
        color: ${colors.pureRed};
    }
`;
export const expandedInfoModalCopyIcon = styled(FontAwesomeIcon)`
    font-size: 14px;
    color: ${colors.darkerGrey};
    cursor: pointer;
    transition-duration: 0.1s;

    :hover{
        color: ${colors.black};
    }
`;
export const expandedInfoModalTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
`;
export const expandedInfoModalField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;
export const expandedInfoModalFieldTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
    font-weight: 600;
`;
export const expandedInfoModalFieldText = styled.p`
    font-family: ${fonts.main};
    font-size: 14px;
`;

export const card = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    
`;
export const cardData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
`;
export const itemName = styled.div`
    font-family: ${fonts.main};
    font-size: 20px;
    color: ${colors.darkeredGrey};
    margin: 5px 0 0 0;

    @media (max-width: 1366px) {
        font-size: 18px;
    }
`;
export const itemRole = styled.div`
    font-family: ${fonts.main};
    font-size: 15px;
    color: ${colors.darkerGrey};
    margin-bottom: 5px;

    @media (max-width: 1366px) {
        font-size: 13px;
    }
`;
export const deleteResponseCardIcon = styled(FontAwesomeIcon)`
    color: ${colors.darkGrey};
    cursor: pointer;
    transition-duration: 0.1s;
    margin-left: 15px;

    &:hover {
        color: ${colors.red};
    }
`;
export const ExpandInfoResponseCardIcon = styled(FontAwesomeIcon)`
    color: ${colors.darkGrey};
    cursor: pointer;
    transition-duration: 0.1s;
    margin-left: 15px;

    &:hover {
        color: ${colors.linkBlue};
    }
`;
export const cardInfo = styled.div`
    display: flex;
    height: 100%;
    width: calc(100% - 20px);
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 0 10px 0 10px;
    margin: 5px 0 5px 0;

    @media (max-width: 900px) {
        height: 100%;
    }
`;
export const itemInfo = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid ${colors.grey};
`;
export const itemInfoName = styled.div`
    width: 30%;
    text-align: left;
    font: 600 12px ${fonts.main};
    color: ${colors.black};
`;
export const itemInfoValue = styled.div`
    width: 60%;
    margin-left: 10%;
    direction: rtl;
    text-align: right;
    font: 400 12px ${fonts.main};

    @media (max-width: 1366px) {
        width: 50%;
        margin-left: 20%;
    }
    @media (max-width: 900px) {
        width: 60%;
    }
    
    span {
        direction: ltr;
        unicode-bidi: embed;
    }
`;
export const noInfo = styled.p`
    font: 400 16px ${fonts.main};
    text-transform: uppercase;
    text-align: center;
    color: ${colors.black};
    margin-top: 10px;
    width: 70%;
    align-self: center;
    margin-top: 60px;

    @media (max-width: 1366px) {
        margin-top: 20px;
        font: 400 14px ${fonts.main};
    }

    @media (max-width: 900px) {
        margin-top: 60px;
        font: 400 16px ${fonts.main};
    }
`;