import React, { useEffect, useState } from 'react';
import * as S from './styles';
import general from '../../../../utils/general';

export const StyledCheckbox = (props: {
    name: string;
    checked?: boolean;
    required?: boolean;
    label: string;
    onChange: any;
}) => {
    return (
        <S.wrapper onClick={() => props.onChange(!props.checked)} active={props.checked ?? false}>
            <S.checkboxIcon active={props.checked ?? false} icon={props.checked ? general.icons.faCheck : general.icons.faTimes} onClick={() => props.onChange(!props.checked)} />
            <S.checkboxLabel active={props.checked ?? false}>{props.label}</S.checkboxLabel>
        </S.wrapper>
    );
}

export default StyledCheckbox;