import general from '../../../utils/general';
import { useState, useEffect } from 'react';
import moment from 'moment';

export const useController = () => {
    const months = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
    const monthsAbrev = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    const [innerWidth, setInnerWidth] = useState(window.innerWidth);
    const [financialPermissionBlock, setFinancialPermissionBlock] = useState(false);
    const [eventsPermissionBlock, setEventsPermissionBlock] = useState(false);
    const [stockPermissionBlock, setStockPermissionBlock] = useState(false);
    const [loaders, setLoaders] = useState({
        financialMovements: true,
        nextExpenses: true,
        nextEvents: true,
        stock: true,
        collaboratorsRanking: true
    });
    const [infoBits, setInfoBits] = useState([
        {
            slug: 'anualBilling',
            title: 'Faturamento anual',
            sections: [
                {
                    title: moment().format('YYYY'),
                    content: 'R$ 0,00'
                },
                {
                    title: moment().subtract(1, 'year').format('YYYY'),
                    content: 'R$ 0,00'
                }
            ]
        },
        {
            slug: 'monthlyBilling',
            title: 'Faturamento mensal',
            sections: [
                {
                    title: months[moment().month()],
                    content: 'R$ 0,00'
                },
                {
                    title: months[moment().subtract(1, 'month').month()],
                    content: 'R$ 0,00'
                }
            ]
        },
        {
            slug: 'annualExpenses',
            title: 'Despesas anuais',
            sections: [
                {
                    title: moment().format('YYYY'),
                    content: 'R$ 0,00'
                },
                {
                    title: moment().subtract(1, 'year').format('YYYY'),
                    content: 'R$ 0,00'
                }
            ]
        },
        {
            slug: 'monthlyExpenses',
            title: 'Despesas mensais',
            sections: [
                {
                    title: months[moment().month()],
                    content: 'R$ 0,00'
                },
                {
                    title: months[moment().subtract(1, 'month').month()],
                    content: 'R$ 0,00'
                }
            ]
        },
        {
            slug: 'soldProducts',
            title: 'Produtos vendidos',
            sections: [
                {
                    title: months[moment().month()],
                    content: '0'
                },
                {
                    title: months[moment().subtract(1, 'month').month()],
                    content: '0'
                }
            ]
        },
        {
            slug: 'servicesDone',
            title: 'Serviços realizados',
            sections: [
                {
                    title: months[moment().month()],
                    content: '0'
                },
                {
                    title: months[moment().subtract(1, 'month').month()],
                    content: '0'
                }
            ]
        }
    ]);
    const [billingDataset, setBillingDataset] = useState();
    const [profitDivisionDataset, setProfitDivisionDataset] = useState();
    const [billingSetExtra, setBillingSetExtra] = useState<any>();

    const [nextExpenses, setNextExpenses] = useState([])
    const [nextEvents, setNextEvents] = useState([])
    const [stock, setStock] = useState([])
    const [collaboratorsRanking, setCollaboratorsRanking] = useState([])

    const buildBillingDataset = (monthlyRevenue: any) => {
        let dataset: any = []

        for(let i = 0; i < 12; i++) {
            dataset.push({
                month: monthsAbrev[i],
                [monthlyRevenue.currentYear[0].year]: monthlyRevenue.currentYear[i].moneyIn / 100,
                [monthlyRevenue.lastYear[0].year]: monthlyRevenue.lastYear[i].moneyIn / 100
            })
        }

        const valueFormatter = (value: number) => `R$ ${general.formatMoney(value.toString())}`

        setBillingSetExtra([
            { dataKey: monthlyRevenue.currentYear[0].year.toString(), label: monthlyRevenue.currentYear[0].year.toString(), valueFormatter },
            { dataKey: monthlyRevenue.lastYear[0].year.toString(), label: monthlyRevenue.lastYear[0].year.toString(), valueFormatter }
        ])
        setBillingDataset(dataset)
    }

    const buildProfitDivisionDataset = (annualBalance: any) => {
        let dataset: any = []
        let items: any = annualBalance.currentYear.productsProfitByCategory.concat(annualBalance.currentYear.servicesProfitByCategory)

        items.map((item: any, index: number) => {
            dataset.push({
                id: index,
                value: (item.profit / 100).toFixed(2),
                label: item.category,
                color: general.makeRandomHexColor()
            })
        })

        setProfitDivisionDataset(dataset)
    }

    const getAnnualBalance = (financialMovements: any) => {
        let currentYear: any = {
            moneyIn: 0,
            moneyOut: 0,
            productsProfitByCategory: [],
            servicesProfitByCategory: []
        }
        let lastYear = {
            moneyIn: 0,
            moneyOut: 0
        }

        financialMovements.map((movement: any) => {
            if (movement.type === 1) {
                if (moment(movement.created_at).year() === moment().year()) {
                    currentYear.moneyIn += movement.total_value

                    if(movement.products.length > 0) {
                        movement.products.map((item: any) => {
                            let categoryIndex = currentYear.productsProfitByCategory.findIndex((category: any) => category.category === item.product.category_name)
                            if (categoryIndex === -1) {
                                currentYear.productsProfitByCategory.push({
                                    category: item.product.category_name,
                                    profit: item.value * item.quantity
                                })
                            } else {
                                currentYear.productsProfitByCategory[categoryIndex].profit += item.value * item.quantity
                            }
                        })
                    }

                    if(movement.services.length > 0) {
                        movement.services.map((item: any) => {
                            let categoryIndex = currentYear.servicesProfitByCategory.findIndex((category: any) => category.category === item.service.category_name)
                            if (categoryIndex === -1) {
                                currentYear.servicesProfitByCategory.push({
                                    category: item.service.category_name,
                                    profit: item.value * item.quantity
                                })
                            } else {
                                currentYear.servicesProfitByCategory[categoryIndex].profit += item.value * item.quantity
                            }
                        })
                    }
                } else {
                    lastYear.moneyIn += movement.total_value
                }
            } else {
                if (moment(movement.created_at).year() === moment().year()) {
                    currentYear.moneyOut += movement.total_value
                } else {
                    lastYear.moneyOut += movement.total_value
                }
            }
        })

        return {
            currentYear: {
                moneyIn: currentYear.moneyIn,
                moneyOut: currentYear.moneyOut,
                productsProfitByCategory: currentYear.productsProfitByCategory,
                servicesProfitByCategory: currentYear.servicesProfitByCategory,
                profit: currentYear.moneyIn - currentYear.moneyOut
            },
            lastYear: {
                moneyIn: lastYear.moneyIn,
                moneyOut: lastYear.moneyOut,
                profit: lastYear.moneyIn - lastYear.moneyOut
            }
        }
    }

    const getMonthlyBalance = (financialMovements: any) => {
        let monthsBalance: any = {
            currentYear: [],
            lastYear: []
        }

        for (let i = 0; i < 12; i++) {
            monthsBalance.currentYear.push({
                month: months[i],
                year: moment().year(),
                monthIndex: i,
                moneyIn: 0,
                moneyOut: 0,
                productsSold: 0,
                servicesDone: 0
            })
            monthsBalance.lastYear.push({
                month: months[i],
                year: moment().subtract(1, 'year').year(),
                monthIndex: i,
                moneyIn: 0,
                moneyOut: 0,
                productsSold: 0,
                servicesDone: 0
            })
        }

        financialMovements.map((movement: any) => {
            if (movement.type === 1) {
                if (moment(movement.createdAt).year() === moment().year()) {
                    monthsBalance.currentYear[moment(movement.createdAt).month()].moneyIn += movement.total_value
                } else {
                    monthsBalance.lastYear[moment(movement.createdAt).month()].moneyIn += movement.total_value
                }

                if(movement.products.length > 0) {
                    movement.products.map((product: any) => {
                        if (moment(movement.createdAt).year() === moment().year()) {
                            monthsBalance.currentYear[moment(movement.createdAt).month()].productsSold += product.quantity
                        } else {
                            monthsBalance.lastYear[moment(movement.createdAt).month()].productsSold += product.quantity
                        }
                    })
                }

                if(movement.services.length > 0) {
                    movement.services.map((service: any) => {
                        if (moment(movement.createdAt).year() === moment().year()) {
                            monthsBalance.currentYear[moment(movement.createdAt).month()].servicesDone += service.quantity
                        } else {
                            monthsBalance.lastYear[moment(movement.createdAt).month()].servicesDone += service.quantity
                        }
                    })
                }
            } else {
                if (moment(movement.createdAt).year() === moment().year()) {
                    monthsBalance.currentYear[moment(movement.createdAt).month()].moneyOut += movement.total_value
                } else {
                    monthsBalance.lastYear[moment(movement.createdAt).month()].moneyOut += movement.total_value
                }
            }
        })

        return monthsBalance
    }

    const getNextExpenses = (financialMovements: any) => {
        let expenses: any = []

        financialMovements.map((movement: any) => {
            if (
                movement.type === 0
                && movement.paid_at == null
            ) {
                expenses.push({
                    id: movement.id,
                    title: movement.title,
                    value: movement.total_value,
                    date: movement.due_at ? general.formatDate(movement.due_at) : 'Sem data',
                })
            }
        })

        setLoaders(loader => ({ ...loader, nextExpenses: false }))

        return expenses.sort((a: any, b: any) => {
            return moment(a.date, 'DD/MM/YYYY').isAfter(moment(b.date, 'DD/MM/YYYY')) ? 1 : -1
        })
    }

    const getNextEvents = async () => {
        await general.api.post('event/list', {
            searchMonth: moment().format('MM')
        }).then((response: any) => {
            if(response.data.status == 403) {
                setEventsPermissionBlock(true);
                setLoaders(loader => ({ ...loader, financialMovements: false }))
                return;
            }
            const events = response.data.data

            if (events) {
                let newEvents: any = []

                events.map((event: any) => {
                    if(moment(event.end).isAfter(moment())) {
                        newEvents.push({
                            id: event.id,
                            title: event.title,
                            start: general.formatDate(event.start, true),
                            end: general.formatDate(event.end, true),
                        })
                    }
                })

                setNextEvents(newEvents.sort((a: any, b: any) => {
                    return moment(a.start, 'DD/MM/YYYY HH:mm').isAfter(moment(b.start, 'DD/MM/YYYY HH:mm')) ? 1 : -1
                }))
            }

            setLoaders(loader => ({ ...loader, nextEvents: false }))
        }).catch((error: any) => {
            console.log(error)
            general.notify('Erro ao buscar eventos', 'error')
            setLoaders(loader => ({ ...loader, nextEvents: false }))
        })
    }

    const getStock = async () => {
        await general.api.post('product/list',{
            page: 0,
            limit: 9999999999
        }).then((response: any) => {
            if(response.data.status == 403) {
                setStockPermissionBlock(true);
                setLoaders(loader => ({ ...loader, financialMovements: false }))
                return;
            }
            const products = response.data.data.data

            if (products) {
                let newStock: any = []

                products.map((product: any) => {
                    newStock.push({
                        id: product.id,
                        name: product.name,
                        stock: product.stock,
                    })
                })

                setStock(newStock.sort((a: any, b: any) => {
                    return a.stock > b.stock ? 1 : -1
                }))
            }

            setLoaders(loader => ({ ...loader, stock: false }))
        }).catch((error: any) => {
            console.log(error)
            general.notify('Erro ao buscar estoque', 'error')
            setLoaders(loader => ({ ...loader, stock: false }))
        })
    }

    const getCollaboratorsRanking = (financialMovements: any) => {
        let collaborators: any = []
        financialMovements.map((movement: any) => {
            if (movement.type === 1) {
                movement.products.map((product: any) => {
                    let collaboratorIndex = collaborators.findIndex((collaborator: any) => collaborator.id === movement.account_id)
                    if (collaboratorIndex === -1) {
                        collaborators.push({
                            id: movement.account_id,
                            name: general.formatName(movement.account[0].name),
                            items: product.quantity,
                            totalValue: product.value * product.quantity
                        })
                    } else {
                        collaborators[collaboratorIndex].items += product.quantity
                        collaborators[collaboratorIndex].totalValue += product.value * product.quantity
                    }
                })

                movement.services.map((service: any) => {
                    let collaboratorIndex = collaborators.findIndex((collaborator: any) => collaborator.id === movement.account_id)
                    if (collaboratorIndex === -1) {
                        collaborators.push({
                            id: movement.account_id,
                            name: general.formatName(movement.account[0].name),
                            items: service.quantity,
                            totalValue: service.value * service.quantity
                        })
                    } else {
                        collaborators[collaboratorIndex].items += service.quantity
                        collaborators[collaboratorIndex].totalValue += service.value * service.quantity
                    }
                })
            }
        })

        setCollaboratorsRanking(collaborators.sort((a: any, b: any) => {
            return a.value < b.value ? 1 : -1
        }))

        setLoaders(loader => ({ ...loader, collaboratorsRanking: false }))
    }


    const getDashboardData = async () => {
        await general.api.post('financial-movement/list', {
            moneyIn: true,
            moneyOut: true,
            startDate: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
            minValue: null,
            maxValue: null,
            limit: 9999999999,
        }).then((response: any) => {
            getNextEvents()
            getStock()
            if(response.data.status == 403) {
                setFinancialPermissionBlock(true);
                let newInfoBits = [...infoBits]
                newInfoBits.map((infoBit: any) => {
                    infoBit.sections[0].content = 'Bloqueado'
                    infoBit.sections[1].content = 'Bloqueado'
                })
                setInfoBits(newInfoBits)
                setLoaders(loader => ({ ...loader, financialMovements: false }))
                setLoaders(loader => ({ ...loader, collaboratorsRanking: false }))
                return;
            }
            const data = response.data.data
            const annualBalance = getAnnualBalance(data.financialmovements)
            const monthlyBalance = getMonthlyBalance(data.financialmovements)

            if (data.financialmovements) {
                let newInfoBits = [...infoBits]

                newInfoBits.map((infoBit: any) => {
                    if (infoBit.slug === 'anualBilling') {
                        infoBit.sections[0].content = `R$ ${general.formatMoney(annualBalance.currentYear.moneyIn.toString())}`
                        infoBit.sections[1].content = `R$ ${general.formatMoney(annualBalance.lastYear.moneyIn.toString())}`
                    } else if (infoBit.slug === 'monthlyBilling') {
                        infoBit.sections[0].content = `R$ ${general.formatMoney(monthlyBalance.currentYear[moment().month()].moneyIn.toString())}`
                        infoBit.sections[1].content = `R$ ${general.formatMoney(monthlyBalance.currentYear[moment().subtract(1, 'month').month()].moneyIn.toString())}`
                    } else if (infoBit.slug === 'annualExpenses') {
                        infoBit.sections[0].content = `R$ ${general.formatMoney(annualBalance.currentYear.moneyOut.toString())}`
                        infoBit.sections[1].content = `R$ ${general.formatMoney(annualBalance.lastYear.moneyOut.toString())}`
                    } else if (infoBit.slug === 'monthlyExpenses') {
                        infoBit.sections[0].content = `R$ ${general.formatMoney(monthlyBalance.currentYear[moment().month()].moneyOut.toString())}`
                        infoBit.sections[1].content = `R$ ${general.formatMoney(monthlyBalance.currentYear[moment().subtract(1, 'month').month()].moneyOut.toString())}`
                    } else if (infoBit.slug === 'soldProducts') {
                        infoBit.sections[0].content = monthlyBalance.currentYear[moment().month()].productsSold
                        infoBit.sections[1].content = monthlyBalance.currentYear[moment().subtract(1, 'month').month()].productsSold
                    } else if (infoBit.slug === 'servicesDone') {
                        infoBit.sections[0].content = monthlyBalance.currentYear[moment().month()].servicesDone
                        infoBit.sections[1].content = monthlyBalance.currentYear[moment().subtract(1, 'month').month()].servicesDone
                    }
                })

                buildProfitDivisionDataset(annualBalance)
                buildBillingDataset(monthlyBalance)
                setNextExpenses(getNextExpenses(data.financialmovements))
                setInfoBits(newInfoBits)
                setLoaders(loader => ({ ...loader, financialMovements: false }))
            }

            getCollaboratorsRanking(data.financialmovements)

        }).catch((error: any) => {
            console.log(error)
            general.notify('Erro ao buscar movimentações financeiras', 'error')
            setLoaders(loader => ({ ...loader, financialMovements: false }))
        })
    }

    useEffect(() => {
        getDashboardData()
    }, [])

    return {
        general,
        infoBits,
        months,
        billingDataset,
        billingSetExtra,
        profitDivisionDataset,
        innerWidth,
        nextExpenses,
        nextEvents,
        stock,
        collaboratorsRanking,
        loaders,
        financialPermissionBlock,
        eventsPermissionBlock,
        stockPermissionBlock
    }
}

export default useController;