import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultPropsBackoffice } from '../../../utils/types';

const Logs = (props: defaultPropsBackoffice) => {
    const controller = useController();

    return <h1 style={{color: "white"}}>Backoffice Logs</h1>;
}

export default Logs;