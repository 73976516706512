import styled, {keyframes} from 'styled-components';
import {colors} from '../../../../utils/colors';
import {fonts} from '../../../../utils/fonts';
import general from '../../../../utils/general';
import { useController } from './controller';

export const main = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    width: 330px;
    height: 320px;
    background-color: ${colors.white};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1366px) {
        width: 230px;
        height: 240px;
    }

    @media (max-width: 900px) {
        width: 190px;
    }

    @media (max-width: 700px) {
        width: calc(100% - 20px);
        flex-direction: row;
        align-items: center;
        height: 100px;
        padding: 0 10px;
    }
`;
export const avatarContainer = styled.div<{imgObj: any}>`
    display: flex;
    border-radius: 15px;
    margin-bottom: 10px;
    height: 70%;
    max-width: 90%;
    object-fit: cover;
    object-position: center;
    position: relative;
    overflow: hidden;

    @media (max-width: 700px) {
        width: 25%;
        height: 85%;
        margin-bottom: 0;
    }

    @media (max-width: 550px) {
        width: 45%;
        height: 75%;
        padding: 0;
    }
`;
const loadingImage = keyframes`
    0% {opacity: 0.6;}
    50% {opacity: 1;}
    100% {opacity: 0.6;}
`;
export const backgroundImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 15px;
    object-fit: cover;
    z-index: 1;
    animation: ${loadingImage} 2s infinite;
`;
export const cardAvatar = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 15px;
    opacity: 0;
    object-fit: cover;
    object-position: center;
    z-index: 10;
    position: absolute;
    transition-duration: 0.3s;
`;
export const cardData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    @media (max-width: 700px) {
        padding: 10px 0;
        justify-content: center;
        align-items: center;
    }
`;
export const cardTitles = styled.div`
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-family: ${fonts.main};

    @media (max-width: 700px) {
        display: flex;
        flex-direction: row;
        gap: 5px;
        text-align: left;
        width: auto;
        align-self: ceenter;
    }
`;
export const cardTitle = styled.h3`
    font-size: 18px;
    font-weight: 800;
    padding: 0 5px;
    flex: 1;

    @media (max-width: 1366px) {
        font-size: 16px;
    }
    @media (max-width: 700px) {
        font-size: 14px;
        text-align: center;
    }
`;
export const cardSubtitle = styled.h4<{subtitle: string}>`
    color: ${colors.darkerGrey};
    font-weight: 800;
    align-self: center;

    @media (max-width: 1366px) {
        font-size: 12px;
    }

    @media (max-width: 700px) {
        font-size: 12px;
        margin-top: 1px;
        padding-right: 10px;
        
        ${({subtitle}) => subtitle ? `
            &::before {
                content: '[';
            }
            &::after {
                content: ']';
            }
        ` : ''}
    }
`;
export const cardInfoArea = styled.div<{infoLength: number}>`
    display: grid;
    grid-template-columns: repeat(${({infoLength}) => infoLength >= 3 ? 3 : infoLength}, 1fr);
    width: calc(100% - 20px);
    flex-wrap: wrap;
    gap: 10px;
    padding: 0 10px;
    margin-top: 10px;
    place-items: center;
    height: 100%;

    @media(max-width: 700px) {
        height: auto;
    }
`;
export const cardInfoBit = styled.div`
    color: ${colors.darkerGrey};
    font-family: ${fonts.main};
    font-weight: 800;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 3px;
`;
export const infoIcon = styled(general.FontAwesomeIcon)`
    font-size: 1.5vw;

    @media (max-width: 1200px) {
        font-size: 1.75vw;
    }

    @media (max-width: 900px) {
        font-size: 2.25vw;
    }

    @media (max-width: 700px) {
        font-size: 4vw;
    }

    @media (max-height: 600px) {
        font-size: 2vw;
    }
`;
export const info = styled.p`
    font-size: .9vw;

    @media (max-width: 1200px) {
        font-size: 1.1vw;
    }

    @media (max-width: 900px) {
        font-size: 1.5vw;
    }

    @media (max-width: 700px) {
        font-size: 3.2vw;
    }

    @media (max-height: 600px) {
        font-size: 1.65vw;
    }
`;