import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';
import { useGlitch } from 'react-powerglitch'

const Page404 = (props: defaultProps) => {
    const controller = useController();
    const glitch = useGlitch();

    return (
        <S.mainContainer>
            <S.container>
                <S.imgLogo ref={glitch.ref} src='/app/images/logo/logo_arezdot_yellow_noname.png' />
                <S.mainText ref={glitch.ref}>404 - Página <br/> Não encontrada</S.mainText>
            </S.container>
        </S.mainContainer>
    )
}

export default Page404;