import general from '../../../utils/general';
import { useEffect, useState } from 'react';
import { useGlitch } from 'react-powerglitch';

export const useController = () => {
    const menuPreset = [[
        { title: "Minhas empresas", icon: "faBuildingUser", route: "/app/company/choose" },
        /* {title: "Convites", icon: "faEnvelope", route: "/"},
        {title: "Configurações", icon: "faGear", route: "/"}, */
    ], [
        { title: "Dashboard", icon: "faChartPie", route: "/app/dashboard" },
        { title: "Categorias", icon: "faLayerGroup", route: "/app/prodserv/categories" },
        { title: "Estoque", icon: "faBoxesStacked", route: "/app/products" },
        { title: "Serviços", icon: "faScrewdriverWrench", route: "/app/services" },
        { title: "Finanças", icon: "faDollar", route: "/app/finances" },
        { title: "Utilidades", icon: "faShapes", route: "/app/utilities" },
        { title: "Fornecedores", icon: "faTruckField", route: "/app/suppliers" },
        { title: "Recursos Humanos", icon: "faUserGroup", route: "/app/humanresources" },
        { title: "Times", icon: "faUsers", route: "/app/teams" },
        { title: "Clientes", icon: "faUser", route: "/app/stakeholders" },
        { title: "Leads", icon: "faFilterCircleDollar", route: "/app/leads" },
        { title: "Formulários", icon: "faPenToSquare", route: "/app/forms" },
        { title: "Agenda", icon: "faCalendar", route: "/app/agenda" },
        /* { title: "Tarefas", icon: "faListCheck", route: "/app/forms" }, */
        /* { title: "Projetos", icon: "faDiagramProject", route: "/app/forms" }, */
        { title: "Metas", icon: "faBarsProgress", route: "/app/goals" },
        { title: "Patrimônio", icon: "faHouseCircleCheck", route: "/app/patrimony" },
        { title: "Veículos", icon: "faCar", route: "/app/vehicles" },
        /* {title: "Marketing", icon: "faBullhorn", route: "/app/stores"}, */
        /* {title: "Lojas", icon: "faCartShopping", route: "/app/stores"}, */
        /* {title: "Automações", icon: "faRobot", route: "/app/stores"}, */
        /* {title: "Integrações", icon: "faLink", route: "/app/stores"}, */
        /* { title: "Novidades", icon: "faNewspaper", route: "/app/forms" }, */
        { title: "Configurar empresa", icon: "faBuilding", route: "/app/company/config" },
        { title: "Trocar Empresa", icon: "faArrowRightArrowLeft", route: "/app/company/choose" },
    ], [
        { title: "Contas", icon: "faUsers", route: "/backoffice/accounts" },
        { title: "Planos", icon: "faDollar", route: "/backoffice/plans" },
        { title: "Empresas", icon: "faBuilding", route: "/backoffice/companies" },
        { title: "Códigos/Cupons", icon: "faTicket", route: "/backoffice/promo_codes" },
    ]]
    const [editCompanyModal, setEditCompanyModal] = useState<boolean>(false)
    const [detailedMenu, setDetailedMenu] = useState<number>(0)
    const [AIAllowed, setAIAllowed] = useState<boolean>(false)
    const [AIModalOpen, setAIModalOpen] = useState<boolean>(false)
    const [AIAnswer, setAIAnswer] = useState<string>('')
    const [supportModalOpen, setSupportModalOpen] = useState<boolean>(false)
    const [loadingAIAnswer, setLoadingAIAnswer] = useState<boolean>(false)
    const [prompt, setPrompt] = useState<string>('')
    const glitch = useGlitch();

    const verifyAI = async () => {
        try {
            const response = await general.api.post('helpers/verify/module', {
                module: 'module_ai'
            })
            if (response.status === 200) {
                if (response.data.status === 403) {
                    setAIAllowed(false)
                    return
                }
                setAIAllowed(true)
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const changePrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrompt(e.target.value)
    }

    const AIRequest = async () => {
        try {
            if (prompt === '') {
                general.notify('Digite algo para a AI responder', 'error')
                return
            }
            setLoadingAIAnswer(true)
            const response = await general.api.post('helpers/aiprompt', {
                prompt
            })
            if (response.status === 200) {
                if (response.data.status === 403) {
                    setAIAllowed(false)
                    setLoadingAIAnswer(false)
                    general.notify('Você não tem permissão para fazer isso', 'error')
                    return
                }
                if (response.data.data) {
                    setAIAnswer(response.data.data.replace(/(?:\r\n|\r|\n)/g, '<br>'))
                }
                setLoadingAIAnswer(false)
            }
            else {
                setLoadingAIAnswer(false)
                general.notify('Erro ao solicitar resposta da AI', 'error')
            }
        }
        catch (err) {
            console.log(err)
            setLoadingAIAnswer(false)
        }
    }

    useEffect(() => {
        verifyAI()
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setAIModalOpen(false)
                setSupportModalOpen(false)
            }
        })
    }, [])

    useEffect(() => {
        if (AIModalOpen) {
            document.getElementById('AIInput')?.focus()
        }
    }, [AIModalOpen])

    useEffect(() => {
        if(loadingAIAnswer){
            glitch.startGlitch();
        }
        else {
            glitch.stopGlitch();
        }
    }, [loadingAIAnswer])

    return {
        prompt,
        glitch,
        general,
        AIAnswer,
        AIAllowed,
        menuPreset,
        AIModalOpen,
        detailedMenu,
        loadingAIAnswer,
        editCompanyModal,
        supportModalOpen,
        AIRequest,
        setPrompt,
        setAIAnswer,
        setAIAllowed,
        changePrompt,
        setAIModalOpen,
        setDetailedMenu,
        setLoadingAIAnswer,
        setEditCompanyModal,
        setSupportModalOpen,
    }
}

export default useController;