import general from '../../../../utils/general';
import { useState, useRef } from 'react';
export const useController = () => {


    return {
        general,
    };
}

export default useController;