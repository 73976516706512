import general from '../../../utils/general';
import { useEffect, useState } from 'react';
export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [createModalActive, setCreateModalActive] = useState(false);
    const [currentItem, setCurrentItem] = useState<any>();
    const [patrimonyList, setPatrimonyList] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const patrimonyCreateTemplate = {
        title: 'Patrimônio',
        subtitle: 'Criar um novo patrimônio',
        infos: [
            {
                name: 'Nome',
                key: 'name',
                type: 'text',
                value: '',
                disabled: true,
                required: true
            },
            {
                name: 'Descrição',
                key: 'description',
                type: 'text',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Data de aquisição',
                key: 'acquisition_date',
                type: 'text',
                mask: '##/##/####',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Valor de compra',
                key: 'acquisition_value',
                type: 'money',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Valor atual',
                key: 'current_value',
                type: 'money',
                value: '',
                disabled: true,
                required: false
            },
            {
                name: 'Status',
                key: 'status',
                type: 'select',
                value: '',
                disabled: true,
                required: false,
                selectOptions: [
                    {
                        name: 'Ativo/Operacional',
                        value: 'active'
                    },
                    {
                        name: 'Em Uso',
                        value: 'in_use'
                    },
                    {
                        name: 'Disponível',
                        value: 'available'
                    },
                    {
                        name: 'Em Manutenção',
                        value: 'maintenance'
                    },
                    {
                        name: 'Desativado',
                        value: 'disabled'
                    },
                    {
                        name: 'Vendido',
                        value: 'sold'
                    },
                    {
                        name: 'Descartado',
                        value: 'discarded'
                    },
                    {
                        name: 'Alocado',
                        value: 'allocated'
                    },
                    {
                        name: 'Em Reparação',
                        value: 'repair'
                    },
                    {
                        name: 'Danificado',
                        value: 'damaged'
                    },
                    {
                        name: 'Perdido',
                        value: 'lost'
                    },
                    {
                        name: 'Roubado/Furtado',
                        value: 'stolen'
                    },
                    {
                        name: 'Em Armazenamento',
                        value: 'storage'
                    },
                    {
                        name: 'Em Transferência',
                        value: 'transfer'
                    },
                    {
                        name: 'Em Aquisição',
                        value: 'acquisition'
                    },
                    {
                        name: 'Aguardando Instalação',
                        value: 'awaiting_installation'
                    },
                    {
                        name: 'Em Configuração',
                        value: 'configuration'
                    },
                    {
                        name: 'Obsoleto',
                        value: 'obsolete'
                    },
                    {
                        name: 'Em Atualização/Upgrade',
                        value: 'updating'
                    },
                    {
                        name: 'Em Calibração',
                        value: 'calibration'
                    },
                    {
                        name: 'Em Manutenção Preventiva',
                        value: 'preventive_maintenance'
                    },
                    {
                        name: 'Em Manutenção Corretiva',
                        value: 'corrective_maintenance'
                    },
                    {
                        name: 'Em Inspeção',
                        value: 'inspection'
                    },
                    {
                        name: 'Fora da Garantia',
                        value: 'out_of_warranty'
                    },
                    {
                        name: 'Sob Garantia',
                        value: 'under_warranty'
                    },
                    {
                        name: 'Aguardando Descarte',
                        value: 'awaiting_discard'
                    },
                    {
                        name: 'Aguardando Peças/Substituição',
                        value: 'awaiting_parts'
                    },
                    {
                        name: 'Em Trânsito',
                        value: 'in_transit'
                    },
                    {
                        name: 'Em Processo de Reavaliação',
                        value: 'revaluation'
                    },
                    {
                        name: 'Em Processo de Retorno',
                        value: 'return_process'
                    },
                    {
                        name: 'Em Serviço Externo',
                        value: 'external_service'
                    },
                    {
                        name: 'Em Desenvolvimento',
                        value: 'development'
                    },
                    {
                        name: 'Em Implementação',
                        value: 'implementation'
                    },
                    {
                        name: 'Em Teste',
                        value: 'testing'
                    },
                    {
                        name: 'Em Backup/Redundância',
                        value: 'backup'
                    },
                    {
                        name: 'Sob Leasing',
                        value: 'leasing'
                    },
                    {
                        name: 'Leasing Ativo',
                        value: 'active_leasing'
                    },
                    {
                        name: 'Leasing Encerrado',
                        value: 'closed_leasing'
                    },
                    {
                        name: 'Sob Contrato de Serviço',
                        value: 'service_contract'
                    },
                    {
                        name: 'Sob Custódia de Terceiros',
                        value: 'third_party_custody'
                    },
                    {
                        name: 'Em Auditoria',
                        value: 'audit'
                    },
                    {
                        name: 'Em Conformidade',
                        value: 'compliance'
                    },
                    {
                        name: 'Não Conforme',
                        value: 'non_compliant'
                    },
                    {
                        name: 'Bloqueado',
                        value: 'blocked'
                    },
                    {
                        name: 'Em Quarentena',
                        value: 'quarantine'
                    },
                    {
                        name: 'Sob Observação',
                        value: 'observation'
                    },
                    {
                        name: 'Em Litígio',
                        value: 'litigation'
                    },
                    {
                        name: 'Em Processo de Seguro',
                        value: 'insurance_process'
                    },
                    {
                        name: 'Requisitado',
                        value: 'requested'
                    },
                    {
                        name: 'Aguardando Aprovação',
                        value: 'awaiting_approval'
                    },
                    {
                        name: 'Subutilizado',
                        value: 'underutilized'
                    },
                    {
                        name: 'Sobrecarregado',
                        value: 'overloaded'
                    },
                    {
                        name: 'Descontinuado pelo Fabricante',
                        value: 'discontinued'
                    },
                    {
                        name: 'Não Localizado',
                        value: 'not_found'
                    },
                    {
                        name: 'Em Renovação de Licença',
                        value: 'license_renewal'
                    },
                    {
                        name: 'Com Restrição de Uso',
                        value: 'restricted_use'
                    },
                    {
                        name: 'Não Reparável',
                        value: 'unrepairable'
                    },
                    {
                        name: 'Em Treinamento',
                        value: 'training'
                    },
                    {
                        name: 'Em Processo de Integração',
                        value: 'integration'
                    },
                    {
                        name: 'Aguardando Pagamento',
                        value: 'awaiting_payment'
                    },
                    {
                        name: 'Em Processo de Customização',
                        value: 'customization'
                    },
                    {
                        name: 'Em Espera',
                        value: 'waiting'
                    }
                ]
            },
        ]
    }

    const getPatrimonyList = async (showLoader?: boolean) => {
        try {
            showLoader == true && setLoading(true);
            const response = await general.api.post('patrimony/list', {
                page: page
            });
            if (response.status === 200) {
                if (response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setTotalPages(response.data.data.total_pages);
                setPatrimonyList(response.data.data.data);
                setLoading(false);
            }
        }
        catch (err) {
            console.log(err);
            general.notify('Erro ao buscar veículos', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentItem) {
            let newCurrentItem = currentItem;
            setCurrentItem(newCurrentItem);
        }
    }, [currentItem]);

    useEffect(() => {
        getPatrimonyList(true);
    }, [page]);

    useEffect(() => {
        getPatrimonyList(true);
    }, []);

    useEffect(() => {
        getPatrimonyList(false);
    }, [createModalActive, editModalActive]);

    return {
        general,
        editModalActive,
        setEditModalActive,
        createModalActive,
        setCreateModalActive,
        patrimonyCreateTemplate,
        currentItem,
        setCurrentItem,
        patrimonyList,
        page,
        setPage,
        totalPages,
        loading,
        permissionBlock
    }
}

export default useController;