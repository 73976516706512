import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultProps } from '../../../utils/types';

const NewItemComponent = (props: defaultProps) => {
    const controller = useController();

    return (
        <S.main>
            {/*  @ts-expect-error */}
            <S.icon icon={controller.general.icons[props.mainIcon ?? 'faAdd']} />
            <S.text>Adicionar<br />{props.pageItem ?? ''}</S.text>
        </S.main>
    );
}

export default NewItemComponent;