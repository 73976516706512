import React, { useEffect, useState } from 'react';
import * as S from './styles';
import ReactQuill from 'react-quill';

export const QuillTextArea = (props: {
    name: string;
    placeholder?: string;
    required?: boolean;
    label: string;
    value: string;
    onChange: any;
    hasModules?: boolean;
}) => {
    useEffect(() => {
        const fontSizePicker = document.querySelector('.ql-picker-label');
        if(fontSizePicker){
            fontSizePicker.setAttribute('data-value', '12px');
        }
    }, []);

    return (
        <S.text>
            <S.label htmlFor={props.name}>{props.label}</S.label>
            <S.textArea
                theme="snow"
                defaultValue={props.value}
                onChange={props.onChange}
                modules={{
                    toolbar: [
                        [{ size: ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '36px', '48px', '60px', '72px', '96px']}],
                        [ {color: []} ],
                        ['bold'],
                        ['italic'],
                        ['underline'],
                        ['strike'],
                        ['blockquote'],
                        [{ 'list': 'ordered' }],
                        [{ 'list': 'bullet' }],
                        [{ 'indent': '-1' }],
                        [{ 'indent': '+1' }],
                        [{ 'align': [] }],
                        ['link'],
                        ['image']
                    ],
                }}
            />
        </S.text>
    );
}

export default QuillTextArea;