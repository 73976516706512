import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultItemProps } from '../../../../utils/types';
import Avatar from 'react-avatar';
import {colors} from '../../../../utils/colors';

const UserCard = (props: defaultItemProps) => {
    const controller = useController();

    return (
        <S.main>
            <S.card>
                {(props.avatar && props.avatar != '') ? <S.cardAvatar src={props.avatar} alt='Uma imagem como avatar'/> : <Avatar alt='Uma imagem como avatar' round='55px' style={
                    {
                        width: '110px',
                        height: '110px',
                        fontSize: '40px',
                        border: `2px solid ${colors.grey}`,
                        marginTop: '-55px',
                    }
                } size='110' maxInitials={2} name={props.title ?? "Não informado"} />}
                <S.cardData>
                    <S.personName>{(props.title!.split(' ').length > 1 ? `${props.title?.split(' ')[0]} ${props.title?.split(' ')[props.title?.split(' ').length - 1]}` : props.title) ?? 'Título não informado'}</S.personName>
                    <S.personRole>{props.subtitle ?? 'Subtítulo não informado'}</S.personRole>
                    <S.cardInfo>
                        {props.infos && props.infos?.length > 0 ? props.infos.map((info, index) => {
                            return (
                                <S.personInfo key={index}>
                                    <S.personInfoName>{info?.name ?? "Não informado"}:</S.personInfoName>
                                    <S.personInfoValue dangerouslySetInnerHTML={{__html: `<span>${(info.value != '' && info.value) ? (info.type === 'select' ? (info.selectOptions?.filter(option => option.value === info.value)[0]?.name ?? info.value ?? "Não informado") : (info.type === 'date' ? info.value?.toString().split('T')[0].split('-').reverse().join('/') : info.value)) : 'Não informado'}</span>`}}></S.personInfoValue>
                                </S.personInfo>
                            )
                        }) : (
                            <S.noInfo>Esse card não contém nenhuma informação extra.</S.noInfo>
                        )}
                    </S.cardInfo>
                </S.cardData>
            </S.card>

        </S.main>
    );
}

export default UserCard;