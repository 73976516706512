import styled, {keyframes} from 'styled-components';
import {colors} from '../../../../utils/colors';
import {fonts} from '../../../../utils/fonts';
import general from '../../../../utils/general';

export const main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 50px 0;
    width: 330px;
    height: 260px;
    background-color: ${colors.black};
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
    border-radius: 10px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
        transform: scale(1.05);
    }

    @media (max-width: 1366px) {
        width: 230px;
        height: 180px;
    }

    @media (max-width: 900px) {
        width: 280px;
    }

    @media (max-width: 700px) {
        width: calc(100% - 50px);
        flex-direction: row;
        max-height: 50px;
        padding: 25px 25px;
    }
`;
export const icon = styled(general.FontAwesomeIcon)`
    font-size: 144px;
    color: ${colors.white};

    @media (max-width: 1366px) {
        font-size: 80px;
    }

    @media (max-width: 768px) {
        font-size: 60px;
    }
`;
export const text = styled.p`
    font-family: ${fonts.main};
    font-size: 26px;
    margin: 0 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.white};
    text-align: center;

    @media (max-width: 1366px) {
        font-size: 20px;
    }

    @media (max-width: 768px) {
        font-size: 18px;
    }

    @media (max-width: 700px) {
        text-align: right;
    }

    @media (max-width: 500px) {
        font-size: 16px;
    }
`;