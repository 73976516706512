import general from '../../../utils/general';
import { useEffect, useState, useMemo } from 'react';
import { loadSlim } from "@tsparticles/slim";
import { initParticlesEngine } from "@tsparticles/react";
import {
    type Container,
    type ISourceOptions,
    MoveDirection,
    OutMode,
  } from "@tsparticles/engine";

export const useController = () => {
    const [loading, setLoading] = useState(false);
    const [init, setInit] = useState(false);

    const particlesLoaded = (container?: Container) => {};

    const login = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        let email = (document.querySelector('input[name="email"]') as HTMLInputElement).value;
        let password = (document.querySelector('input[name="password"]') as HTMLInputElement).value;

        if (email === '' || password === '') {
            setLoading(false);
            general.notify('Por favor, preencha os campos para login', 'error');
            return;
        }

        if(password.includes(' ')){
            setLoading(false);
            general.notify('A senha não pode conter espaços', 'error');
            return;
        }

        if(email.includes(' ')){
            setLoading(false);
            general.notify('O email não pode conter espaços', 'error');
            return;
        }

        else {
            try {
                general.api.post('account/login', {email, password}).then((response: any) => {
                    setLoading(false);
                    if (response.data.status === 200) {
                        let accountData = response.data.data.account;
                        accountData.token = response.data.data.token;
                        localStorage.setItem("account", JSON.stringify(accountData));
                        localStorage.setItem("token", response.data.data.token);
                        general.GoToRoute('/app/company/choose')
                    }
                    else {
                        general.notify(response.data.message, 'error');
                    }
                })
            }
            catch (err) {
                setLoading(false);
                general.notify('Erro ao tentar fazer login', 'error');
                console.log(err);
            }
        }
    }

    useEffect(() => {
        initParticlesEngine(async (engine) => {
          await loadSlim(engine);
        }).then(() => {
          setInit(true);
        });
      }, []);

      const options: ISourceOptions = useMemo(
        () => ({
          background: {
            color: {
              value: "#574f03",
            },
          },
          fpsLimit: 120,
          interactivity: {
            events: {
              onClick: {
                enable: true,
                mode: "push",
              },
              onHover: {
                enable: true,
                mode: "grab",
              },
            },
            modes: {
              push: {
                quantity: 1,
              },
              grab: {
                distance: 200,
                quantity: 2,
                links: {
                  opacity: 1,
                },
              }
            },
          },
          particles: {
            color: {
              value: "#ffffff",
            },
            links: {
              color: "#ffffff",
              distance: 250,
              enable: true,
              opacity: 0.3,
              width: 1,
            },
            move: {
              direction: MoveDirection.none,
              enable: true,
              outModes: {
                default: OutMode.out,
              },
              random: true,
              speed: 2,
              straight: false,
            },
            number: {
              density: {
                enable: true,
              },
              value: 120,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              value: { min: 1, max: 2 },
            },
          },
          detectRetina: true,
        }),
        [],
      );

    return { general, login, loading, particlesLoaded, options}
}

export default useController;