import styled, {keyframes} from 'styled-components';
import { colors } from '../../../utils/colors';
import { fonts } from '../../../utils/fonts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;
export const generalArea = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1;

    @media (max-width: 1280px) {
        height: calc(100% - 60px);
    }
`;
export const operationsArea = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    flex: 1;
    overflow-y: auto;
    padding: 20px;
    gap: 25px;

    &::after {
        content: '';
        flex: auto;
    }
`;
export const collaboratorPermissionsModalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
`;
export const collaboratorPermissionsModal = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 50%;
    background-color: ${colors.backgroundWhite};
    border-radius: 10px;
    padding: 20px;
    z-index: 9999;
    animation: ${keyframes`
        from {
            opacity: 0;
            transform: scale(0.8);
        }
        to {
            opacity: 1;
            transform: scale(1);
        }
    `} 0.3s;
`;
export const permissionsModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;
export const permissionsModalTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 20px;
    color: ${colors.black};
`;
export const permissionsModalClose = styled(FontAwesomeIcon)`
    font-size: 20px;
    color: ${colors.black};
    cursor: pointer;
`;
export const permissionsModalContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
`;
export const permissionsModalItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;
export const permissionsModalItemTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 16px;
    color: ${colors.black};
    text-transform: capitalize;
`;
export const permissionsModalItemPermissions = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
`;
export const permissionsModalItemPermission = styled.div`
    display: flex;
    flex-direction: row;
    gap: 5px;
`;
export const permissionCheckboxWrapper = styled.div<{checked: boolean}>`
    display: flex;
    flex-direction: row;
    gap: 5px;
    border: 1px solid ${colors.darkGrey};
    border-radius: 5px;
    background-color: ${props => props.checked ? colors.black : 'transparent'};
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
`;
export const permissionCheckbox = styled.input`
    cursor: pointer;
    display: none;
`;
export const permissionCheckboxLabel = styled.label<{checked: boolean}>`
    cursor: pointer;
    font-family: ${fonts.main};
    font-size: 14px;
    padding: 10px;
    color: ${props => props.checked ? colors.white : colors.black};
    text-transform: capitalize;
    flex: 1;
    width: fit-content;
`;
export const saveButton = styled.button`
    font-family: ${fonts.main};
    font-size: 14px;
    color: ${colors.black};
    background-color: transparent;
    font-weight: 800;
    border: none;
    border-radius: 5px;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    width: 100%;
    align-self: flex-end;
    transition: background-color 0.3s;
    border: 1px solid ${colors.black};
    text-transform: uppercase;

    &:hover {
        background-color: ${colors.black};
        color: ${colors.white};
    }
`;