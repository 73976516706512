import styled, {keyframes} from 'styled-components';
import {colors} from '../../../utils/colors';
import {fonts} from '../../../utils/fonts';
import general from '../../../utils/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const blockedItems = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;

    @media(max-width: 900px) {
        padding: 10% 0;
    }
`;
export const blockedItemsIcon = styled(FontAwesomeIcon)`
    font-size: 3rem;
    color: ${colors.darkeredGrey};
`;
export const blockedItemsTitle = styled.h2`
    font-size: 1.5rem;
    color: ${colors.darkeredGrey};
    margin-top: 1rem;
    text-align: center;
`;