import general from '../../../utils/general';
import { useState, useEffect, useRef } from 'react';
import { addCompanyModalProps } from '../../../utils/types';
import { plan } from './types'

export const useController = (props: addCompanyModalProps) => {
    const [modalActive, setModalActive] = useState(false);
    const [step, setStep] = useState(0);
    const [currentLogo, setCurrentLogo] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [companyInfo, setCompanyInfo] = useState<any>({
        _id: JSON.parse(localStorage.getItem('account') as string)._id,
        logo: { name: "", type: "", size: "", result: "", pathCdn: "" },
        name: "",
        fantasy_name: "",
        document: "",
        email: "",
        phone: "",
        birthdate: "",
    });

    const resetPage = () => {
        setStep(0);
        props.setModalActive(false);
    }

    const fieldFormat = (field: string, value: any) => {
        switch (field) {
            case 'phone':
                return general.phoneMask.customPhoneMask(value, '55');
            case 'birthdate':
                return general.customMask(value, '##/##/####');
            case 'document':
                return general.customMask(value, (value.replace(/[^0-9]/g, '').length >= 12 ? '##.###.###/####-##' : '###.###.###-##'));
            default:
                return value;
        }
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = e.target;
        setCompanyInfo((prev: any) => ({ ...prev, [name]: fieldFormat(name, value) }));
    }

    const onlyImages = (file: File) => {
        if (file.type.includes('image')) return true;
        return false;
    }

    const handleInputFile = async (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const file: File = (target.files as FileList)[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            if (!onlyImages(file)) return alert('Arquivo inválido');
            setCompanyInfo((prev: any) => ({ ...prev, logo: { name: file.name, type: file.type, size: file.size.toString(), result: reader.result as string } }));
            setCurrentLogo(reader.result as string);
        }
        let fileUrl = await reader.readAsDataURL(file);
    }

    const getStoredCompany = () => {
        const company = localStorage.getItem('companyObject');
        if (company) {
            setCompanyInfo({
                ...companyInfo,
                name: JSON.parse(company)?.name ?? '',
                logo: JSON.parse(company)?.logo ?? '',
                fantasy_name: JSON.parse(company)?.fantasy_name ?? '',
                document: fieldFormat('document', JSON.parse(company)?.document ?? ''),
                email: JSON.parse(company)?.email ?? '',
                phone: fieldFormat('phone', JSON.parse(company)?.phone ?? ''),
                birthdate: fieldFormat('birthdate', JSON.parse(company)?.birthdate?.split("T")[0].split("-").reverse().join("/") ?? ''),
            });
        }
    }

    const updateInfo = async () => {
        if (companyInfo.name.length < 3){
            props.setModalActive(false);
            return general.notify('Nome inválido', 'error')
        };
        setLoading(true);
        await general.api.put(`/company/update/${localStorage.getItem('company')}`, {
            ...companyInfo, 
            birthdate: companyInfo.birthdate ? companyInfo.birthdate.split('/').reverse().join('-') : null,
            phone: companyInfo.phone.replace(/\D/g, ''),
            document: companyInfo.document.replace(/\D/g, ''),
        }).then((response: any) => {
            if(response.data.status == 403) {
                props.setModalActive(false);
                setLoading(false);
                return general.notify(response.data.message, 'error')
            };
            general.notify(response.data.message, 'success');
            localStorage.setItem('companyObject', JSON.stringify(response.data.data));
            getStoredCompany();
            resetPage();
            setLoading(false);
        }).catch((err) => {
            general.notify(err.response.data.message, 'error');
            setLoading(false);
        });
    }

    useEffect(() => {
        getStoredCompany();
        if (!props.modalActive) {
            setTimeout(() => {
                setModalActive(props.modalActive);
            }, 300);
        }
        else {
            setModalActive(props.modalActive);
        }
    }, [props.modalActive]);

    useEffect(() => {
        document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                props.setModalActive(false);
            }
        })
    }, []);

    return {
        general,
        modalActive,
        step,
        setStep,
        companyInfo,
        setCompanyInfo,
        handleInputChange,
        handleInputFile,
        updateInfo,
        currentLogo,
        loading
    };
}

export default useController;