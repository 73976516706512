import styled, { keyframes } from 'styled-components';
import general from '../../../../utils/general';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';

export const main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.backgroundWhite};
`;

export const formNotFound = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 75px;
`;
export const formNotFoundIcon = styled(general.FontAwesomeIcon)`
    align-self: center;
    justify-self: center;
    font-size: 144px;
    color: ${colors.black};

    @media (max-width: 500px) {
        font-size: 120px;
    }
`;
export const formNotFoundTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.black};
    text-align: center;
`;

export const formWrapper = styled.div<{ formSended: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    height: 92%;
    overflow-y: auto;
    margin: 20px auto;
    background-color: ${colors.white};
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

    ${props => props.formSended && `
        justify-content: center;
        align-items: center;
    `}

    @media (max-width: 1366px) {
        height: 89%;
    }

    @media (max-width: 1200px) {
        max-width: 85%;
        margin: 10px auto;
        height: 81%;
    }

    @media (max-width: 800px) {
        height: 88%;
    }

    @media (max-width: 500px) {
        max-width: 85%;
        margin: 10px auto;
        height: 85%;
    }
`;

export const formTitle = styled.h1`
    font-family: ${fonts.main};
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    color: ${colors.black};

    @media (max-width: 500px) {
        font-size: 24px;
    }
`;

export const formDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 400;
    color: ${colors.black};
    margin-bottom: 10px;
    padding: 10px 20px;

    .ql-align-center {
        text-align: center;
    }
    img {
        max-width: 100%;
        height: auto;
    }
`;

export const formFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const formField = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const formFieldTitle = styled.h2`
    font-family: ${fonts.main};
    font-size: 24px;
    font-weight: 600;
    color: ${colors.black};
`;

export const formFieldDescription = styled.p`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 400;
    color: ${colors.black};
`;

export const formFieldInput = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

export const formFieldTextInput = styled.input`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 400;
    color: ${colors.black};
    padding: 10px;
    border: 1px solid ${colors.black};
`;

export const formFieldTextArea = styled.textarea`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 400;
    color: ${colors.black};
    padding: 10px;
    border: 1px solid ${colors.black};
`;

export const formFieldSelect = styled.select`
    font-family: ${fonts.main};
    font-size: 16px;
    font-weight: 400;
    color: ${colors.black};
    padding: 10px;
    border: 1px solid ${colors.black};
`;

export const formSubmit = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 100%;
`;

export const formSubmitButton = styled.button`
    font-family: ${fonts.main};
    width: 100%;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.white};
    background-color: ${colors.black};
    padding: 20px 0;
    height: 60px;
    align-self: flex-end;
    border: none;
    cursor: pointer;

    &:hover {
        transform: scale(1.02);
    }
`;

export const formSended = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 80%;
`;

export const formSendedIcon = styled(general.FontAwesomeIcon)`
    font-size: 144px;
    color: ${colors.black};

    @media (max-width: 500px) {
        font-size: 120px;
    }
`;

export const formSendedTitle = styled.p`
    font-family: ${fonts.main};
    font-size: 18px;
    width: 100%;
    font-weight: 600;
    text-transform: uppercase;
    color: ${colors.black};
    text-align: center;
`;

export const formSendedButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px 50px;
    width: 100%;
    background-color: ${colors.black};
    color: ${colors.white};
    border-radius: 5px;
    cursor: pointer;
    transition-duration: 0.3s;

    &:hover {
        transform: scale(1.05);
    }
`;

export const redirectButton = styled.p`
    font-family: ${fonts.main};
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
`;
