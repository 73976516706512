import React, { useState } from 'react';
import { useController } from './controller';
import * as S from './styles';
import { defaultItemProps } from '../../../../utils/types';
import general from '../../../../utils/general';
import Avatar from 'react-avatar';
import { colors } from '../../../../utils/colors';

const ResponseCard = (props: defaultItemProps) => {
    const controller = useController(props);

    return (
        <S.main deleteActive={controller.deleteModalActive} expandedActive={controller.expandedInfoModal}>
            <S.deleteAnswerModal active={controller.deleteModalActive} onClick={(e: any) => {
                e.stopPropagation();
            }}>
                <S.deleteAnswerModalContent>
                    <S.deleteAnswerModalTitle>
                        Tem certeza que deseja excluir essa resposta?
                    </S.deleteAnswerModalTitle>
                    <S.deleteAnswerModalButtons>
                        <S.deleteAnswerModalButton warning={true} onClick={() => controller.deleteResponseCard(props.answer._id ?? "")}>
                            Excluir
                        </S.deleteAnswerModalButton>
                        <S.deleteAnswerModalButton onClick={() => controller.setDeleteModalActive(false)}>
                            Cancelar
                        </S.deleteAnswerModalButton>
                    </S.deleteAnswerModalButtons>
                </S.deleteAnswerModalContent>
            </S.deleteAnswerModal>

            <S.expandedInfoModal active={controller.expandedInfoModal} onClick={(e) => {
                e.stopPropagation()
                controller.setExpandedInfoModal(false)
            }}>
                <S.expandedInfoModalContent onClick={(e: any) => e.stopPropagation()}>
                    <S.expandedInfoModalHeader>
                        <S.expandedInfoModalTitle>Informações expandidas</S.expandedInfoModalTitle>
                        <S.expandedInfoModalClose icon={general.icons.faTimes} onClick={() => controller.setExpandedInfoModal(false)} />
                    </S.expandedInfoModalHeader>
                    <S.expandedInfoFields>
                        {props.infos ? props.infos.map((field: typeof props.infos[0], index: number) => {
                            return (
                                <>
                                    <S.expandedInfoModalField key={index}>
                                        <S.expandedInfoModalFieldTitle>Campo ({field.name}): <S.expandedInfoModalCopyIcon icon={general.icons.faCopy} onClick={() => {
                                            general.copyToClipboard((field.type == "checkbox" ? (field.value ? "Sim" : "Não") : (field.value == '' || field.value == null) ? 'Não informado' : field.value.toString()))
                                            general.notify('Campo (' + field.name + ') copiado para a área de transferência', 'success')
                                        }} /></S.expandedInfoModalFieldTitle>
                                        <S.expandedInfoModalFieldText>{field.type == "checkbox" ? (field.value ? "Sim" : "Não") : (field.value == '' || field.value == null) ? 'Não informado' : field.value}</S.expandedInfoModalFieldText>
                                    </S.expandedInfoModalField>
                                </>
                            )
                        }) : null}
                    </S.expandedInfoFields>
                </S.expandedInfoModalContent>
            </S.expandedInfoModal>

            <S.card>
                <S.cardData>
                    <S.itemName>{(props.title!.split(' ').length > 1 ? `${props.title?.split(' ')[0]} ${props.title?.split(' ')[props.title?.split(' ').length - 1]}` : props.title) ?? 'Título não informado'}</S.itemName>
                    <S.itemRole>
                        {props.subtitle ?? 'Subtítulo não informado'}
                        <S.deleteResponseCardIcon onClick={(e: any) => {
                            e.stopPropagation();
                            controller.setDeleteModalActive(true);
                        }} icon={controller.general.icons.faTrash} />
                        <S.ExpandInfoResponseCardIcon onClick={(e: any) => {
                            e.stopPropagation();
                            controller.setExpandedInfoModal(true);
                        }} icon={controller.general.icons.faExpand} />
                    </S.itemRole>
                    <S.cardInfo>
                        {props.infos && props.infos.length > 0 ? props.infos.map((info, index) => {
                            return (
                                <S.itemInfo key={index}>
                                    <S.itemInfoName>{info.name}:</S.itemInfoName>
                                    <S.itemInfoValue dangerouslySetInnerHTML={{ __html: `<span>${(info.value != '' && info.value) ? (info.type === 'select' ? info.selectOptions?.filter(option => option.value === info.value)[0].name : (info.type === 'date' ? info.value?.toString().split('T')[0].split('-').reverse().join('/') : info.value)) : 'Não informado'}</span>` }}></S.itemInfoValue>
                                </S.itemInfo>
                            )
                        }) : (
                            <S.noInfo>Esse card não contém nenhuma informação extra.</S.noInfo>
                        )}
                    </S.cardInfo>
                </S.cardData>
            </S.card>

        </S.main>
    );
}

export default ResponseCard;