import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../utils/colors';
import { fonts } from '../../../../utils/fonts';
import general from '../../../../utils/general';
import ReactQuill, { Quill } from 'react-quill';
import Delta from 'quill-delta';

var Size = Quill.import('attributors/style/size');
const ClipboardModule = Quill.import('modules/clipboard');
Size.whitelist = ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '22px', '24px', '26px', '28px', '36px', '48px', '60px', '72px', '96px'];

class CustomClipboard extends ClipboardModule {
    onPaste(event: ClipboardEvent): void {
        const range: any = this.quill.getSelection();
        const formats: any = this.quill.getFormat(range.index);
        const pastedDelta: Delta = this.convert(formats);
        const delta: Delta = new Delta()
          .retain(range.index)
          .delete(range.length)
          .concat(pastedDelta);
        this.quill.updateContents(delta, 'silent');
        this.quill.setSelection(delta.length() - range.length, range.length, 'silent');
        this.quill.scrollIntoView();
      }
}

Quill.register(Size, true);
Quill.register('modules/clipboard', CustomClipboard, true);
let editor = ReactQuill as any;


export const text = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px 0;
`;

export const textArea = styled(editor)`
    margin-bottom: 35px;

    .ql-toolbar {
        display: flex;
        justify-content: center;
        border-radius: 5px 5px 0 0;
    }

    .ql-container {
        border-radius: 0 0 5px 5px;
        border-top: none;
    }   

    .ql-tooltip {
        position: absolute;
        top: 50% !important;
        left: 35% !important;
        align-self: center;
    }

    .ql-toolbar {
        flex-wrap: wrap;
    }

    @media(max-width: 1200px){
        margin-bottom: 60px;
    }

    @media(max-width: 500px){
        margin-bottom: 85px;
    }

    .ql-snow{
        .ql-picker{
            &.ql-size{
                .ql-picker-label,
                .ql-picker-item{
                    &::before{
                        content: attr(data-value) !important;
                    }
                }
            }
        }
    }

    .ql-editor{
        font-family: ${fonts.main};
        font-size: 12px;
    }

    ${Size.whitelist.map((size: number) => `
        .ql-size[value="${size}"] {
            font-size: ${size} !important;
        }
    `)}

`;

export const label = styled.label`
    font-family: ${fonts.main};
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
`;