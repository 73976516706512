import './nfcupom.css';
import general from '../../../../utils/general';

export const NfcupomTemplate = (props: {
    company_name: string;
    company_document: string;
    emitter: string;
    current_date: string;
    client_name: string;
    client_document: string;
    installments: number;
    payment_method: number;
    items: string;
    sub_total_value: string;
    service_fee: string;
    discount: string;
    total_value: string;
}) => {
    enum PaymentMethod {
        CREDIT_CARD = 0,
        DEBIT_CARD = 1,
        VOUCHER = 2,
        CASH = 3,
        PIX = 4,
        TRANSFER = 5,
        BANK_SLIP = 6,
        OTHER = 7
    }

    const getPaymentMethod = () => {
        switch (props.payment_method) {
            case PaymentMethod.CREDIT_CARD:
                return 'Cartão de crédito';
            case PaymentMethod.DEBIT_CARD:
                return 'Cartão de débito';
            case PaymentMethod.VOUCHER:
                return 'Voucher';
            case PaymentMethod.CASH:
                return 'Dinheiro';
            case PaymentMethod.PIX:
                return 'PIX';
            case PaymentMethod.TRANSFER:
                return 'Transferência';
            case PaymentMethod.BANK_SLIP:
                return 'Boleto';
            case PaymentMethod.OTHER:
                return 'Outro';
            default:
                return 'Não informado';
        }
    }

    return (
        <body>
            <div>
                <table className="printer-ticket">
                    <thead>
                        <tr>
                            <th className="title" colSpan={3}>
                                {props.company_name} <br />
                                <span style={{
                                    fontSize: '0.6em',
                                    fontWeight: 'normal',
                                    textTransform: 'none',
                                }}>{props.company_document}</span>
                            </th> 
                        </tr>
                        <tr>
                            <th colSpan={3}>
                                {props.current_date} < br/>
                                Emissor: {props.emitter} < br/>
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={3}>
                                Dados do cliente: <br />
                                {props.client_name} <br />
                                {props.client_document}
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={3}>
                                Forma de pagamento: <br />
                                {getPaymentMethod()}
                            </th>
                        </tr>
                        <tr>
                            <th className="ttu" colSpan={3}>
                                <b>Cupom não fiscal</b>
                            </th>
                        </tr>
                    </thead>
                    <tbody dangerouslySetInnerHTML={{ __html: props.items }} />
                    <tfoot>
                        <tr className="sup ttu p--0">
                            <td colSpan={3}>
                                <b>Totais</b>
                            </td>
                        </tr>
                        <tr className="ttu">
                            <td colSpan={2}>Sub-total</td>
                            <td align="right">R${props.sub_total_value}</td>
                        </tr>
                        <tr className="ttu">
                            <td colSpan={2}>Taxa de serviço</td>
                            <td align="right">{props.service_fee}</td>
                        </tr>
                        <tr className="ttu">
                            <td colSpan={2}>Desconto</td>
                            <td align="right">{props.discount}</td>
                        </tr>
                        {props.installments > 1 ?
                            <tr className="ttu">
                                <td colSpan={1}>Parcelas</td>
                                <td colSpan={2} align="right">{props.installments}x R${general.formatMoney(((parseInt(props.total_value.toString().replace(',', '').replace('.', '')) / props.installments) / 100).toFixed(2).replace('.', '').replace(',', ''))}</td>
                            </tr>
                            : null}
                        <tr className="ttu">
                            <td colSpan={2}>Total</td>
                            <td align="right">R${props.total_value}</td>
                        </tr>
                        <tr className="sup">
                            <td colSpan={3} align="center">
                                Emitido com Arezdot ERP <br />
                                https://arezdot.com
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </body>
    )
}

export default NfcupomTemplate;