import general from '../../../../utils/general';
import { useState } from 'react';

export const useController = (props: any) => {
    const [value, setValue] = useState(props?.field?.value ?? props.field?.selectOptions[0]?.value ?? '');
    const [optionsShowing, setOptionsShowing] = useState(false);

    const handleSetValue = (e: any) => {
        setValue(e.target.value);
        setOptionsShowing(false);
    }

    return {
        general,
        value,
        handleSetValue,
        optionsShowing,
        setOptionsShowing
    };
}

export default useController;