import general from '../../../../utils/general';
import { FormType } from '../FormModal/enum'
import { useState, useEffect } from 'react';

export const useController = () => {
    const [editModalActive, setEditModalActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissionBlock, setPermissionBlock] = useState(false);
    const [deleteModalActive, setDeleteModalActive] = useState(false);
    const [formDeleted, setFormDeleted] = useState(false);
    const [form, setForm] = useState<any>({
        title: '',
        description: '',
        thanks_active: false,
        form_type: FormType.SURVEY,
        thanks_message: '',
        thanks_btn_title: '',
        thanks_btn_link: '',
        fields_array: [],
        schedule: {
            allowed_days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
            time_in_minutes: 15,
            time_day_start: '07:00',
            time_day_end: '18:00',
        },
    });

    const getFormFromSlug = async (showLoader?: boolean) => {
        try{
            const slug = window.location.pathname.split('/').pop();
            showLoader == true && setLoading(true);
            const response = await general.api.get('form/slug/' + slug);
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setPermissionBlock(true);
                    setLoading(false);
                    return;
                }
                setForm(response.data.data);
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao buscar formulário', 'error');
            setLoading(false);
        }
    }

    const deleteForm = async () => {
        try{
            setLoading(true);
            const response = await general.api.delete('form/delete/' + form._id);
            if (response.status === 200) {
                if(response.data.status == 403) {
                    setLoading(false);
                    return;
                }
                setFormDeleted(true);
                general.notify('Formulário excluído com sucesso, redirecionando...', 'success');
                setTimeout(() => {
                    window.location.href = '/app/forms';
                }, 2000);
                setEditModalActive(false);
                setLoading(false);
            }
        }
        catch(err) {
            console.log(err);
            general.notify('Erro ao deletar formulário', 'error');
            setLoading(false);
        }
    }

    useEffect(() => {
        getFormFromSlug(true);
    }, []);

    useEffect(() => {
        getFormFromSlug(false);
    }, [editModalActive]);

    return {
        general,
        form,
        loading,
        permissionBlock,
        editModalActive,
        FormType,
        deleteModalActive,
        formDeleted,
        setFormDeleted,
        getFormFromSlug,
        setDeleteModalActive,
        deleteForm,
        setEditModalActive
    }
}

export default useController;